import { useLocation } from "react-router-dom";

/**
 * Get URL query params as object
 *
 * Example:
 *
 * ```tsx
 * type Query = {
 *  q: string
 * }
 *
 * const MyComponent = () => {
 *  const { q } = useQuery<Query>();
 *
 *  return (
 *    <h1>{q}</h1>
 *  );
 * }
 * ```
 */
export const useQuery = <T = Record<string, any>>(): Partial<T> => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  const queryParams = Object.fromEntries(urlSearchParams);

  return queryParams as any;
};
