import { Button, ButtonGroup, Divider, InputLeftElement, Text, useToast, VStack } from "@chakra-ui/react";
import { Container, Form, InputForm, WithLoading } from "@components";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePublishersFindById } from "@hooks/api/publishers.hook";
import { MainLayout } from "@layouts";
import { PublishersService } from "@services/publishers.service";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

const FORM_LABELS = {
  name: "Name",
  rate: "TL Rate",
  rate_major_tlc: "Major TLC Rate",
  rate_minor_tlc: "Minor TLC Rate",
  rate_reedit: "Edit/QC Rate",
  rate_retime: "Time Rate",
};

const SCHEMA = Yup.object({
  name: Yup.string().required().label(FORM_LABELS.name),
  rate: Yup.number().required().label(FORM_LABELS.rate),
  rate_major_tlc: Yup.number().required().label(FORM_LABELS.rate_major_tlc),
  rate_minor_tlc: Yup.number().required().label(FORM_LABELS.rate_minor_tlc),
  rate_reedit: Yup.number().required().label(FORM_LABELS.rate_reedit),
  rate_retime: Yup.number().required().label(FORM_LABELS.rate_retime),
});

type FormFields = Yup.InferType<typeof SCHEMA>;

const DEFAULT_VALUES: FormFields = {
  name: "",
  rate: 0,
  rate_major_tlc: 0,
  rate_minor_tlc: 0,
  rate_reedit: 0,
  rate_retime: 0,
};

export const PublishersForm = () => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const history = useHistory();
  const { data, loading: publishersLoading, refetch } = usePublishersFindById(id);

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  const { isDirty, isSubmitting } = methods.formState;

  useEffect(() => {
    if (data) methods.reset(data);
  }, [data]);

  const onSubmit = async (values: FormFields) => {
    try {
      const promise = id ? PublishersService.updateById(id, values) : PublishersService.create(values);

      await promise;

      const messageStatus = id ? "updated" : "created";

      toast({
        status: "success",
        description: `Publisher ${messageStatus} successfully.`,
      });

      refetch();

      history.push("/publishers");
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  const loading = publishersLoading;

  return (
    <MainLayout>
      <Container>
        <WithLoading loading={loading}>
          <Form methods={methods} onSubmit={onSubmit}>
            <VStack spacing="4">
              <Text fontSize="28px" textAlign="left" width="100%">
                {id ? "Edit" : "Add"} Publisher
              </Text>
              <Divider />

              <InputForm label={FORM_LABELS.name} name="name" />

              <InputForm
                label={FORM_LABELS.rate}
                name="rate"
                type="number"
                inputProps={{ step: 0.01 }}
                leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
                registerOptions={{ valueAsNumber: true }}
              />

              <InputForm
                label={FORM_LABELS.rate_major_tlc}
                name="rate_major_tlc"
                type="number"
                inputProps={{ step: 0.01 }}
                leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
                registerOptions={{ valueAsNumber: true }}
              />

              <InputForm
                label={FORM_LABELS.rate_minor_tlc}
                name="rate_minor_tlc"
                type="number"
                inputProps={{ step: 0.01 }}
                leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
                registerOptions={{ valueAsNumber: true }}
              />

              <InputForm
                label={FORM_LABELS.rate_reedit}
                name="rate_reedit"
                type="number"
                inputProps={{ step: 0.01 }}
                leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
                registerOptions={{ valueAsNumber: true }}
              />

              <InputForm
                label={FORM_LABELS.rate_retime}
                name="rate_retime"
                type="number"
                inputProps={{ step: 0.01 }}
                leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
                registerOptions={{ valueAsNumber: true }}
              />

              <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="space-between">
                <Button colorScheme="red" size="md" onClick={() => history.goBack()}>
                  Cancel
                </Button>
                <Button colorScheme="teal" size="md" type="submit" isLoading={isSubmitting} isDisabled={!isDirty}>
                  Save
                </Button>
              </ButtonGroup>
            </VStack>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
