import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, InputForm, InputSelectForm, SelectOptions, WithLoading } from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePayrollExpenses } from "@hooks/api/expenses.hook";
import { useUsersFindAll } from "@hooks/api/users.hook";
import { MainLayout } from "@layouts";
import { User } from "@services";
import { Expense, ExpensePayload, ExpensesService } from "@services/expenses.service";
import { moneyFormat } from "@utils/money-format";
import { useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { SELECT_CATEGORIES } from "./expenseslist";

type UserExpenseData = Omit<Expense, "created_at" | "serie_id" | "user">;

type ExpensesRowProps = {
  data?: Expense;
  users: User[];
  refetch: () => void;
};

type ExpensesRowDataProps = {
  onEdit: () => void;
  data: Expense;
  refetch: () => void;
};

type ExpensesRowFormProps = {
  onCancel: () => void;
  data?: UserExpenseData;
  users: SelectOptions;
  isAdd?: boolean;
  refetch: () => void;
};

const DEFAULT_FORM_VALUE: UserExpenseData = {
  amount: 0,
  description: "",
  id: 0,
  user_id: "" as any,
  category: "",
  recurrent: false,
};

const AlertMessageComponent = (props: { id: number }) => (
  <Text>
    Are you sure you want to delete the expense{" "}
    <Text as="span" fontWeight="bold">
      #{props.id}
    </Text>
    ?
  </Text>
);

const ExpensesRowData = ({ onEdit, data, refetch }: ExpensesRowDataProps) => {
  const toast = useToast();
  const ref = useRef<ConfirmModalRef>(null);
  const handleClickDeleteExpense = () => {
    ref.current?.open(
      { id: data.id },
      {
        title: "Delete Expense",
        message: <AlertMessageComponent id={data.id} />,
      }
    );
  };
  const onConfirmDeleteExpense = async (params: UserExpenseData) => {
    await ExpensesService.deleteById(params.id.toString());

    refetch();

    toast({
      status: "success",
      description: "Expense deleted successfully!",
      isClosable: true,
    });
  };

  const expense = data as Expense;

  return (
    <>
      <Tr>
        <Td w="3%">{expense.id}</Td>
        <Td w="20%">{expense.user.name}</Td>
        <Td>{expense.category}</Td>
        <Td>{expense.description}</Td>
        <Td w="10%">{moneyFormat(expense.amount)}</Td>
        <Td w="3%" textAlign="center">
          {expense.recurrent ? "Yes" : "No"}
        </Td>
        <Td textAlign="center">
          <ButtonGroup>
            <Tooltip label="Edit" aria-label="Edit Expense">
              <IconButton size="sm" aria-label="Edit" icon={<EditIcon />} colorScheme="blue" onClick={onEdit} />
            </Tooltip>
            <Tooltip label="Delete" aria-label="Delete Expense">
              <IconButton
                size="sm"
                aria-label="Delete"
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={handleClickDeleteExpense}
              />
            </Tooltip>
          </ButtonGroup>
          <ConfirmModal ref={ref} onConfirm={() => onConfirmDeleteExpense(data)} />
        </Td>
      </Tr>
    </>
  );
};

const ExpensesRowForm = ({ onCancel, data, users, isAdd, refetch }: ExpensesRowFormProps) => {
  const toast = useToast();
  const methods = useForm({
    defaultValues: {
      amount: data?.amount || DEFAULT_FORM_VALUE.amount,
      description: data?.description || DEFAULT_FORM_VALUE.description,
      id: data?.id || DEFAULT_FORM_VALUE.id,
      user_id: data?.user_id || DEFAULT_FORM_VALUE.user_id,
      category: data?.category || DEFAULT_FORM_VALUE.category,
      recurrent: data?.recurrent || DEFAULT_FORM_VALUE.recurrent,
    },
    resolver: yupResolver(
      Yup.object({
        user_id: Yup.mixed()
          .required()
          .test({ name: "user_id", message: "${path} is required", test: (value) => !!value })
          .label("User"),
        category: Yup.string().required().label("Category"),
        amount: Yup.number().required().typeError("${path} need be a number").min(1).label("Amount"),
      })
    ),
  });

  const onSubmit = async (values: UserExpenseData) => {
    try {
      const formValues: Omit<ExpensePayload, "id"> = {
        amount: +values.amount,
        description: values.description,
        category: values.category,
        recurrent: values.recurrent,
        user_id: values.user_id,
      };

      if (isAdd) {
        await ExpensesService.create(formValues);
        methods.reset(DEFAULT_FORM_VALUE);
      } else {
        await ExpensesService.updateById(values.id.toString(), formValues);
      }

      const message = isAdd ? "Expense created successfully" : "Expense edited successfully!";

      toast({
        status: "success",
        description: message,
        isClosable: true,
      });

      refetch();

      if (!isAdd) {
        onCancel();
      }
    } catch (error: any) {
      toast({
        status: "error",
        description: error.message,
        isClosable: true,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <Tr>
        {data?.id && <Td>{data?.id}</Td>}
        <Td colSpan={data?.id ? 1 : 2} pr="0">
          <InputSelectForm label="" options={users} name="user_id" placeholder="Select user" />
        </Td>
        <Td>
          <InputSelectForm
            label=""
            options={SELECT_CATEGORIES}
            name={`category`}
            selectProps={{ defaultValue: data?.category }}
          />
        </Td>
        <Td>
          <InputForm label="" name="description" placeholder="Description" />
        </Td>
        <Td pr="0">
          <InputForm
            type="number"
            label=""
            name="amount"
            placeholder="Amount"
            leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
          />
        </Td>
        <Td textAlign="center">
          <InputSwitchForm name="recurrent" formControlProps={{ display: "flex", flex: 1, justifyContent: "center" }} />
        </Td>
        <Td textAlign="center">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ButtonGroup>
              <Tooltip label="Save Expense" aria-label="Save Expense">
                <IconButton type="submit" size="sm" aria-label="Save" icon={<CheckIcon />} colorScheme="green" />
              </Tooltip>
              {!isAdd && (
                <Tooltip label="Cancel" aria-label="Cancel">
                  <IconButton
                    size="sm"
                    aria-label="Cancel"
                    icon={<CloseIcon />}
                    colorScheme="purple"
                    onClick={onCancel}
                  />
                </Tooltip>
              )}
            </ButtonGroup>
          </form>
        </Td>
      </Tr>
    </FormProvider>
  );
};

const ExpensesRow = ({ data, users, refetch }: ExpensesRowProps) => {
  const [isEdit, setEdit] = useState(false);
  const selectUsers = useMemo<SelectOptions>(
    () => users.map((u) => ({ label: u.name, value: u.id.toString() })).sort((a, b) => a.label.localeCompare(b.label)),
    [users]
  );

  if (isEdit && !!data) {
    return <ExpensesRowForm data={data} users={selectUsers} onCancel={() => setEdit(false)} refetch={refetch} />;
  }

  if (data) {
    return <ExpensesRowData data={data} onEdit={() => setEdit(true)} refetch={refetch} />;
  }

  return <ExpensesRowForm users={selectUsers} onCancel={() => setEdit(false)} refetch={refetch} isAdd />;
};

const Header = () => {
  return (
    <Flex mt="20px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
      <Flex direction="column">
        <Heading size="md" textTransform="capitalize">
          Payroll Expenses
        </Heading>
      </Flex>
    </Flex>
  );
};

export const PayrollExpenses = () => {
  const { loading: loadingExpenses, data: expenses, refetch } = usePayrollExpenses();
  const { loading: loadingUsers, data: users } = useUsersFindAll();
  const total = useMemo(() => expenses?.reduce((p, c) => p + c.amount, 0), [expenses]);

  const loading = loadingUsers || loadingExpenses;

  return (
    <MainLayout>
      <Container maxW="container.lg">
        <WithLoading loading={loading}>
          <Header />
          <Divider mb="20px" />
          <Table variant="striped" marginTop="16px !important">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>User</Th>
                <Th>Category</Th>
                <Th>Description</Th>
                <Th>Amount</Th>
                <Th>Recurrent</Th>
                <Th textAlign="center">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {expenses?.map((e) => {
                return <ExpensesRow key={e.id} data={e} users={users || []} refetch={refetch} />;
              })}
              <ExpensesRow users={users || []} refetch={refetch} />
              <Tr>
                <Td colSpan={5}>Total</Td>
                <Td colSpan={2}>{moneyFormat(total)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
