import { Alert, AlertDescription, AlertIcon, AlertProps } from "@chakra-ui/react";

export type AlertErrorProps = {
  message?: string;
} & AlertProps;

export const AlertError = ({ message = "", ...props }: AlertErrorProps) => {
  if (!message) return null;

  return (
    <Alert status="error" mt="5" {...props}>
      <AlertIcon />
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};
