import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { WithLoading, WithRoles } from "@components";
import { usePayrollView } from "@hooks/api/payroll.hook";
import { MainLayout } from "@layouts";
import { PayrollService, PayrollViewPayload } from "@services/payroll.service";
import { useAppSelector } from "@store";
import { DateFormat } from "@utils";
import { downloadFile } from "@utils/download-file";
import { moneyFormat } from "@utils/money-format";
import { SUPER_ADMIN } from "@utils/permissions";
import { slugify } from "@utils/slugify";
import JSZip from "jszip";
import React, { useState } from "react";
import { useParams } from "react-router";

type SeriesPayrollViewType = PayrollViewPayload["series"][number];

export const SeriesPayrollView = ({
  serie,
  hideExpenses = false,
  hideUser = false,
  hideTitle = false,
}: {
  serie: SeriesPayrollViewType;
  hideTitle?: boolean;
  hideExpenses?: boolean;
  hideUser?: boolean;
}) => {
  const totalSeriesEpisodes = serie.episodes?.reduce(
    (acc, current) => acc + current.team.map((t) => t.amount).reduce((vacc, v) => vacc + v, 0),
    0
  );

  const totalSeriesExpenses = serie.expenses?.reduce((acc, current) => acc + current.amount, 0);

  return (
    <>
      {!hideTitle && <Heading size="md">{serie.title}</Heading>}
      <Table variant="simple" marginTop="16px !important">
        <Thead>
          <Tr>
            <Th width="15%">Episode #</Th>
            {!hideUser && <Th>Name</Th>}
            <Th>Role</Th>
            <Th w="20%">Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {serie.episodes.map((episode) =>
            episode.team.map((team, i) => (
              <Tr key={team.id}>
                {i == 0 && <Td rowSpan={episode.team.length}>{episode.number}</Td>}
                {!hideUser && <Td>{team.user_name}</Td>}
                <Td>{team.role_name}</Td>
                <Td>{moneyFormat(team.amount)}</Td>
              </Tr>
            ))
          )}
          <Tr>
            <Td colSpan={hideUser ? 2 : 3}>Total</Td>
            <Td textAlign="left">{moneyFormat(totalSeriesEpisodes)}</Td>
          </Tr>
        </Tbody>
      </Table>

      {!hideExpenses && serie.expenses.length > 0 && (
        <>
          <Heading size="md" mt="30px">
            {serie.title} - Expenses
          </Heading>
          <Table variant="simple" marginTop="16px !important">
            <Thead>
              <Tr>
                <Th width="15%">Name</Th>
                <Th>Description</Th>
                <Th w="15%">Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {serie.expenses.map((expense) => (
                <Tr key={expense.id}>
                  <Td>{expense.user_name}</Td>
                  <Td>{expense.description}</Td>
                  <Td>{moneyFormat(expense.amount)}</Td>
                </Tr>
              ))}
              <Tr>
                <Td colSpan={2}>Total</Td>
                <Td textAlign="left">{moneyFormat(totalSeriesExpenses)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}

      <Divider mt="30px" mb="30px" />
    </>
  );
};

export const PayrollView = () => {
  const { id: payroll_id } = useParams<{ id: string; name: string }>();
  const { data: payroll, loading } = usePayrollView(parseInt(payroll_id));
  const [loadingPdfs, setLoading] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const currentUserName = useAppSelector((state) => state.user.name);

  const genAdminPDF = async () => {
    const blob = await PayrollService.adminPayrollPdf(parseInt(payroll_id));
    const fileURL = window.URL.createObjectURL(blob);
    downloadFile(fileURL, `${payroll.name}.pdf`);
  };

  const genAdminPDFUsers = async () => {
    setLoading(true);
    const zip = new JSZip();

    for (const user of payroll.summaries.users) {
      const blob = await PayrollService.adminUserPdf(parseInt(payroll_id), user.id);
      zip.file(`${user.name} - ${payroll.name}.pdf`, blob);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    const fileURL = window.URL.createObjectURL(zipBlob);

    downloadFile(fileURL, `${payroll.name} - Users.zip`);

    setLoading(false);
  };

  const genPDFUser = async () => {
    const blob = await PayrollService.userPdf(parseInt(payroll_id));
    const fileURL = window.URL.createObjectURL(blob);
    downloadFile(fileURL, `${payroll.name} - ${currentUserName}.pdf`);
  };

  const genAdminCSV = async () => {
    setLoadingCSV(true);

    const blob = await PayrollService.adminPayrollCSV(parseInt(payroll_id));
    const fileURL = window.URL.createObjectURL(blob);

    downloadFile(fileURL, `${slugify(payroll.name)}.zip`);

    setLoadingCSV(false);
  };

  return (
    <MainLayout>
      <Container maxW="container.lg">
        <WithLoading loading={loading}>
          {payroll && (
            <>
              <Flex mt="10px" direction="row" justifyContent="space-between" alignItems="center">
                <Flex direction="column">
                  <Heading size="lg" p="0" m="0">
                    {payroll.name}
                  </Heading>
                  <Heading size="xs" mt="5px !important" fontWeight="normal">
                    {DateFormat.toDateString(payroll.period_start)} - {DateFormat.toDateString(payroll.period_end)}{" "}
                    (UTC)
                  </Heading>
                </Flex>
                <Flex>
                  <ButtonGroup size="sm">
                    <WithRoles roles={SUPER_ADMIN}>
                      <Button color="red.300" onClick={genAdminCSV} isLoading={loadingCSV}>
                        CSV
                      </Button>
                      <Button color="red.300" onClick={genAdminPDF}>
                        PDF
                      </Button>
                      <Button color="red.300" onClick={genAdminPDFUsers} isLoading={loadingPdfs}>
                        Payslips
                      </Button>
                    </WithRoles>
                    <WithRoles roles={["normal", "project_manager", "admin"]}>
                      <Button color="red.300" onClick={genPDFUser}>
                        Payslip
                      </Button>
                    </WithRoles>
                  </ButtonGroup>
                </Flex>
              </Flex>

              <Divider mt="30px" mb="30px" />

              <Table marginTop="16px !important" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={7}>Users Summary</Th>
                  </Tr>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Series</Th>
                    <Th>Roles</Th>
                    <Th># Episodes</Th>
                    <Th>Which?</Th>
                    <Th>Effective Episodes</Th>
                    <Th w="20%">Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payroll.summaries.users.map((u, uidx) => (
                    <React.Fragment key={u.id}>
                      {u.series.map((s, sidx) =>
                        s.roles.map((r, ridx) => (
                          <Tr key={`${u.id}-${s.id}-${r.id}`} background={uidx % 2 == 0 ? "gray.700" : undefined}>
                            {sidx == 0 && ridx == 0 && (
                              <Td rowSpan={u.series.reduce((acc, curr) => acc + curr.roles.length, 0)}>{u.name}</Td>
                            )}
                            {ridx == 0 && <Td rowSpan={s.roles.length}>{s.title}</Td>}
                            <Td>{r.name}</Td>
                            <Td>{r.num_episodes}</Td>
                            <Td>{r.episodes_range}</Td>
                            {sidx == 0 && ridx == 0 && (
                              <Td rowSpan={u.series.reduce((acc, curr) => acc + curr.roles.length, 0)}>
                                {u.num_episodes}
                              </Td>
                            )}
                            {sidx == 0 && ridx == 0 && (
                              <Td rowSpan={u.series.reduce((acc, curr) => acc + curr.roles.length, 0)}>
                                {moneyFormat(u.amount)}
                              </Td>
                            )}
                          </Tr>
                        ))
                      )}
                      {u.series.length == 0 && (
                        <Tr background={uidx % 2 == 0 ? "gray.700" : undefined}>
                          <Td colSpan={5}>{u.name}</Td>
                          <Td>{u.num_episodes}</Td>
                          <Td>{moneyFormat(u.amount)}</Td>
                        </Tr>
                      )}
                    </React.Fragment>
                  ))}
                </Tbody>
              </Table>

              <Table variant="striped" marginTop="16px !important" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={3}>Roles Summary</Th>
                  </Tr>
                  <Tr>
                    <Th>Role</Th>
                    <Th># Episodes</Th>
                    <Th w="20%">Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payroll.summaries.roles.map((r) => (
                    <Tr key={r.id}>
                      <Td>{r.name}</Td>
                      <Td>{r.num_episodes}</Td>
                      <Td>{moneyFormat(r.amount)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Table variant="striped" marginTop="16px !important" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={4}>Series Summary</Th>
                  </Tr>
                  <Tr>
                    <Th>Series</Th>
                    <Th># Episodes</Th>
                    <Th>Episodes</Th>
                    <Th w="20%">Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payroll.summaries.series.map((s) => (
                    <Tr key={s.id}>
                      <Td>{s.title}</Td>
                      <Td>{s.num_episodes}</Td>
                      <Td>{s.episodes_range}</Td>
                      <Td>{moneyFormat(s.amount)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Table variant="striped" marginTop="16px !important" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={4}>Series Expenses</Th>
                  </Tr>
                  <Tr>
                    <Th>Series</Th>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th w="20%">Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payroll.series
                    .filter((s) => s.expenses.length > 0)
                    .map((s) =>
                      s.expenses.map((e) => (
                        <Tr key={e.id}>
                          <Td>{s.title}</Td>
                          <Td>{e.user_name}</Td>
                          <Td>{e.description}</Td>
                          <Td>{moneyFormat(e.amount)}</Td>
                        </Tr>
                      ))
                    )}
                  {payroll.series.filter((s) => s.expenses.length > 0).length == 0 && (
                    <Tr>
                      <Td colSpan={4} fontWeight="bold">
                        None
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>

              <WithRoles roles={SUPER_ADMIN}>
                <Table variant="striped" marginTop="16px !important" size="sm">
                  <Thead>
                    <Tr>
                      <Th colSpan={4}>Business Expenses</Th>
                    </Tr>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Category</Th>
                      <Th>Description</Th>
                      <Th w="20%">Total Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {payroll.expenses.map((e) => (
                      <Tr key={e.id}>
                        <Td>{e.user_name}</Td>
                        <Td>{e.category}</Td>
                        <Td>{e.description}</Td>
                        <Td>{moneyFormat(e.amount)}</Td>
                      </Tr>
                    ))}
                    {payroll.expenses.length == 0 && (
                      <Tr>
                        <Td colSpan={4} fontWeight="bold">
                          None
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </WithRoles>

              <Divider mt="30px" mb="30px" />

              {payroll.series.map((serie) => (
                <SeriesPayrollView key={serie.id} serie={serie} />
              ))}
            </>
          )}
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
