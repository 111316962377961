import { GetLanguagesFilters, Language, Translations, TranslationsService } from "@services/translations.service";
import useSWR from "swr";
import { HookResponse, makeEmptyHookResponse, makeSwrResponse } from "./utils";

export const useGetLanguages = (filters?: GetLanguagesFilters): HookResponse<Language[]> => {
  const key = `/languages?${JSON.stringify(filters)}`;
  const fetcher = () => TranslationsService.getLanguages(filters);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useGetLanguageById = (id: number | null): HookResponse<Language> => {
  if (!id) return makeEmptyHookResponse() as HookResponse<Language>;

  const key = `/languages/${id}`;
  const fetcher = () => TranslationsService.getLanguageById(id as number);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useGetTranslationsByLanguageId = (languageId: number): HookResponse<Translations> => {
  const key = `/translations/${languageId}`;
  const fetcher = () => TranslationsService.getTranslationsByLanguageId(languageId);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
