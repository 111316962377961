import { useToast } from "@chakra-ui/toast";
import {
  Auth,
  Episode,
  Episodes,
  EpisodesEdit,
  Expenses,
  Home,
  InvoiceCreate,
  InvoiceHistory,
  InvoiceNext,
  InvoiceView,
  Languages,
  LanguagesForm,
  NextPayroll,
  Payroll,
  PayrollExpenses,
  PayrollView,
  PublishersForm,
  PublishersList,
  Roles,
  RolesForm,
  Serie,
  Series,
  SeriesPayrollSummary,
  TranslationsList,
  Users,
  UsersForm,
} from "@pages";
import { NewPayroll } from "@pages/payroll/new";
import { Profile } from "@pages/profile";
import { AuthService } from "@services";
import { useAppSelector } from "@store";
import { ADMIN_PM, ADMIN_PUBLISHER, ADMIN_ROLES, ALL_ROLES, ALL_USERS, SUPER_ADMIN } from "@utils/permissions";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { UserRoles } from "types/user-roles";

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          <Auth />
        </Route>

        <PrivateRoute roles={ALL_ROLES} path="/profile">
          <Profile />
        </PrivateRoute>

        <PrivateRoute roles={ADMIN_ROLES} path="/translations/:id/keys">
          <TranslationsList />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/translations/add">
          <LanguagesForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/translations/:id/edit">
          <LanguagesForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/translations">
          <Languages />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/users/add">
          <UsersForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/users/:id">
          <UsersForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/users">
          <Users />
        </PrivateRoute>

        <PrivateRoute roles={ADMIN_ROLES} path="/publishers/add">
          <PublishersForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/publishers/:id">
          <PublishersForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/publishers">
          <PublishersList />
        </PrivateRoute>

        <PrivateRoute roles={ADMIN_ROLES} path="/roles/add">
          <RolesForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/roles/:id">
          <RolesForm />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_ROLES} path="/roles">
          <Roles />
        </PrivateRoute>

        <PrivateRoute roles={ADMIN_PM} path="/series/add">
          <Serie />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PM} path="/series/:id/edit">
          <Serie />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PM} path="/series/:id/:name/edit">
          <Episodes />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PM} path="/series/:id/:name/expenses">
          <Expenses />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PM} path="/series/:id/:name/payroll">
          <SeriesPayrollSummary />
        </PrivateRoute>

        <PrivateRoute roles={ALL_ROLES} path="/series/archived">
          <Series />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PM} path="/series/:id/:name/episodes/edit">
          <EpisodesEdit />
        </PrivateRoute>
        <PrivateRoute roles={ALL_ROLES} path="/series/:id/:name/episodes/:episode_id?">
          <Episodes />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PM} path="/series/:id/:name/episode/:episode_id">
          <Episode />
        </PrivateRoute>
        <PrivateRoute roles={ALL_ROLES} path="/series">
          <Series />
        </PrivateRoute>

        <PrivateRoute roles={ADMIN_PUBLISHER} path="/invoice/view/:id/:name">
          <InvoiceView />
        </PrivateRoute>
        <PrivateRoute roles={SUPER_ADMIN} path="/invoice/create">
          <InvoiceCreate />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PUBLISHER} path="/invoice/next">
          <InvoiceNext />
        </PrivateRoute>
        <PrivateRoute roles={ADMIN_PUBLISHER} path="/invoice">
          <InvoiceHistory />
        </PrivateRoute>

        <PrivateRoute roles={SUPER_ADMIN} path="/payroll/new">
          <NewPayroll />
        </PrivateRoute>
        <PrivateRoute roles={ALL_USERS} path="/payroll/next">
          <NextPayroll />
        </PrivateRoute>
        <PrivateRoute roles={ALL_USERS} path="/payroll/history">
          <Payroll />
        </PrivateRoute>
        <PrivateRoute roles={SUPER_ADMIN} path="/payroll/expenses">
          <PayrollExpenses />
        </PrivateRoute>
        <PrivateRoute roles={ALL_USERS} path="/payroll/view/:id/:name">
          <PayrollView />
        </PrivateRoute>

        <PrivateRoute roles={ALL_ROLES} path="/">
          <Home />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

type PrivateRouteProps = {
  path: string;
  roles: UserRoles[];
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, roles, path }) => {
  const { role, logged } = useAppSelector((state) => state.user);
  const hasPermission = AuthService.hasPermission(roles, role as UserRoles);
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    if (!logged) return history.push("/login");

    if (!hasPermission) {
      toast({
        description: "You don't have permission!",
        status: "error",
      });

      history.push("/");
    }
  }, []);

  return (
    <Route exact path={path}>
      {children}
    </Route>
  );
};
