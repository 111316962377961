import { api } from "./api";
import { User } from "./users.service";
import { handleRequestPromise } from "./_utils_";

export const PublishersService = {
  findAll: async (): Promise<Publisher[]> => {
    const promise = api.get<Publisher[]>(`publishers`);
    return handleRequestPromise(promise);
  },
  findById: async (id: string): Promise<Publisher> => {
    const promise = api.get<Publisher>(`publishers/${id}`);
    return handleRequestPromise(promise);
  },
  create: async (publisher: Partial<Publisher>): Promise<boolean> => {
    const promise = api.post<boolean>(`publishers`, publisher);
    return handleRequestPromise(promise);
  },
  updateById: async (id: string, publisher: Partial<Publisher>): Promise<boolean> => {
    const promise = api.patch<boolean>(`publishers/${id}`, publisher);
    return handleRequestPromise(promise);
  },
  deleteById: async (id: string): Promise<boolean> => {
    const promise = api.delete<boolean>(`publishers/${id}`);
    return handleRequestPromise(promise);
  },
};

export type Publisher = {
  id: number;
  name: string;
  rate: number;
  rate_major_tlc: number;
  rate_minor_tlc: number;
  rate_reedit: number;
  rate_retime: number;
  admins?: User[];
};
