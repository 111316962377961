import { InputSelect, InputSelectProps } from "@components/input";
import { useFormContext } from "react-hook-form";
import { makeInputFormProps } from "./utils";

export const InputSelectForm = (props: InputSelectProps) => {
  const { register, formState } = useFormContext();
  const { registerOptions, ...rest } = props;
  const withFormHookProps = makeInputFormProps(register(props.name, registerOptions), formState);

  return <InputSelect {...rest} {...withFormHookProps} />;
};
