import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, Container, WithLoading } from "@components";
import { useGetLanguages } from "@hooks/api/translations.hook";
import { MainLayout } from "@layouts";
import { Language, TranslationsService } from "@services/translations.service";
import { useRef } from "react";
import { FaList } from "react-icons/fa";
import { useHistory } from "react-router";
import { ENGLISH_ID } from "./translations.constant";

const AlertMessageComponent = (props: { language: Language }) => {
  return (
    <Text>
      Are you sure you want to delete the{" "}
      <Text as="span" fontWeight="bold">
        {props.language.label}
      </Text>{" "}
      language?
    </Text>
  );
};

export const Languages = () => {
  const history = useHistory();
  const languages = useGetLanguages();
  const ref = useRef<ConfirmModalRef>(null);
  const toast = useToast();

  const handleClickAddBtn = () => history.push("/translations/add");
  const handleClickEditBtn = (id: number) => history.push(`/translations/${id}/edit`);
  const handleClickKeysBtn = (id: number) => history.push(`/translations/${id}/keys`);
  const handleClickDeleteBtn = async (language: Language) => {
    try {
      await TranslationsService.deleteLanguage(language.id);
      await languages.refetch();

      toast({
        status: "success",
        description: "Language deleted successfully",
      });
    } catch (error: any) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  return (
    <MainLayout>
      <ConfirmModal ref={ref} onConfirm={handleClickDeleteBtn} />

      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.xl" } }}>
        <HStack justifyContent="space-between">
          <Text fontSize="xx-large" as="span" fontWeight="bold">
            Languages
          </Text>
          <Button fontSize="sm" colorScheme="teal" onClick={handleClickAddBtn}>
            Add New Language
          </Button>
        </HStack>
        <Divider />
        <WithLoading loading={languages.loading}>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Label</Th>
                <Th>Flag</Th>
                <Th>Active</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {languages.data?.map((l) => (
                <Tr key={l.id}>
                  <Td>{l.id}</Td>
                  <Td>{l.label}</Td>
                  <Td>{l.flag}</Td>
                  <Td>{l.active ? "Yes" : "No"}</Td>
                  <Td>
                    <ButtonGroup>
                      <IconButton
                        size="sm"
                        aria-label="Edit"
                        icon={<EditIcon />}
                        onClick={() => handleClickEditBtn(l.id)}
                        borderRadius="4"
                      />
                      <IconButton
                        size="sm"
                        aria-label="Delete"
                        colorScheme="red"
                        isDisabled={l.id === ENGLISH_ID}
                        icon={<DeleteIcon />}
                        borderRadius="4"
                        onClick={() =>
                          ref.current?.open(l, {
                            title: "Delete Language?",
                            message: <AlertMessageComponent language={l} />,
                          })
                        }
                      />
                      <IconButton
                        size="sm"
                        colorScheme="purple"
                        aria-label="Keys"
                        icon={<Icon as={FaList} />}
                        onClick={() => handleClickKeysBtn(l.id)}
                        borderRadius="4"
                      />
                    </ButtonGroup>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
