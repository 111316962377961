import { CopyIcon } from "@chakra-ui/icons";
import { Button, Divider, Flex, Heading, Spinner, Stack, Text, useClipboard, VStack } from "@chakra-ui/react";
import { AlertError, Container } from "@components";
import { useGetLinkQRCode } from "@hooks/api";
import { useAppDispatch, useAppSelector } from "@store";
import { goToStep, LoginFormSteps } from "@store/modules/login";
import { CONSTANTS } from "@utils";
import QRCode from "qrcode.react";
import { useHistory } from "react-router";

export const ScanQRCode = () => {
  const loginState = useAppSelector((state) => state.login);
  const { data, error, loading } = useGetLinkQRCode(loginState);
  const { hasCopied, onCopy } = useClipboard(data?.key_2fa);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleClickContinue = () => {
    dispatch(goToStep({ step: LoginFormSteps.REGISTER_TWO_AUTH_CODE }));
  };

  if (error) {
    history.push("/login");

    return null;
  }

  return (
    <Container>
      <Stack align="center">
        <Heading fontSize="4xl">{CONSTANTS.PROJECT_NAME}</Heading>
        <Heading fontSize="4sm">by {CONSTANTS.BY}</Heading>
      </Stack>
      <Flex
        direction="column"
        rounded="lg"
        bg="gray.700"
        boxShadow="lg"
        p={8}
        justify="center"
        alignItems="center"
        mb="8"
      >
        <Heading size="sm" textAlign="center" maxWidth="300px">
          <Text>Please scan the QR Code below using the Google Authenticator app.</Text>
          <Text mt="5px">You won&apos;t be able to proceed until this is registered with your account.</Text>
        </Heading>
        <Divider my="6" />
        <VStack spacing="1" mb="6">
          <Text as="strong">Secret Key</Text>
          <Flex align="baseline">
            <Text>{data?.key_2fa}</Text>
            <Button ml="4" size="xs" colorScheme="green" leftIcon={<CopyIcon />} onClick={onCopy}>
              {hasCopied ? "Copied!" : "Copy"}
            </Button>
          </Flex>
        </VStack>
        {loading && <Spinner />}
        {data && <QRCode value={data.otp_auth_url} size={250} />}
        <AlertError message={error} maxW={250} />
        <Button w="full" maxW={300} mt="6" onClick={handleClickContinue}>
          Continue
        </Button>
      </Flex>
    </Container>
  );
};
