import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Heading,
  IconButton,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Form, InputForm, InputSelectForm, SelectOptions, WithLoading } from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInvoiceCreateData, useInvoiceNumber } from "@hooks/api/invoice.hook";
import { usePublishersFindAll } from "@hooks/api/publishers.hook";
import { MainLayout } from "@layouts";
import { InvoiceService } from "@services";
import { toFixed } from "@utils/math";
import { moneyFormat, moneyValue } from "@utils/money-format";
import { flatten } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

const FORM_LABELS = {
  publisher: "Publisher",
  invoice_number: "Number",
  name: "Title",
  user: "User",
  period_start: "Start Date",
  period_end: "End Date",
};

const EPISODE_SCHEMA = Yup.array()
  .of(
    Yup.object({
      id: Yup.number().required(),
      number: Yup.string().required(),
      publisher: Yup.object({
        duration: Yup.number().required(),
        rate: Yup.number().required(),
        rate_major_tlc: Yup.number().required(),
        rate_minor_tlc: Yup.number().required(),
        rate_reedit: Yup.number().required(),
        rate_retime: Yup.number().required(),
        options: Yup.object({
          full_tl: Yup.boolean(),
          major_tlc: Yup.boolean(),
          minor_tlc: Yup.boolean(),
          reedit: Yup.boolean(),
          retime: Yup.boolean(),
        }).required(),
        approved: Yup.boolean().required(),
      }).required(),
    })
  )
  .required();

const SCHEMA = Yup.object({
  publisher_id: Yup.string().required().label(FORM_LABELS.publisher),
  invoice_number: Yup.number().required().label(FORM_LABELS.invoice_number),
  name: Yup.string().required().label(FORM_LABELS.name),
  period_start: Yup.string().required().label(FORM_LABELS.period_start),
  period_end: Yup.string().required().label(FORM_LABELS.period_end),
  invoice: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required(),
        title: Yup.string().required(),
        episodes: Yup.array().of(EPISODE_SCHEMA).required(),
      }).required()
    )
    .required(),
});

export type InvoiceFormFields = Yup.InferType<typeof SCHEMA>;

const DEFAULT_VALUES: InvoiceFormFields = {
  publisher_id: "",
  invoice_number: 0,
  name: moment().subtract(1, "month").format("MMM YYYY"),
  period_start: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
  period_end: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  invoice: [],
};

type EpisodePublisherInvoiceProps = {
  series_index: number;
  episode_index: number;
  episode_type_index: number;
  episode: InvoiceFormFields["invoice"][number]["episodes"][number][number];
};

type EpisodePublisherRateProps = {
  series_index: number;
  episode_index: number;
  episode_type_index: number;
};

const EpisodePublisherRate = ({ series_index, episode_index, episode_type_index }: EpisodePublisherRateProps) => {
  const { control } = useFormContext<InvoiceFormFields>();

  const publisher = useWatch({
    name: `invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher` as "invoice.0.episodes.0.0.publisher",
    control,
  });

  const rate =
    (publisher.options.full_tl ? publisher.rate : 0) +
    (publisher.options.major_tlc ? publisher.rate_major_tlc : 0) +
    (publisher.options.minor_tlc ? publisher.rate_minor_tlc : 0) +
    (publisher.options.reedit ? publisher.rate_reedit : 0) +
    (publisher.options.retime ? publisher.rate_retime : 0);

  const total = rate;
  return <>{moneyFormat(total)}</>;
};

type EpisodePublisherTotalProps = {
  series_index: number;
  episode_index: number;
  episode_type_index: number;
};

const EpisodePublisherTotal = ({ series_index, episode_index, episode_type_index }: EpisodePublisherTotalProps) => {
  const { control } = useFormContext<InvoiceFormFields>();

  const publisher = useWatch({
    name: `invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher` as "invoice.0.episodes.0.0.publisher",
    control,
  });

  const rate =
    (publisher.options.full_tl ? publisher.rate : 0) +
    (publisher.options.major_tlc ? publisher.rate_major_tlc : 0) +
    (publisher.options.minor_tlc ? publisher.rate_minor_tlc : 0) +
    (publisher.options.reedit ? publisher.rate_reedit : 0) +
    (publisher.options.retime ? publisher.rate_retime : 0);

  const minutes = Math.max(1, publisher.duration / 60);
  const total = moneyValue(rate * toFixed(minutes, 1));
  return <>{moneyFormat(total)}</>;
};

const getEpisodePublisherRate = (episode: InvoiceFormFields["invoice"][number]["episodes"][number][number]) => {
  return (
    (episode.publisher.options.full_tl ? episode.publisher.rate : 0) +
    (episode.publisher.options.major_tlc ? episode.publisher.rate_major_tlc : 0) +
    (episode.publisher.options.minor_tlc ? episode.publisher.rate_minor_tlc : 0) +
    (episode.publisher.options.reedit ? episode.publisher.rate_reedit : 0) +
    (episode.publisher.options.retime ? episode.publisher.rate_retime : 0)
  );
};

const SeriesPublisherInvoiceAproved = ({
  series_index,
  episode_type_index,
}: {
  series_index: number;
  episode_type_index: number;
}) => {
  const { getValues, reset } = useFormContext<InvoiceFormFields>();

  const uncheckAll = () => {
    const values = getValues();

    for (const e of values.invoice[series_index].episodes[episode_type_index]) {
      e.publisher.approved = false;
    }

    reset({ ...values });
  };

  return (
    <Button size="xs" onClick={uncheckAll}>
      Uncheck All
    </Button>
  );
};

const SeriesPublisherInvoiceDuration = ({
  series_index,
  episode_type_index,
}: {
  series_index: number;
  episode_type_index: number;
}) => {
  const { control } = useFormContext<InvoiceFormFields>();

  const formValues = useWatch({
    name: `invoice.${series_index}.episodes.${episode_type_index}` as "invoice.0.episodes.0",
    control,
  });

  const episodes = formValues.filter((e) => e.publisher.approved);

  const minutes =
    episodes.length > 0
      ? toFixed(
          (episodes
            .map((e) => toFixed(Math.max(1, e.publisher.duration / 60), 1))
            .reduce((acc, current) => acc + current, 0) || 0) / episodes.length,
          1
        )
      : 0;

  return <>{minutes}</>;
};

const SeriesPublisherInvoiceTotal = ({
  series_index,
  episode_type_index,
}: {
  series_index: number;
  episode_type_index: number;
}) => {
  const { control } = useFormContext<InvoiceFormFields>();

  const formValues = useWatch({
    name: `invoice.${series_index}.episodes.${episode_type_index}` as "invoice.0.episodes.0",
    control,
  });

  const episodes = formValues.filter((e) => e.publisher.approved);

  const minutes =
    episodes.length > 0
      ? toFixed(
          (episodes
            .map((e) => toFixed(Math.max(1, e.publisher.duration / 60), 1))
            .reduce((acc, current) => acc + current, 0) || 0) / episodes.length,
          1
        )
      : 0;

  const total =
    formValues?.reduce(
      (acc, current) =>
        acc + (current.publisher && current.publisher.approved ? getEpisodePublisherRate(current) * minutes : 0),
      0
    ) || 0;
  return <>{moneyFormat(total)}</>;
};

const EpisodeOptions = ({
  series_index,
  episode_index,
  episode_type_index,
}: {
  series_index: number;
  episode_index: number;
  episode_type_index: number;
}) => {
  const { control } = useFormContext<InvoiceFormFields>();

  const options = useWatch({
    name: `invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.options` as "invoice.0.episodes.0.0.publisher.options",
    control,
  });

  return (
    <>
      <InputSwitchForm
        label="TL"
        name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.options.full_tl`}
        formControlProps={{ width: "130px" }}
        switchProps={{ isDisabled: options.major_tlc || options.minor_tlc || options.reedit || options.retime }}
      />
      <InputSwitchForm
        label="Major TLC"
        name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.options.major_tlc`}
        formControlProps={{ width: "130px" }}
        switchProps={{ isDisabled: options.full_tl || options.minor_tlc }}
      />
      <InputSwitchForm
        label="Minor TLC"
        name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.options.minor_tlc`}
        formControlProps={{ width: "130px" }}
        switchProps={{ isDisabled: options.full_tl || options.major_tlc }}
      />
      <InputSwitchForm
        label="Edit/QC"
        name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.options.reedit`}
        formControlProps={{ width: "130px" }}
        switchProps={{ isDisabled: options.full_tl }}
      />
      <InputSwitchForm
        label="Time"
        name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.options.retime`}
        formControlProps={{ width: "130px" }}
        switchProps={{ isDisabled: options.full_tl }}
      />
    </>
  );
};

const EpisodePublisherInvoice = ({
  series_index,
  episode_index,
  episode_type_index,
  episode,
}: EpisodePublisherInvoiceProps) => {
  const { register } = useFormContext<InvoiceFormFields>();
  const [edit, setEdit] = useState(false);

  if (!episode.publisher) {
    return null;
  }

  if (edit) {
    return (
      <EpisodePublisherInvoiceEditable
        series_index={series_index}
        episode_index={episode_index}
        episode={episode}
        episode_type_index={episode_type_index}
      />
    );
  }

  return (
    <Tr>
      <Td>{episode.number}</Td>
      <Td>{episode.publisher.duration}</Td>
      <Td>
        <VStack w="xs" alignItems="flex-start" spacing={1} width="130px">
          <EpisodeOptions
            series_index={series_index}
            episode_index={episode_index}
            episode_type_index={episode_type_index}
          />
        </VStack>
      </Td>
      <Td>
        <EpisodePublisherRate
          series_index={series_index}
          episode_index={episode_index}
          episode_type_index={episode_type_index}
        />
      </Td>
      <Td>
        <EpisodePublisherTotal
          series_index={series_index}
          episode_index={episode_index}
          episode_type_index={episode_type_index}
        />
      </Td>
      <Td textAlign="center">
        <Switch
          size="sm"
          {...register(
            `invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.approved` as const
          )}
          defaultChecked={episode.publisher.approved}
        />
      </Td>
      <Td textAlign="center">
        <ButtonGroup>
          <Tooltip label="Edit" aria-label="Edit Publisher Episode">
            <IconButton size="sm" aria-label="Edit" icon={<EditIcon />} onClick={() => setEdit(true)} />
          </Tooltip>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const EpisodePublisherInvoiceEditable = ({
  series_index,
  episode_index,
  episode,
  episode_type_index,
}: EpisodePublisherInvoiceProps) => {
  const { register } = useFormContext<InvoiceFormFields>();

  if (!episode.publisher) {
    return null;
  }

  return (
    <Tr>
      <Td>{episode.number}</Td>
      <Td>
        <InputForm
          label=""
          name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.duration`}
          registerOptions={{ valueAsNumber: true }}
          inputProps={{ defaultValue: episode.publisher.duration, size: "sm" }}
          type="number"
        />
      </Td>
      <Td>
        <VStack w="xs" alignItems="flex-start" spacing={5} width="130px">
          <EpisodeOptions
            series_index={series_index}
            episode_index={episode_index}
            episode_type_index={episode_type_index}
          />
        </VStack>
      </Td>
      <Td>
        <VStack w="xs" spacing={0} width="130px">
          <InputForm
            label=""
            name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.rate`}
            registerOptions={{ valueAsNumber: true }}
            inputProps={{ defaultValue: episode.publisher.rate, size: "sm" }}
            type="number"
          />
          <InputForm
            label=""
            name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.rate_major_tlc`}
            registerOptions={{ valueAsNumber: true }}
            inputProps={{ defaultValue: episode.publisher.rate_major_tlc, size: "sm" }}
            type="number"
          />
          <InputForm
            label=""
            name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.rate_minor_tlc`}
            registerOptions={{ valueAsNumber: true }}
            inputProps={{ defaultValue: episode.publisher.rate_minor_tlc, size: "sm" }}
            type="number"
          />
          <InputForm
            label=""
            name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.rate_reedit`}
            registerOptions={{ valueAsNumber: true }}
            inputProps={{ defaultValue: episode.publisher.rate_reedit, size: "sm" }}
            type="number"
          />
          <InputForm
            label=""
            name={`invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.rate_retime`}
            registerOptions={{ valueAsNumber: true }}
            inputProps={{ defaultValue: episode.publisher.rate_retime, size: "sm" }}
            type="number"
          />
        </VStack>
      </Td>
      <Td>
        <EpisodePublisherTotal
          series_index={series_index}
          episode_index={episode_index}
          episode_type_index={episode_type_index}
        />
      </Td>
      <Td textAlign="center">
        <Switch
          size="sm"
          {...register(
            `invoice.${series_index}.episodes.${episode_type_index}.${episode_index}.publisher.approved` as const
          )}
          defaultChecked={episode.publisher.approved}
        />
      </Td>
      <Td textAlign="center">
        <ButtonGroup>
          <Tooltip label="Edit" aria-label="Edit Publisher Episode">
            <IconButton size="sm" aria-label="Edit" icon={<EditIcon />} disabled />
          </Tooltip>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

type SeriesInvoiceProps = {
  series_index: number;
  title: string;
  episode_type_index: number;
  episodes: InvoiceFormFields["invoice"][number]["episodes"][number];
};

const SeriesInvoice = ({ series_index, title, episodes, episode_type_index }: SeriesInvoiceProps) => {
  return (
    <>
      <Heading size="md" mt="30px">
        {title}
      </Heading>
      <Table variant="simple" marginTop="16px !important">
        <Thead>
          <Tr>
            <Th width="15%">Episode #</Th>
            <Th>Duration</Th>
            <Th>Options</Th>
            <Th>Rate</Th>
            <Th w="15%">Amount</Th>
            <Th w="3%">Approved</Th>
            <Th w="3%">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {episodes.map(
            (episode, episodes_index) =>
              episode.publisher && (
                <EpisodePublisherInvoice
                  series_index={series_index}
                  episode_index={episodes_index}
                  key={episode.number}
                  episode={episode}
                  episode_type_index={episode_type_index}
                />
              )
          )}
          <Tr>
            <Td>Total</Td>
            <Td colSpan={3} textAlign="left">
              <SeriesPublisherInvoiceDuration series_index={series_index} episode_type_index={episode_type_index} />
            </Td>
            <Td>
              <SeriesPublisherInvoiceTotal series_index={series_index} episode_type_index={episode_type_index} />
            </Td>
            <Td colSpan={2} textAlign="left">
              <SeriesPublisherInvoiceAproved series_index={series_index} episode_type_index={episode_type_index} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export const InvoiceCreate = () => {
  const history = useHistory();
  const toast = useToast();

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  const [creating, setCreating] = useState(false);

  const onSubmit = async (values: InvoiceFormFields) => {
    setCreating(true);

    try {
      const episodes = flatten(values.invoice.map((s) => s.episodes));
      await InvoiceService.create({
        name: values.name,
        invoice_number: values.invoice_number,
        publisher_id: parseInt(values.publisher_id),
        period_start: values.period_start,
        period_end: values.period_end,
        episodes: flatten(episodes)
          .filter((e) => e.publisher.approved)
          .map((e) => ({
            id: e.id,
            duration: e.publisher.duration,
            rate: e.publisher.options.full_tl ? e.publisher.rate : 0,
            rate_major_tlc: e.publisher.options.major_tlc ? e.publisher.rate_major_tlc : 0,
            rate_minor_tlc: e.publisher.options.minor_tlc ? e.publisher.rate_minor_tlc : 0,
            rate_reedit: e.publisher.options.reedit ? e.publisher.rate_reedit : 0,
            rate_retime: e.publisher.options.retime ? e.publisher.rate_retime : 0,
          })),
      });
    } catch {
      toast({
        status: "error",
        description: "Error when creating invoice!",
        isClosable: true,
      });
      return;
    } finally {
      setCreating(false);
    }

    toast({
      status: "success",
      description: "Invoice created successfully.",
      isClosable: true,
    });

    history.push("/invoice");
  };

  const publisher_id = useWatch({ name: "publisher_id", control: methods.control });
  const { data: publishersData, loading: publishersLoading } = usePublishersFindAll();
  const { data: invoiceCreateData, loading: invoiceLoading } = useInvoiceCreateData(
    publisher_id != "" ? parseInt(publisher_id) : undefined
  );

  const { data: invoiceNumber, loading: invoiceNumberLoading } = useInvoiceNumber(
    publisher_id != "" ? parseInt(publisher_id) : undefined
  );

  const { fields: invoiceData } = useFieldArray({
    control: methods.control,
    name: "invoice",
    keyName: "field_id",
  });

  useEffect(() => {
    if (invoiceNumber && invoiceCreateData) {
      methods.reset({ ...methods.getValues(), invoice_number: invoiceNumber.number, invoice: invoiceCreateData || [] });
    }
  }, [invoiceCreateData, invoiceNumber]);

  useEffect(() => {
    if (publishersData && publishersData.length == 1) {
      methods.setValue("publisher_id", publishersData[0].id.toString());
    }
  }, [publishersData]);

  const PUBLISHERS_SELECT: SelectOptions =
    publishersData?.map((p) => ({ label: p.name, value: p.id.toString() })) ?? [];

  return (
    <MainLayout>
      <Container maxW="container.lg">
        <WithLoading loading={publishersLoading}>
          <Form methods={methods} onSubmit={onSubmit}>
            <Heading size="lg" mb="30px">
              Create Invoice
            </Heading>
            <InputSelectForm label={FORM_LABELS.publisher} options={PUBLISHERS_SELECT} name="publisher_id" />
            <Divider mt="30px" mb="30px" />

            <InputForm label={FORM_LABELS.invoice_number} name="invoice_number" type="number" />
            <InputForm label={FORM_LABELS.name} name="name" />
            <InputForm type="date" label={FORM_LABELS.period_start} name="period_start" />
            <InputForm type="date" label={FORM_LABELS.period_end} name="period_end" />

            <Divider mt="30px" mb="30px" />

            <WithLoading loading={!!publisher_id && (invoiceLoading || invoiceNumberLoading)} centerH={false}>
              {invoiceData.map((seriesInvoice, index) => (
                <React.Fragment key={seriesInvoice.id}>
                  <>
                    {seriesInvoice.episodes[0].length > 0 && (
                      <SeriesInvoice
                        key={seriesInvoice.field_id}
                        title={seriesInvoice.title}
                        series_index={index}
                        episodes={seriesInvoice.episodes[0]}
                        episode_type_index={0}
                      />
                    )}
                  </>
                  <>
                    {seriesInvoice.episodes[1].length > 0 && (
                      <SeriesInvoice
                        key={seriesInvoice.field_id}
                        title={`${seriesInvoice.title} - PV`}
                        series_index={index}
                        episodes={seriesInvoice.episodes[1]}
                        episode_type_index={1}
                      />
                    )}
                  </>
                  <>
                    {seriesInvoice.episodes[2].length > 0 && (
                      <SeriesInvoice
                        key={seriesInvoice.field_id}
                        title={`${seriesInvoice.title} - Double/Movie`}
                        series_index={index}
                        episodes={seriesInvoice.episodes[2]}
                        episode_type_index={2}
                      />
                    )}
                  </>
                </React.Fragment>
              ))}
            </WithLoading>

            <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end" mt="15px">
              <Button colorScheme="red" size="md" as={Link} to={"/invoice"} isDisabled={creating}>
                Cancel
              </Button>

              <Button colorScheme="teal" size="md" type="submit" isLoading={creating} isDisabled={creating}>
                Create
              </Button>
            </ButtonGroup>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
