import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { FiFile } from "react-icons/fi";

type FileUploadProps = {
  name: string;
  placeholder?: string;
  label?: string;
  acceptedFileTypes: string;
  helperMessage?: string;
  defaultFilename?: string;
};

type Props = Partial<InputProps> & FileUploadProps;

export const FileUploadFrom = ({
  name,
  placeholder,
  label,
  acceptedFileTypes,
  helperMessage,
  defaultFilename,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { formState, watch, setValue } = useFormContext();
  const value = watch(name, "") as File;
  const errorMessage = formState.errors[name]?.message;

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files![0];
    setValue(name, file, { shouldDirty: true });
  };

  return (
    <FormControl isInvalid={!!errorMessage}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<Icon as={FiFile} />} />
        <input
          type="file"
          accept={acceptedFileTypes}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          name={name}
          ref={inputRef}
          multiple={false}
        ></input>
        <Input
          placeholder={placeholder || "Your file ..."}
          onClick={() => inputRef?.current?.click()}
          value={value?.name || defaultFilename || ""}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      {helperMessage && <FormHelperText>{helperMessage}</FormHelperText>}
    </FormControl>
  );
};
