import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const TranslationsService = {
  getLanguages: (filters?: GetLanguagesFilters): Promise<Language[]> => {
    const promise = api.get<Language[]>("translations/languages", { params: filters });
    return handleRequestPromise(promise);
  },
  createLanguage: (params: Omit<Language, "id">): Promise<Language> => {
    const promise = api.post<Language>("translations/languages", params);
    return handleRequestPromise(promise);
  },
  updateLanguage: (params: Omit<Language, "id">, id: number): Promise<void> => {
    const promise = api.put(`translations/languages/${id}`, params);
    return handleRequestPromise(promise);
  },
  deleteLanguage: async (id: number): Promise<void> => {
    const promise = api.delete(`translations/languages/${id}`);
    return handleRequestPromise(promise);
  },
  getLanguageById: (id: number) => {
    const promise = api.get<Language>(`translations/languages/${id}`);
    return handleRequestPromise(promise);
  },
  getTranslationsByLanguageId: async (languageId: number): Promise<Translations> => {
    const promise = api.get<Translations>(`translations/${languageId}`);
    return handleRequestPromise(promise);
  },
  saveTranslation: async (payload: SaveTranslationPaylaod): Promise<Translations> => {
    const promise = api.post<Translations>(`translations/save-translation`, payload);
    return handleRequestPromise(promise);
  },
};

export type GetLanguagesFilters = {
  active?: boolean;
};

export type SaveTranslationPaylaod = {
  language_id: number;
  key: string;
  text: string;
};

export type Language = {
  id: number;
  flag: string;
  label: string;
  active: boolean;
};

export type Translations = Record<TranslationKeys, string>;

export type TranslationKeys =
  | "users.form.name.label"
  | "user.form.username.label"
  | "user.form.two-step-auth-type.label"
  | "user.form.password.label"
  | "users.form.email.label"
  | "user.form.discord.label"
  | "user.form.roles.label"
  | "user.form.permission.label"
  | "user.form.timezone.label"
  | "users.form.edit.title"
  | "users.form.add.title"
  | "users.list.id"
  | "users.list.avatar"
  | "users.list.email"
  | "users.list.username"
  | "users.list.type"
  | "users.list.actions"
  | "users.list.add"
  | "users.list.name"
  | "users.list.delete.title"
  | "users.list.delete.message"
  | "menu.dashboard"
  | "menu.series"
  | "menu.series.archived"
  | "menu.payroll"
  | "menu.payroll.create"
  | "menu.payroll.history"
  | "menu.payroll.expenses"
  | "menu.invoice"
  | "menu.invoice.create"
  | "menu.invoice.history"
  | "menu.roles"
  | "menu.users"
  | "menu.publishers"
  | "menu.translations"
  | "menu.profile"
  | "menu.logout"
  | "series.input.placeholder"
  | "series.search.not-found"
  | "series.button.add"
  | "series.archived"
  | "series.episodes-count"
  | "series.team.empty"
  | "series.delete.success"
  | "series.delete.title"
  | "series.delete.message"
  | "series.episodes"
  | "series.simulcast"
  | "series.catalogue"
  | "series.deadline"
  | "series.episodes.empty"
  | "series.episodes.download.srt"
  | "series.episodes.download.ass"
  | "series.episodes.download.all.loading"
  | "series.episodes.airing-date"
  | "series.episodes.deadline"
  | "series.options"
  | "series.episodes.options.edit-series"
  | "series.episodes.options.expenses"
  | "series.episodes.options.payroll"
  | "series.episodes.options.edit-episodes-and-flags"
  | "series.episodes.options.refresh-sftp"
  | "series.episodes.options.submit-all"
  | "series.episodes.options.download-srt"
  | "series.episodes.options.download-ass"
  | "series.episodes.status"
  | "series.episodes.target-date"
  | "series.episodes.actions"
  | "dashboard.simulcast-overview"
  | "dashboard.simulcast-overview.series"
  | "dashboard.catalogue-overview"
  | "dashboard.catalogue-overview.series"
  | "dashboard.catalogue-overview.episode"
  | "dashboard.staff-overview"
  | "dashboard.staff-overview.user"
  | "dashboard.staff-overview.last-day"
  | "dashboard.staff-overview.7-days"
  | "dashboard.staff-overview.14-days"
  | "dashboard.staff-overview.30-days"
  | "dashboard.staff-overview.average"
  | "dashboard.calendar-overview"
  | "dashboard.calendar-overview.series"
  | "dashboard.calendar-overview.badge.red"
  | "dashboard.calendar-overview.badge.yellow"
  | "dashboard.calendar-overview.badge.green"
  | "dashboard.calendar-overview.script.label"
  | "dashboard.calendar-overview.video.label"
  | "dashboard.calendar-overview.status.label"
  | "dashboard.calendar-overview.available"
  | "dashboard.calendar-overview.missing"
  | "dashboard.calendar-overview.status-submitted"
  | "dashboard.calendar-overview.status-being"
  | "dashboard.series-episodes-overview"
  | "dashboard.series-episodes-overview.series"
  | "dashboard.series-episodes-overview.script"
  | "dashboard.series-episodes-overview.video"
  | "dashboard.series-episodes-overview.submitted"
  | "invoices.next.title"
  | "invoices.next.empty"
  | "invoices.next.publisher"
  | "invoices.next.table.series"
  | "invoices.next.table.number-episodes"
  | "invoices.next.table.episodes"
  | "invoices.next.table.minutes-per-episode"
  | "invoices.next.table.activity"
  | "invoices.next.table.price"
  | "invoices.next.table.amount"
  | "invoices.next.table.total"
  | "invoices.history.title"
  | "invoices.history.create-button"
  | "invoices.history.table.title"
  | "invoices.history.table.series"
  | "invoices.history.table.effective-episodes"
  | "invoices.history.table.total"
  | "invoices.history.table.number-of-series"
  | "invoices.history.empty"
  | "invoices.history"
  | "invoices.history"
  | "invoices.history"
  | "invoices.history"
  | "invoices.history"
  | "invoices.history.view-button"
  | "invoices.view.pdf-button"
  | "invoices.view.table.series"
  | "invoices.view.table.number-episodes"
  | "invoices.view.table.episodes"
  | "invoices.view.table.minutes"
  | "invoices.view.table.activity"
  | "invoices.view.table.price"
  | "invoices.view.table.amount"
  | "invoices.view.table.total"
  | "dashboard.timer.title"
  | "dashboard.timer.table.series"
  | "dashboard.timer.table.episodes"
  | "dashboard.timer.table.translated"
  | "dashboard.timer.table.target"
  | "dashboard.timer.table.claimant"
  | "dashboard.timer.table.expires"
  | "dashboard.timer.table.claim-user-none"
  | "dashboard.timer.table.claim-end"
  | "dashboard.timer.empty"
  | "dashboard.timer.claim-button"
  | "dashboard.timer.claim-denied"
  | "dashboard.timer.claim-delete-button"
  | "profile.title"
  | "profile.form.upload-button"
  | "profile.form.old-password"
  | "profile.form.new-password"
  | "profile.form.confirm-password"
  | "profile.form.calendar-link"
  | "profile.form.save-button"
  | "profile.form.calendar-copy"
  | "profile.form.calendar-copied"
  | "profile.form.update-success-message"
  | "profile.form.language"
  | "profile.form.timezone";
