import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const RolesService = {
  findAll: async (onlyActive: boolean): Promise<Role[]> => {
    const promise = api.get<Role[]>("roles", { params: { onlyActive } });
    return handleRequestPromise(promise);
  },
  findById: async (id: string): Promise<Role> => {
    const promise = api.get<Role>(`roles/${id}`);
    return handleRequestPromise(promise);
  },
  create: async (role: Partial<Role>): Promise<Role> => {
    const promise = api.post<Role>(`roles`, role);
    return handleRequestPromise(promise);
  },
  updateById: async (id: string, role: Partial<Role>): Promise<boolean> => {
    const promise = api.patch<boolean>(`roles/${id}`, role);
    return handleRequestPromise(promise);
  },
  deleteById: async (id: string): Promise<boolean> => {
    const promise = api.delete<boolean>(`roles/${id}`);
    return handleRequestPromise(promise);
  },
};

export type Role = {
  id: number;
  name: string;
  short_name: string;
  description: string;
  finish_text: string;
  begin_text: string;
  sftp_folder: string;
  rate: number;
  file_tag: string;
  color: string;
  order: number;
  active: boolean;
};
