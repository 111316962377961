import { SWRResponse } from "swr";

export type HookResponse<T> = {
  data: T;
  error?: string;
  loading: boolean;
  refetch: () => Promise<boolean>;
};

export const makeSwrResponse = <T>(swr: SWRResponse<T, any>): HookResponse<T> => {
  return {
    data: swr.data!,
    error: swr.error,
    loading: swr.data === undefined && !swr.error,
    refetch: swr.revalidate,
  };
};

export const makeEmptyHookResponse = <T>(): HookResponse<T | null> => {
  return {
    data: null,
    error: undefined,
    loading: false,
    refetch: async () => false,
  };
};
