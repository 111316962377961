import { PayrollService } from "@services/payroll.service";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const usePayrollNew = () => {
  const key = `/payroll/new`;
  const fetcher = () => PayrollService.new();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const usePayrollNext = () => {
  const key = `/payroll/next`;
  const fetcher = () => PayrollService.next();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const usePayrollHistory = () => {
  const key = `/payroll/history`;
  const fetcher = () => PayrollService.history();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const usePayrollSeries = (series_id: string) => {
  const key = `/payroll/series/${series_id}`;
  const fetcher = () => PayrollService.series(series_id);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const usePayrollView = (id: number) => {
  const key = `/payroll/view/${id}`;
  const fetcher = () => PayrollService.view(id);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
