import { handlePromise } from "@utils";
import { AxiosPromise } from "axios";

export const mapApiBasicError = (error: any, key = "message") => {
  const message = error?.response?.data[key] || error.message;

  return message;
};

export const handleRequestPromise = async <T = any>(promise: AxiosPromise<T>): Promise<T> => {
  const [data, error] = await handlePromise(promise);

  // Custom Error Codes from API
  if (error?.response?.data.code) return Promise.reject(error?.response.data); // Custom errors

  // Default errors from API
  if (error) throw new Error(mapApiBasicError(error));

  return data.data;
};
