import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { InputSelectForm, SelectOptions, WithLoading } from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEpisodesFindAll } from "@hooks/api/episodes.hook";
import { useSeriesFindById } from "@hooks/api/series.hook";
import { useUsersFindAll } from "@hooks/api/users.hook";
import { MainLayout } from "@layouts";
import { EpisodesTeamsService, Team, User } from "@services";
import { Episode, EpisodesService } from "@services/episodes.service";
import { groupBy } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Link as LinkRouter } from "react-router-dom";
import * as Yup from "yup";

type EpisodeEditFormProps = {
  episode_index: number;
  episode: Episode;
  team: Team[];
  users: User[];
};

const SCHEMA = Yup.object({
  video_available_flag: Yup.boolean().required(),
  script_available_flag: Yup.boolean().required(),
  submitted_date_flag: Yup.boolean().required(),
  team: Yup.array()
    .of(
      Yup.object({
        episodeId: Yup.number().required(),
        roleId: Yup.number().required(),
        team_id: Yup.number().required(),
        userId: Yup.string(),
      }).required()
    )
    .required(),
});

type FormFields = Yup.InferType<typeof SCHEMA>;

const groupEpisodeTeamByRole = (episode: Episode, team: Team[]) => {
  const episodeteam_role = groupBy(episode.episodeteam, "role.id");

  const ret = team.map((t) => ({ ...t, episode: episodeteam_role[t.role_id]?.[0] }));
  ret.sort((a, b) => a.role.order - b.role.order);
  return ret;
};

const EpisodeEditForm = ({ episode, episode_index, team, users }: EpisodeEditFormProps) => {
  const episodeteam = useMemo(() => groupEpisodeTeamByRole(episode, team), [episode, team]);
  const toast = useToast();

  const methods = useForm<FormFields>({
    defaultValues: {
      video_available_flag: !!episode.video_available,
      script_available_flag: !!episode.script_available,
      submitted_date_flag: !!episode.submitted_date,
      team: episodeteam.map((e) => ({
        episodeId: episode.id,
        roleId: e.role_id,
        team_id: e.episode?.id || 0,
        userId: e.episode?.user.id.toString() || "",
      })),
    },
    resolver: yupResolver(SCHEMA),
  });

  useEffect(() => {
    methods.reset({
      video_available_flag: !!episode.video_available,
      script_available_flag: !!episode.script_available,
      submitted_date_flag: !!episode.submitted_date,
      team: episodeteam.map((e) => ({
        episodeId: episode.id,
        roleId: e.role_id,
        team_id: e.episode?.id || 0,
        userId: e.episode?.user.id.toString() || "",
      })),
    });
  }, [episodeteam, episode]);

  const onSubmit = async (values: FormFields) => {
    try {
      await EpisodesService.updateFlagsById(episode.id.toString(), {
        video_available_flag: values.video_available_flag,
        script_available_flag: values.script_available_flag,
        submitted_date_flag: values.submitted_date_flag,
      });

      for (const team of values.team) {
        if (!team.team_id) {
          if (!team.userId) {
            continue;
          }

          await EpisodesTeamsService.adminCreate({
            episodeId: team.episodeId,
            roleId: team.roleId,
            userId: parseInt(team.userId),
          });
        } else {
          await EpisodesTeamsService.update(team.team_id.toString(), {
            episodeId: team.episodeId,
            roleId: team.roleId,
            userId: parseInt(team.userId || "0"),
          });
        }
      }

      methods.reset(values);

      toast({
        status: "success",
        description: `Episode #${episode.number} updated sucessfully!`,
      });
    } catch (error: any) {
      toast({
        status: "error",
        title: "Error when updating episode team/status!",
        description: error.message,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      {team.map((t, i) => {
        const usersSelectOptionsByRole: SelectOptions = users
          .filter((u) => u.roles?.some((r) => r.id === t.role_id))
          .map((u) => ({ label: u.name, value: u.id.toString() }))
          .sort((a, b) => a.label.localeCompare(b.label));

        return (
          <Tr
            key={t.id}
            background={episode_index % 2 == 0 ? "gray.700" : undefined}
            borderColor={episode_index % 2 == 0 ? "black" : undefined}
          >
            {i == 0 && <Td rowSpan={team.length}>{episode.number}</Td>}
            {i == 0 && (
              <Td rowSpan={team.length}>
                <InputSwitchForm name="script_available_flag" />
              </Td>
            )}
            {i == 0 && (
              <Td rowSpan={team.length}>
                <InputSwitchForm name="video_available_flag" />
              </Td>
            )}
            <Td>{t.role.name}</Td>
            <Td>
              <InputSelectForm
                name={`team.${i}.userId`}
                label=""
                placeholder={`Select User`}
                options={usersSelectOptionsByRole}
                selectProps={{ size: "sm" }}
                registerOptions={{ setValueAs: (v) => (v ? v : "") }}
              />
            </Td>
            {i == 0 && (
              <Td rowSpan={team.length}>
                <InputSwitchForm name="submitted_date_flag" />
              </Td>
            )}
            {i == 0 && (
              <Td rowSpan={team.length} textAlign="center">
                <Tooltip label="Save Episode" aria-label="Save Episode">
                  <IconButton
                    type="submit"
                    size="sm"
                    aria-label="Save"
                    icon={<CheckIcon />}
                    colorScheme="green"
                    disabled={!methods.formState.isDirty}
                    onClick={methods.handleSubmit(onSubmit)}
                  />
                </Tooltip>
              </Td>
            )}
          </Tr>
        );
      })}
    </FormProvider>
  );
};

export const EpisodesEdit = () => {
  const { id, name } = useParams<{ name: string; id: string }>();
  const { data: seriesData, loading: seriesLoading } = useSeriesFindById(id);
  const { data: episodesData, loading: episodesLoading } = useEpisodesFindAll(parseInt(id));
  const { data: usersData, loading: usersLoading } = useUsersFindAll();

  const loading = episodesLoading || seriesLoading || usersLoading;

  seriesData?.team.sort((a, b) => a.role.order - b.role.order);

  const BackToEpisodes = useCallback(() => {
    return (
      <Button
        as={LinkRouter}
        size="sm"
        leftIcon={<ArrowBackIcon />}
        colorScheme="red"
        to={`/series/${id}/${name}/episodes`}
      >
        Back to episodes
      </Button>
    );
  }, [id, name]);

  return (
    <MainLayout>
      <Container maxW="container.lg">
        <WithLoading loading={loading}>
          <Flex mt="20px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
            <Heading size="md" textTransform="capitalize">
              {seriesData?.short_title} - Episodes Edit
            </Heading>
            <Box>
              <BackToEpisodes />
            </Box>
          </Flex>
          <Divider mb="30px" />
          <Table size="sm">
            <Thead>
              <Tr>
                <Th w="5%">#</Th>
                <Th w="15%">Script Avail.</Th>
                <Th w="15%">Video Avail.</Th>
                <Th colSpan={2}>Team</Th>
                <Th w="5%">Submitted</Th>
                <Th w="10%" textAlign="center">
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {episodesData?.map((e, i) => {
                return (
                  seriesData?.team && (
                    <EpisodeEditForm
                      key={e.id}
                      episode={e}
                      episode_index={i}
                      team={seriesData?.team}
                      users={usersData || []}
                    />
                  )
                );
              })}
            </Tbody>
          </Table>
          <Flex flex={1} justify="flex-end" mt="8">
            <BackToEpisodes />
          </Flex>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
