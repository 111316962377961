import { ChakraProvider, ColorModeOptions, extendTheme } from "@chakra-ui/react";
import { FC } from "react";

const colors = {
  dark: "#06071b",
  brown: "#563d3f",
  gray: {
    light: "#c9c3c5",
    medium: "#8a937e",
  },
  primary: {
    light: "#13164e",
    medium: "#3539b0",
    dark: "#1525ed",
  },
};

const global = {
  "html, body, #root": {
    height: "100%",
    backgroundColor: "#1A202C",
  },
};

const config: ColorModeOptions = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors,
  styles: {
    global,
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "blue",
        variant: "outline",
      },
    },
  },
});

export const Theme: FC = ({ children }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
