import { Avatar, Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";
import { useTranslations } from "@hooks/useTranslations";
import { useAppDispatch, useAppSelector } from "@store";
import { unauthenticate } from "@store/modules/user";
import { useHistory } from "react-router";

export const UserMenu = () => {
  const { avatar, name } = useAppSelector((state) => state.user);
  const translations = useTranslations();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClickLogout = () => {
    dispatch(unauthenticate());
    history.push("/login");
  };

  const handleClickProfile = () => {
    history.push("/profile");
  };

  return (
    <Menu>
      <MenuButton as={Button} rounded={"full"} variant={"link"} cursor={"pointer"}>
        <Avatar size={"sm"} alt="User avatar" name={name} src={avatar} />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleClickProfile}>{translations["menu.profile"]}</MenuItem>
        <MenuDivider />
        <MenuItem onClick={handleClickLogout}>{translations["menu.logout"]}</MenuItem>
      </MenuList>
    </Menu>
  );
};
