import { Serie } from "@services";
import { Language } from "@services/translations.service";

export const makeTitleBySerie = (serie: Serie, language: Language): string => {
  const initials = language?.flag.toLowerCase();

  if (!initials || initials === "en") return serie.short_title;
  if (["cn", "ch"].includes(initials)) return serie.title_ch || serie.short_title;

  const fieldTitle = `title_${initials}`;
  const title: string | undefined = serie[fieldTitle as keyof Serie] as string;

  return title || serie.short_title;
};
