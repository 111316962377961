import { ArrowBackIcon, CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Container, Form, InputForm, InputSelect, SelectOption, WithLoading } from "@components";
import { env } from "@config/env";
import { useGetLanguages, useGetTranslationsByLanguageId } from "@hooks/api/translations.hook";
import { MainLayout } from "@layouts";
import { api } from "@services/api";
import { TranslationKeys, Translations, TranslationsService } from "@services/translations.service";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { ENGLISH_ID } from "./translations.constant";

type TranslationProp = {
  key: string;
  text: string;
  en_text: string;
};

type TableRowProps = {
  translation: TranslationProp;
  showEnText: boolean;
  languageId: number;
  refetch: () => Promise<boolean>;
};

type TableRowDisplayDataProps = {
  onEdit: () => void;
  translation: TranslationProp;
  showEnText: boolean;
};

type TranslationFormProps = {
  onComplete: () => void;
  translation: TranslationProp;
  showEnText: boolean;
  languageId: number;
  refetch: () => Promise<boolean>;
};

type MakeTranslationWithEnTextResponse = Record<TranslationKeys, { text: string; en_text: string }>;

const TranslationAdd = () => {
  const methods = useForm({
    defaultValues: {
      key: "",
      value: "",
    },
  });
  const handleSubmit = async ({ key, text }: any) => {
    await api.post("/translations/save-translation-key", { key });
    await api.post("/translations/save-translation", { key, text, language_id: ENGLISH_ID });
    methods.reset();
  };

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <InputForm name="key" label={"key"} />
      <InputForm name="text" label={"text"} />
      <Button type="submit">save</Button>
    </Form>
  );
};

export const TranslationsList = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data: languages, loading: langLoading } = useGetLanguages();
  const [selected, setSelected] = useState(params.id ? +params.id : ENGLISH_ID);
  const { data, loading, refetch } = useCustomTranslationLoad(selected);
  const showEnText = selected !== ENGLISH_ID;
  const handleClickBackBtn = () => history.push("/translations");

  if (loading || langLoading) {
    return (
      <MainLayout>
        <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.xl" } }}>
          <WithLoading loading={true} />
        </Container>
      </MainLayout>
    );
  }

  const translations = data as MakeTranslationWithEnTextResponse;
  const options = languages.map<SelectOption>((l) => ({ value: l.id.toString(), label: l.label }));
  const selectedLanguage = languages?.find((l) => l.id === selected);

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.xl" } }}>
        <Heading size="lg" display="flex" justifyContent="space-between">
          {selectedLanguage?.label}
          <Button leftIcon={<ArrowBackIcon />} fontSize="sm" size="sm" colorScheme="red" onClick={handleClickBackBtn}>
            Back
          </Button>
        </Heading>

        <InputSelect
          name="select"
          label="Languages"
          options={options}
          selectProps={{
            onChange: (e) => {
              const value = +e.target.value;
              if (value !== 0) setSelected(value);
            },
            value: selected.toString(),
            isRequired: true,
          }}
        />

        {!env.production && <TranslationAdd />}
        {!translations && (
          <Flex flex={1} justifyContent="center" direction="column" align="center">
            <Spinner />
            <Text mt="2">Loading translations...</Text>
          </Flex>
        )}
        {translations && (
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th p="2" w="32%">
                  Key
                </Th>
                <Th p="2">Text</Th>
                <Th p="2" w="15%" textAlign="center">
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(translations).map((key) => {
                const k = key as TranslationKeys;

                const translation: TranslationProp = {
                  key,
                  ...translations[k],
                };

                return (
                  <TableRow
                    key={key}
                    translation={translation}
                    showEnText={showEnText}
                    languageId={selected}
                    refetch={refetch}
                  />
                );
              })}
            </Tbody>
          </Table>
        )}
      </Container>
    </MainLayout>
  );
};

const makeTranslationWithEnText = (
  translation: Translations,
  enTranslation: Translations
): MakeTranslationWithEnTextResponse => {
  const adapted = Object.entries(translation).reduce((prev, [key, value]) => {
    const k = key as TranslationKeys;

    prev[k] = {
      text: value,
      en_text: enTranslation[k],
    };

    return prev;
  }, {} as MakeTranslationWithEnTextResponse);

  return adapted;
};

const useCustomTranslationLoad = (langId = ENGLISH_ID) => {
  const { data: english, loading: enLoading } = useGetTranslationsByLanguageId(ENGLISH_ID);
  const { data: selected, loading: seLoading, refetch } = useGetTranslationsByLanguageId(langId);

  const loading = enLoading && seLoading;
  return {
    loading,
    data: english && selected && !loading && makeTranslationWithEnText(selected, english),
    refetch,
  };
};

const TableRow = (props: TableRowProps) => {
  const [isEdit, setIsEdit] = useState(false);

  if (isEdit) return <TranslationForm onComplete={() => setIsEdit(false)} {...props} />;

  return <TableRowDisplayData onEdit={() => setIsEdit(true)} {...props} />;
};

const TableRowDisplayData = (props: TableRowDisplayDataProps) => {
  return (
    <Tr>
      <Td p="2">{props.translation.key}</Td>
      <Td p="2">
        <Flex flex={1} align="flex-start" direction="column">
          <Text mb="1" mt="2">
            {props.translation.text}
          </Text>
          {props.showEnText && (
            <>
              <Divider />
              <Text fontSize="xs" color="gray.400">
                <strong>EN: </strong>
                {props.translation.en_text}
              </Text>
            </>
          )}
        </Flex>
      </Td>
      <Td p="2" textAlign="center">
        <IconButton size="sm" aria-label="Edit Translation" icon={<EditIcon />} onClick={props.onEdit} />
      </Td>
    </Tr>
  );
};

const TranslationForm = (props: TranslationFormProps) => {
  const methods = useForm({
    defaultValues: {
      text: props.translation.text,
    },
  });

  const handleSubmit = async (values: { text: string }) => {
    await TranslationsService.saveTranslation({
      language_id: props.languageId,
      text: values.text,
      key: props.translation.key,
    });

    await props.refetch();
    props.onComplete();
  };

  const helperMessage = props.showEnText ? `EN: ${props.translation.en_text}` : undefined;

  return (
    <FormProvider {...methods}>
      <Tr>
        <Td p="2">{props.translation.key}</Td>
        <Td p="2">
          <InputForm label="" name="text" helperMessage={helperMessage} />
        </Td>
        <Td p="2" textAlign="center">
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <ButtonGroup size="xs">
              <IconButton
                colorScheme="red"
                size="xs"
                aria-label="Cancel Translation"
                icon={<CloseIcon />}
                onClick={() => props.onComplete()}
              />
              <IconButton
                type="submit"
                size="xs"
                aria-label="Edit Translation"
                icon={<CheckIcon />}
                isLoading={methods.formState.isSubmitting}
              />
            </ButtonGroup>
          </form>
        </Td>
      </Tr>
    </FormProvider>
  );
};
