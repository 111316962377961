import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, WithLoading, WithRoles } from "@components";
import { PageHeader } from "@components/page";
import { usePayrollHistory } from "@hooks/api/payroll.hook";
import { MainLayout } from "@layouts";
import { PayrollService } from "@services/payroll.service";
import { DateFormat } from "@utils";
import { moneyFormat } from "@utils/money-format";
import { SUPER_ADMIN } from "@utils/permissions";
import { slugify } from "@utils/slugify";
import { useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const AlertMessageComponent = (props: { payroll_name: string }) => (
  <Text>
    Are you sure you want to delete the payroll{" "}
    <Text as="span" fontWeight="bold">
      {props.payroll_name}
    </Text>
    ?
  </Text>
);

export const Payroll = () => {
  const history = useHistory();
  const ref = useRef<ConfirmModalRef>(null);
  const { data: payrollData, loading: payrollLoading, refetch } = usePayrollHistory();

  const handleClickDeletePayroll = async (id: number) => {
    await PayrollService.delete(id);
    await refetch();
  };

  return (
    <MainLayout>
      <Container maxW={{ base: "full", sm: "container.lg" }}>
        <ConfirmModal ref={ref} onConfirm={handleClickDeletePayroll} />

        <PageHeader title={"Payroll History"}>
          <WithRoles roles={SUPER_ADMIN}>
            <ButtonGroup variant="outline" spacing="2" width="100%" justifyContent="flex-end">
              <Button colorScheme="teal" size="sm" type="submit" onClick={() => history.push("/payroll/new")}>
                Create New Payroll
              </Button>
            </ButtonGroup>
          </WithRoles>
        </PageHeader>

        <WithLoading loading={payrollLoading}>
          {payrollData?.map((p) => (
            <div key={p.payroll.id}>
              <Flex justifyContent="space-between" alignItems="center">
                <Flex direction="column">
                  <Heading size="md" p="0" m="0">
                    {p.payroll.name}
                  </Heading>
                  <Heading size="xs" mt="5px !important" fontWeight="normal">
                    {DateFormat.toDateString(p.payroll.period_start)} - {DateFormat.toDateString(p.payroll.period_end)}{" "}
                    (UTC)
                  </Heading>
                </Flex>
                <Flex>
                  <ButtonGroup>
                    <Tooltip label="View" aria-label="View Payroll">
                      <IconButton
                        size="sm"
                        aria-label="View"
                        icon={<ViewIcon />}
                        as={Link}
                        to={`/payroll/view/${p.payroll.id}/${slugify(p.payroll.name)}`}
                      />
                    </Tooltip>
                    <WithRoles roles={SUPER_ADMIN}>
                      <Tooltip label="Delete" aria-label="Delete Payroll">
                        <IconButton
                          colorScheme="red"
                          size="sm"
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          onClick={() =>
                            ref.current?.open(p.payroll.id, {
                              title: "Delete Payroll?",
                              message: <AlertMessageComponent payroll_name={p.payroll.name} />,
                            })
                          }
                        />
                      </Tooltip>
                    </WithRoles>
                  </ButtonGroup>
                </Flex>
              </Flex>

              <Table variant="striped" marginTop="16px !important" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={4}>Localisation</Th>
                  </Tr>
                  <Tr>
                    <Th w="10%">Name</Th>
                    <Th>Roles</Th>
                    <Th>Effective Episodes</Th>
                    <Th w="20%">Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {p.summary.localisation.map((l) => (
                    <Tr key={l.username}>
                      <Td>{l.name}</Td>
                      <Td>{l.roles.map((r) => r.name).join(", ")}</Td>
                      <Td>{l.total_episodes}</Td>
                      <Td>{moneyFormat(l.total_amount)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Table variant="striped" marginTop="16px !important" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={4}>Series Expenses</Th>
                  </Tr>
                  <Tr>
                    <Th>Series</Th>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th w="20%">Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {p.summary.series_expenses.map((s) => (
                    <Tr key={s.id}>
                      <Td>{s.title}</Td>
                      <Td>{s.user_name}</Td>
                      <Td>{s.description}</Td>
                      <Td>{moneyFormat(s.total_amount)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <WithRoles roles={SUPER_ADMIN}>
                <Table variant="striped" marginTop="16px !important" size="sm">
                  <Thead>
                    <Tr>
                      <Th colSpan={4}>Business Expenses</Th>
                    </Tr>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Category</Th>
                      <Th>Description</Th>
                      <Th w="20%">Total Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {p.summary.expenses.map((e) => (
                      <Tr key={e.username}>
                        <Td>{e.name}</Td>
                        <Td>{e.category}</Td>
                        <Td>{e.description}</Td>
                        <Td>{moneyFormat(e.total_amount)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </WithRoles>

              <Table variant="striped" marginTop="16px !important" size="sm" mb="16">
                <Thead>
                  <Tr>
                    <Th colSpan={2}>Payroll Total</Th>
                  </Tr>
                  <Tr>
                    <Th>Category</Th>
                    <Th w="20%">Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Localisation</Td>
                    <Td>{moneyFormat(p.summary.total_localisation)}</Td>
                  </Tr>
                  <WithRoles roles={SUPER_ADMIN}>
                    <Tr>
                      <Td>Expenses</Td>
                      <Td>{moneyFormat(p.summary.total_expenses)}</Td>
                    </Tr>
                    <Tr>
                      <Td>Total</Td>
                      <Td>{moneyFormat(p.summary.total_localisation + p.summary.total_expenses)}</Td>
                    </Tr>
                  </WithRoles>
                </Tbody>
              </Table>
            </div>
          ))}
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
