import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useToast } from "@chakra-ui/react";
import { WithLoading, WithRoles } from "@components";
import { WithSubRoles } from "@components/with-roles/with-sub-roles";
import { useEpisodesClaimList } from "@hooks/api/episodes.hook";
import { useCurrentLanguage, useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts/main";
import { makeTitleBySerie } from "@pages/series/series.utils";
import { EpisodesService } from "@services/episodes.service";
import { useAppSelector } from "@store";
import { slugify } from "@utils/slugify";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminCataloguePerRoleStatus } from "./AdminCataloguePerRoleStatus";
import { AdminSeriesEpisodesStatus } from "./AdminSeriesEpisodesStatus";
import { AdminStaffEfficiency } from "./AdminStaffEfficiency";
import { NextCatalogues } from "./NextCatalogues";
import { NextSimulcasts } from "./NextSimulcasts";
import { PublisherCalendarView } from "./PublisherCalendarView";
import { PublisherSeriesEpisodesView } from "./PublisherSeriesEpisodesView";

interface CardHeaderProps {
  title: string;
  action?: React.ReactNode;
  sub?: React.ReactNode;
}

export const CardHeader = (props: CardHeaderProps) => {
  const { title, action, sub } = props;
  return (
    <Flex direction="column" px="6" py="4" borderBottomWidth="1px">
      <Flex align="center" justify="space-between">
        <Heading as="h2" fontSize="lg">
          {title}
        </Heading>
        {action}
      </Flex>
      {sub}
    </Flex>
  );
};

const EpisodeDeleteClaimButton = ({
  time,
  user,
  id,
  refetchList,
}: {
  time: string;
  user: string;
  id: number;
  refetchList: () => void;
}) => {
  const translations = useTranslations();
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(moment().diff(moment(time), "minutes") < 15);

  const removeClaimEpisode = useCallback(async () => {
    setLoading(true);
    try {
      await EpisodesService.removeClaimEpisode(id);
    } catch (error) {
      console.log(error);
    } finally {
      refetchList();
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanDelete(moment().diff(moment(time), "minutes") < 15);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Tooltip
      label={translations["dashboard.timer.claim-delete-button"]}
      aria-label={translations["dashboard.timer.claim-delete-button"]}
    >
      <Button
        rightIcon={<DeleteIcon />}
        disabled={!canDelete}
        size="xs"
        color="red"
        onClick={() => removeClaimEpisode()}
        isLoading={loading}
      >
        {user}
      </Button>
    </Tooltip>
  );
};

const EpisodeClaimButton = ({ id, refetchList }: { id: number; refetchList: () => void }) => {
  const translations = useTranslations();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const claimEpisode = useCallback(async () => {
    setLoading(true);
    try {
      const status = await EpisodesService.claimEpisode(id);
      if (!status) {
        toast({
          status: "error",
          description: translations["dashboard.timer.claim-denied"],
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      refetchList();
      setLoading(false);
    }
  }, [id]);

  return (
    <Button size="xs" onClick={() => claimEpisode()} isLoading={loading}>
      {translations["dashboard.timer.claim-button"]}
    </Button>
  );
};

const calculateTimeLeft = (time: string) => {
  const diff = moment(time).diff(moment());
  const duration = moment.duration(diff, "milliseconds");
  return (
    (duration.months() ? duration.months() + "M " : "") +
    (duration.days() ? duration.days() + "D " : "") +
    duration.hours().toString().padStart(2, "0") +
    ":" +
    duration.minutes().toString().padStart(2, "0") +
    ":" +
    duration.seconds().toString().padStart(2, "0")
  );
};

export const CountdownTimer = ({ time }: { time: string }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(time));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(time));
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return <>{timeLeft}</>;
};

const TimerClaimCard = () => {
  const language = useCurrentLanguage();
  const translations = useTranslations();
  const userName = useAppSelector((state) => state.user.name);

  const { data: claimList, loading: loadingEpisodeClaimList, refetch: refetchClaim } = useEpisodesClaimList();

  const canClaim = !claimList?.find((c) => c.episodes.find((e) => e.claim_user_name == userName));

  return (
    <Box as="section" pt="12" px={{ md: "8" }}>
      <Box rounded={{ md: "lg" }} shadow="dark-lg" overflow="hidden" maxW="4xl" mx="auto">
        <CardHeader title={translations["dashboard.timer.title"]} />
        <Box p="5">
          <WithLoading loading={loadingEpisodeClaimList} centerH={false}>
            {claimList?.length > 0 && (
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>{translations["dashboard.timer.table.series"]}</Th>
                    <Th>{translations["dashboard.timer.table.episodes"]}</Th>
                    <Th>{translations["dashboard.timer.table.translated"]}</Th>
                    <Th>{translations["dashboard.timer.table.target"]}</Th>
                    <Th>{translations["dashboard.timer.table.claimant"]}</Th>
                    <Th>{translations["dashboard.timer.table.expires"]}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {claimList?.map((c) =>
                    c.episodes.map((e) => (
                      <Tr key={e.id}>
                        <Td>
                          <Link to={`/series/${c.series_id}/${slugify(c.series_short_title)}/episodes`}>
                            <Text color="blue.400">{makeTitleBySerie(c as any, language)}</Text>
                          </Link>
                        </Td>
                        <Td>{e.number}</Td>
                        <Td>{moment(e.translated).fromNow()}</Td>
                        <Td>{moment(e.target_date).fromNow()}</Td>
                        <Td>
                          {!e.claim_user_name && canClaim && (
                            <EpisodeClaimButton id={e.id} refetchList={refetchClaim} />
                          )}
                          {e.claim_user_name == userName && e.claim_start && (
                            <EpisodeDeleteClaimButton
                              time={e.claim_start}
                              id={e.id}
                              user={userName}
                              refetchList={refetchClaim}
                            />
                          )}
                          {!e.claim_user_name && !canClaim && (
                            <Text>{translations["dashboard.timer.table.claim-user-none"]}</Text>
                          )}
                          {e.claim_user_name != userName && e.claim_user_name}
                        </Td>
                        <Td>
                          {e.claim_end && <CountdownTimer time={e.claim_end} />}
                          {!e.claim_end && <Text>{translations["dashboard.timer.table.claim-end"]}</Text>}
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            )}
            {claimList?.length == 0 && <Text>{translations["dashboard.timer.empty"]}</Text>}
          </WithLoading>
        </Box>
      </Box>
    </Box>
  );
};

export const Home = () => {
  return (
    <MainLayout>
      <WithRoles roles={["publisher", "admin", "super_admin"]}>
        <PublisherCalendarView />
        <PublisherSeriesEpisodesView />
      </WithRoles>

      <WithRoles roles={["admin", "super_admin", "project_manager"]}>
        <AdminSeriesEpisodesStatus />
        <AdminCataloguePerRoleStatus />
        <AdminStaffEfficiency />
      </WithRoles>

      <WithSubRoles roles={["timer"]}>
        <TimerClaimCard />
      </WithSubRoles>

      <WithRoles roles={["normal", "admin", "super_admin", "project_manager"]}>
        <NextSimulcasts />
        <NextCatalogues />
      </WithRoles>
    </MainLayout>
  );
};
