import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, Container, RoleLabel, WithLoading } from "@components";
import { useUsersFindAll } from "@hooks/api/users.hook";
import { useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { UsersService } from "@services";
import { useAppSelector } from "@store";
import { useRef } from "react";
import { useHistory } from "react-router";

const AlertMessageComponent = (props: { user: string }) => {
  const translations = useTranslations();

  return (
    <Text>
      {translations["users.list.delete.message"]}{" "}
      <Text as="span" fontWeight="bold">
        #{props.user}
      </Text>
      ?
    </Text>
  );
};

export const Users = () => {
  const currentUsername = useAppSelector((state) => state.user.username);
  const translations = useTranslations();
  const toast = useToast();
  const history = useHistory();
  const ref = useRef<ConfirmModalRef>(null);
  const { data, loading, refetch } = useUsersFindAll();

  const handleClickEditUser = (userId: number) => history.push(`/users/${userId}`);
  const handleClickDeleteUser = async (userId: number) => {
    try {
      await UsersService.delete(userId);

      toast({
        status: "success",
        description: "User removed successfully",
      });

      refetch();
    } catch (error: any) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.lg" } }}>
        <ConfirmModal ref={ref} onConfirm={handleClickDeleteUser} />
        <WithLoading loading={loading}>
          <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end">
            <Button colorScheme="teal" size="sm" type="submit" onClick={() => history.push("/users/add")}>
              {translations["users.list.add"]}
            </Button>
          </ButtonGroup>
          <Table variant="striped" marginTop="16px !important">
            <Thead>
              <Tr>
                <Th>{translations["users.list.id"]}</Th>
                <Th>{translations["users.list.avatar"]}</Th>
                <Th colSpan={3}>{translations["users.list.name"]}</Th>
                <Th colSpan={2}>{translations["users.list.email"]}</Th>
                <Th colSpan={1}>{translations["users.list.username"]}</Th>
                <Th>{translations["users.list.type"]}</Th>
                <Th>{translations["users.list.actions"]}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((user) => {
                return (
                  <Tr key={user.id}>
                    <Td>{user.id}</Td>
                    <Td>
                      <Avatar size="sm" name={user.name} alt={`${user.username} avatar's`} src={user.image} />
                    </Td>
                    <Td colSpan={3}>{user.name}</Td>
                    <Td colSpan={2}>{user.email}</Td>
                    <Td colSpan={1}>{user.username}</Td>
                    <Td>
                      <RoleLabel role={user.permission} />
                    </Td>
                    <Td>
                      <ButtonGroup>
                        <IconButton
                          borderRadius="4"
                          size="sm"
                          aria-label="Edit"
                          icon={<EditIcon />}
                          onClick={() => handleClickEditUser(user.id)}
                        />
                        <IconButton
                          borderRadius="4"
                          size="sm"
                          isDisabled={currentUsername === user.username}
                          colorScheme="red"
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          onClick={() =>
                            ref.current?.open(user.id, {
                              title: translations["users.list.delete.title"],
                              message: <AlertMessageComponent user={`${user.id} - ${user.name}`} />,
                            })
                          }
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
