export const moneyFormat = (value?: number | null) => {
  if (!value && value !== 0) return "";

  const formatted = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);

  return formatted;
};

export const moneyValue = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};
