import { SeriesFindAllQueryParams, SeriesService } from "@services";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const useSeriesFindAll = (params: SeriesFindAllQueryParams = { archived: false }) => {
  const key = `/series?${JSON.stringify(params)}`;
  const fetcher = () => SeriesService.findAll(params);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useSeriesFindById = (id: string) => {
  const key = `/series/${id}`;
  const fetcher = () => (id ? SeriesService.findById(id) : null);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
