import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { SerieArtwork, WithLoading, WithRoles } from "@components";
import { useEpisodesFindAll, useSeriesFindById } from "@hooks/api";
import { useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { EpisodesTeamsService, Role, SeriesService } from "@services";
import { Episode, EpisodesService } from "@services/episodes.service";
import { useAppSelector } from "@store";
import { DateFormat } from "@utils";
import { contrastColor } from "@utils/contrast-color";
import { downloadFile } from "@utils/download-file";
import { getEpisodeRange } from "@utils/episodes";
import { ADMIN_PM, ADMIN_PM_PUBLISHER } from "@utils/permissions";
import JSZip from "jszip";
import { last, sortBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

const formatDateRole = (date: string) => DateFormat.toCustomDate(date, "DD-MM-YYYY [at] HH:mm:ss");

type EpisodeStatusProps = {
  episode: Episode;
  currentUserTeamMemberRoles?: Role[];
  onMarkRole: (roleId: number, episodeId: number) => void;
  serieRoles: Role[];
};

const EpisodeStatus = ({ episode, currentUserTeamMemberRoles, onMarkRole, serieRoles }: EpisodeStatusProps) => {
  if (!episode) {
    return <Skeleton />;
  }

  const len = episode.episodeteam?.length || 0;
  episode.episodeteam.sort((a, b) => b.role.order - a.role.order);

  let markButtons = null;

  if (currentUserTeamMemberRoles) {
    const r = currentUserTeamMemberRoles.filter(
      (r) => episode.episodeteam.filter((t) => t.role.id == r.id).length == 0
    )[0];

    const rolesDone = episode.episodeteam.map((t) => t.role.id);
    const nextRole = serieRoles
      .filter((role) => !rolesDone.includes(role.id))
      .reduce<Role | null>((acc, current) => {
        if (!acc) return current;
        if (current.order < acc.order) return current;
        return acc;
      }, null);

    if (nextRole && r && r.id == nextRole.id) {
      markButtons = (
        <ButtonGroup>
          <Button key={r.id} size="xs" color={"yellow.500"} onClick={() => onMarkRole(r.id, episode.id)}>
            Mark as {r.description}
          </Button>
        </ButtonGroup>
      );
    }
  }

  const scriptBadge = episode.script_available ? (
    <Tooltip
      label={`Script Avaliable on ${formatDateRole(episode.script_available)}`}
      aria-label={`Script Avaliable on ${formatDateRole(episode.script_available)}`}
    >
      <Badge p="1" mr="3px" color="black" background="blue.100">
        Script
      </Badge>
    </Tooltip>
  ) : null;

  const videoBadge = episode.video_available ? (
    <Tooltip
      label={`Video Avaliable on ${formatDateRole(episode.video_available)}`}
      aria-label={`Video Avaliable on ${formatDateRole(episode.video_available)}`}
    >
      <Badge p="1" mr="3px" color="black" background="orange.200">
        Video
      </Badge>
    </Tooltip>
  ) : null;

  const submittedBadge = episode.submitted_date ? (
    <Tooltip
      label={`Submitted on ${formatDateRole(episode.submitted_date)}`}
      aria-label={`Submitted on ${formatDateRole(episode.submitted_date)}`}
    >
      <Badge p="1" mr="3px">
        Submitted
      </Badge>
    </Tooltip>
  ) : null;

  if (len == 0) {
    return (
      <Flex alignItems="center">
        {!scriptBadge && !videoBadge && !submittedBadge && <Text>N/A</Text>}
        {scriptBadge}
        {videoBadge}
        {submittedBadge}
        {markButtons && (
          <Box ml="10px" style={{ display: "inline-block" }}>
            {markButtons}
          </Box>
        )}
      </Flex>
    );
  }

  const badge = episode.episodeteam.reverse().map((e) => (
    <React.Fragment key={e.id}>
      <Tooltip
        label={`${e.user.username} ${e.role.description} on ${formatDateRole(e.created_at)}`}
        aria-label={`${e.user.username} ${e.role.description} on ${formatDateRole(e.created_at)}`}
      >
        <Badge key={e.id} backgroundColor={e.role.color} color={contrastColor(e.role.color)} p="1" mr="3px">
          {e.role.description}
        </Badge>
      </Tooltip>
    </React.Fragment>
  ));

  return (
    <>
      {scriptBadge}
      {videoBadge}
      {badge}
      {submittedBadge}
      {markButtons && (
        <Box ml="10px" style={{ display: "inline-block" }}>
          {markButtons}
        </Box>
      )}
    </>
  );
};

const NoneMessage = () => {
  const translations = useTranslations();

  return (
    <Tr>
      <Td colSpan={4}>{translations["series.episodes.empty"]}</Td>
    </Tr>
  );
};

const EpisodeDownloadSubs = ({ episode, serieRoles }: { episode: Episode; serieRoles: Role[] }) => {
  const translations = useTranslations();
  const { episode_id } = useParams<{ name: string; id: string; episode_id?: string }>();

  const [loadingAss, setLoadingAss] = useState(false);
  const [loadingSrt, setLoadingSrt] = useState(false);
  const toast = useToast();

  const downloadSub = useCallback(async (type: string, setLoading: (val: boolean) => void) => {
    setLoading(true);

    try {
      const filename = await EpisodesService.downloadFileName(episode.id, type);
      const blob = await EpisodesService.download(episode.id, type);
      const fileURL = window.URL.createObjectURL(blob);
      downloadFile(fileURL, filename);
    } catch (error: any) {
      toast({
        status: "error",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (Number(episode_id) == episode.id) {
      downloadSub("srt", setLoadingSrt);
    }
  }, []);

  const disabled =
    !episode.episodeteam.find((et) => et.role.id == last(sortBy(serieRoles, "order"))?.id) && !episode.submitted_date;

  return (
    <>
      <Tooltip label={translations["series.episodes.download.srt"]}>
        <Button
          size="sm"
          color="gray.300"
          aria-label="Edit"
          onClick={() => downloadSub("srt", setLoadingSrt)}
          disabled={loadingSrt || disabled}
          isLoading={loadingSrt}
        >
          SRT
        </Button>
      </Tooltip>

      <Tooltip label={translations["series.episodes.download.ass"]}>
        <Button
          size="sm"
          color="red.300"
          aria-label="Edit"
          onClick={() => downloadSub("ass", setLoadingAss)}
          disabled={loadingAss || disabled}
          isLoading={loadingAss}
        >
          ASS
        </Button>
      </Tooltip>
    </>
  );
};

export const Episodes = () => {
  const translations = useTranslations();
  const { name, id, episode_id } = useParams<{ name: string; id: string; episode_id?: string }>();
  const { data: seriesData, loading: seriesLoading } = useSeriesFindById(id);
  const { data: episodesData, loading: episodesLoading, refetch } = useEpisodesFindAll(parseInt(id));
  const history = useHistory();
  const toast = useToast();

  const currentUsername = useAppSelector((state) => state.user.username);
  const currentUserTeamMemberRoles = seriesData?.team
    .filter((t) => t.user.username == currentUsername)
    ?.map((t) => t.role);

  currentUserTeamMemberRoles?.sort((a, b) => a.order - b.order);

  const serieRoles = seriesData?.team.map((t) => t.role) || [];

  const handleClickEditEpisode = (episodeId: number) => history.push(`/series/${id}/${name}/episode/${episodeId}`);

  const onMarkRole = useCallback(
    async (roleId: number, episodeId: number) => {
      try {
        await EpisodesTeamsService.create({ roleId, episodeId });
        await refetch();
      } catch {
        // nothing
      }
    },
    [refetch]
  );

  const submitAllEpisodes = useCallback(async () => {
    await SeriesService.submitAll(id);
    await refetch();
  }, [refetch]);

  const refreshSFTP = useCallback(async () => {
    await SeriesService.refreshSFTP(id);
    await refetch();
  }, [refetch]);

  const downloadAllSubs = useCallback(
    async (type: string) => {
      toast({
        status: "info",
        description: translations["series.episodes.download.all.loading"],
      });

      try {
        const zip = new JSZip();

        const episodes = episodesData.filter(
          (e) => e.submitted_date || e.episodeteam.length == seriesData?.team.length
        );

        for (const episode of episodes) {
          try {
            const filename = await EpisodesService.downloadFileName(episode.id, type);
            const blob = await EpisodesService.download(episode.id, type);
            zip.file(filename, blob);
          } catch {
            throw new Error(`Script for episode ${episode.number} is missing.`);
          }
        }

        const epiNumStr = getEpisodeRange(episodes.map((e) => [e.order, e.number]));

        const zipBlob = await zip.generateAsync({ type: "blob" });
        const fileURL = window.URL.createObjectURL(zipBlob);

        downloadFile(fileURL, `${seriesData?.subtitle_filename}_${epiNumStr}_en_Waku_${type.toUpperCase()}.zip`);
      } catch (error: any) {
        toast({
          status: "error",
          description: error.message,
        });
      }
    },
    [episodesData, seriesData]
  );

  useEffect(() => {
    if (episode_id == "all" && seriesData && episodesData) {
      downloadAllSubs("srt");
    }
  }, [episode_id, episodesData, seriesData]);

  const isSimulcast = seriesData?.type === "simulcast";
  const statusColumnWidthCatalogue = {
    base: "40%",
    md: "55%",
    lg: "60%",
    xl: "68%",
  };

  const statusColumnWidthSimulcast = {
    base: "40%",
    lg: "50%",
    xl: "57%",
  };
  const statusColumnWidth = isSimulcast ? statusColumnWidthSimulcast : statusColumnWidthCatalogue;

  return (
    <MainLayout>
      <Container maxW="container.xl">
        <WithLoading loading={episodesLoading || seriesLoading}>
          <Flex mt="20px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
            <Flex direction="column">
              <Flex>
                <SerieArtwork src={seriesData?.image} alt={seriesData?.short_title} />
                <VStack ml="6" spacing="2" align="flex-start">
                  <Heading size="lg">{seriesData?.short_title}</Heading>
                  <Text fontSize="sm">
                    <strong>Long Title: </strong>
                    {seriesData?.title}
                  </Text>
                  {seriesData?.title_en && (
                    <Text fontSize="sm">
                      <strong>EN Title: </strong>
                      {seriesData?.title_en}
                    </Text>
                  )}
                  {seriesData?.title_jp && (
                    <Text fontSize="sm">
                      <strong>JP Title: </strong>
                      {seriesData?.title_jp}
                    </Text>
                  )}
                  {seriesData?.title_ch && (
                    <Text fontSize="sm">
                      <strong>CN Title: </strong>
                      {seriesData?.title_ch}
                    </Text>
                  )}
                  {isSimulcast && seriesData && (
                    <Text fontSize="sm">
                      <strong>{translations["series.episodes.airing-date"]}: </strong>
                      {DateFormat.toCustomDate(seriesData?.air_date, "dddd [at] HH:mm")}
                    </Text>
                  )}
                  {!isSimulcast && seriesData && (
                    <Text fontSize="sm">
                      <strong>{translations["series.episodes.deadline"]}: </strong>
                      {DateFormat.toCustomDate(seriesData?.deadline, "DD-MM-YYYY [at] HH:mm")}
                    </Text>
                  )}
                </VStack>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} mt="8" mb="4" justify="space-between" align="flex-end">
            <Heading as="span" size="md" textTransform="capitalize">
              {translations["series.episodes"]}
            </Heading>
            <Flex>
              <WithRoles roles={ADMIN_PM_PUBLISHER}>
                <Menu>
                  <MenuButton size="sm" as={Button} rightIcon={<ChevronDownIcon />}>
                    {translations["series.options"]}
                  </MenuButton>
                  <MenuList>
                    <WithRoles roles={ADMIN_PM}>
                      <MenuItem as={Link} to={`/series/${id}/edit`}>
                        <span>{translations["series.episodes.options.edit-series"]}</span>
                      </MenuItem>
                      <MenuItem as={Link} to={`/series/${id}/${name}/expenses`}>
                        <span>{translations["series.episodes.options.expenses"]}</span>
                      </MenuItem>
                      <MenuItem as={Link} to={`/series/${id}/${name}/payroll`}>
                        <span>{translations["series.episodes.options.payroll"]}</span>
                      </MenuItem>
                      <MenuItem as={Link} to={`/series/${id}/${name}/episodes/edit`}>
                        <span>{translations["series.episodes.options.edit-episodes-and-flags"]}</span>
                      </MenuItem>
                      <MenuItem onClick={() => refreshSFTP()}>
                        <span>{translations["series.episodes.options.refresh-sftp"]}</span>
                      </MenuItem>
                      <MenuItem onClick={() => submitAllEpisodes()}>
                        <span>{translations["series.episodes.options.submit-all"]}</span>
                      </MenuItem>
                    </WithRoles>
                    <MenuItem onClick={() => downloadAllSubs("srt")}>
                      <span>{translations["series.episodes.options.download-srt"]}</span>
                    </MenuItem>
                    <MenuItem onClick={() => downloadAllSubs("ass")}>
                      <span>{translations["series.episodes.options.download-ass"]}</span>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </WithRoles>
            </Flex>
          </Flex>

          <Divider mb="30px" />

          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th w="5%">#</Th>
                <Th w={statusColumnWidth}>{translations["series.episodes.status"]}</Th>
                <Th>{translations["series.episodes.target-date"]}</Th>
                {isSimulcast && <Th>{translations["series.episodes.airing-date"]}</Th>}
                <WithRoles roles={ADMIN_PM_PUBLISHER}>
                  <Th w="15%" textAlign="center">
                    {translations["series.episodes.actions"]}
                  </Th>
                </WithRoles>
              </Tr>
            </Thead>
            <Tbody>
              {episodesLoading && (
                <Tr>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td justifyContent="space-around" display="flex">
                    <Skeleton />
                  </Td>
                </Tr>
              )}
              {episodesData?.length == 0 && <NoneMessage />}
              {episodesData?.map((episode: Episode) => (
                <Tr key={episode.id}>
                  <Td>{episode.number}</Td>
                  <Td>
                    <EpisodeStatus
                      episode={episode}
                      currentUserTeamMemberRoles={currentUserTeamMemberRoles}
                      onMarkRole={onMarkRole}
                      serieRoles={serieRoles}
                    />
                  </Td>
                  <Td>
                    {episode.target_submission_date && DateFormat.toDateTimeString(episode.target_submission_date)}
                  </Td>
                  {isSimulcast && <Td>{DateFormat.toDateTimeString(episode.air_date)}</Td>}
                  <WithRoles roles={ADMIN_PM_PUBLISHER}>
                    <Td textAlign="center">
                      <ButtonGroup>
                        <EpisodeDownloadSubs episode={episode} serieRoles={serieRoles} />
                        <WithRoles roles={ADMIN_PM}>
                          <Tooltip label="Edit" aria-label="Edit episode">
                            <IconButton
                              size="sm"
                              aria-label="Edit"
                              icon={<EditIcon />}
                              onClick={() => handleClickEditEpisode(episode.id)}
                            />
                          </Tooltip>
                        </WithRoles>
                      </ButtonGroup>
                    </Td>
                  </WithRoles>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
