import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { WithLoading } from "@components";
import { useDashboardAdminSeriesEpisodes } from "@hooks/api/dashboard.hook";
import { useCurrentLanguage, useTranslations } from "@hooks/useTranslations";
import { CardHeader } from "@pages";
import { makeTitleBySerie } from "@pages/series/series.utils";
import { Serie } from "@services/series.service";
import { contrastColor } from "@utils/contrast-color";
import { slugify } from "@utils/slugify";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

export const AdminSeriesEpisodesStatus = () => {
  const language = useCurrentLanguage();
  const translations = useTranslations();
  const { data: seriesEpisodes, loading: seriesEpisodesLoading } = useDashboardAdminSeriesEpisodes();

  return (
    <Box as="section" pt="6" px={{ md: "8" }}>
      <Box rounded={{ md: "lg" }} shadow="dark-lg" pb="25px" x="auto">
        <CardHeader title={translations["dashboard.simulcast-overview"]} />
        <Flex
          overflowX={"auto"}
          x="auto"
          display="block"
          sx={{
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
          }}
        >
          <WithLoading centerH={false} loading={seriesEpisodesLoading}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th minWidth="200px" maxWidth="200px" width="200px">
                    {translations["dashboard.simulcast-overview.series"]}
                  </Th>
                  <Th colSpan={seriesEpisodes?.maxEpisodes}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {seriesEpisodes?.series.map((s) => (
                  <React.Fragment key={s.id}>
                    <Tr>
                      <Td rowSpan={2}>
                        <Link to={`/series/${s.id}/${slugify(s.short_title || "")}/episodes`}>
                          <Text color="blue.400">{makeTitleBySerie(s as Serie, language)}</Text>
                        </Link>
                      </Td>
                      {s.episodes.map((e) => (
                        <Td key={e.id} textAlign="center" fontSize={"12px"} width="150px">
                          {e.number}
                        </Td>
                      ))}
                      {seriesEpisodes?.maxEpisodes - s.episodes.length > 0 && (
                        <Td colSpan={seriesEpisodes?.maxEpisodes - s.episodes.length}>&nbsp;</Td>
                      )}
                    </Tr>
                    <Tr>
                      {s.episodes.map((e) => (
                        <React.Fragment key={e.id}>
                          {e.last_status && (
                            <Td
                              textAlign="center"
                              backgroundColor={e.last_status.color}
                              color={contrastColor(e.last_status.color)}
                              fontSize={"12px"}
                              fontWeight="bold"
                            >
                              {e.last_status.description} - {moment(e.last_status.date).fromNow()}
                            </Td>
                          )}
                          {!e.last_status && <Td>&nbsp;</Td>}
                        </React.Fragment>
                      ))}
                      {seriesEpisodes?.maxEpisodes - s.episodes.length > 0 && (
                        <Td colSpan={seriesEpisodes?.maxEpisodes - s.episodes.length}>&nbsp;</Td>
                      )}
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          </WithLoading>
        </Flex>
      </Box>
    </Box>
  );
};
