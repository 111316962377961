import { Container, Divider, Flex, Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { WithLoading } from "@components";
import { usePayrollSeries } from "@hooks/api/payroll.hook";
import { useSeriesFindById } from "@hooks/api/series.hook";
import { MainLayout } from "@layouts";
import { SeriesPayrollView } from "@pages";
import { moneyFormat } from "@utils/money-format";
import { useParams } from "react-router";

export const SeriesPayrollSummary = () => {
  const { id } = useParams<{ name: string; id: string }>();
  const { data: seriesData, loading: seriesLoading } = useSeriesFindById(id);
  const { data: payrollData, loading: payrollLoading } = usePayrollSeries(id);

  const loading = seriesLoading || payrollLoading;

  const amountTotal = payrollData?.payrolls.reduce((acc, current) => acc + current.amount, 0);
  const expensesTotal = payrollData?.payrolls.reduce((acc, current) => acc + current.expenses, 0);

  return (
    <MainLayout>
      <Container maxW="container.lg">
        <WithLoading loading={loading}>
          <Flex mt="20px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
            <Flex direction="column">
              <Heading size="md" textTransform="capitalize">
                {seriesData?.short_title} - Payroll
              </Heading>
            </Flex>
          </Flex>

          <Divider mb="30px" />

          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th w="10%">Payroll</Th>
                <Th>Episodes</Th>
                <Th>Amount</Th>
                <Th>Expenses</Th>
                <Th w="15%">Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payrollData?.payrolls.map((p) => (
                <Tr key={p.payroll_id}>
                  <Td>{p.payroll_name}</Td>
                  <Td>{p.episodes_range}</Td>
                  <Td>{moneyFormat(p.amount)}</Td>
                  <Td>{moneyFormat(p.expenses)}</Td>
                  <Td>{moneyFormat(p.amount + p.expenses)}</Td>
                </Tr>
              ))}
              <Tr>
                <Td colSpan={2} fontWeight="bold">
                  Total
                </Td>
                <Td fontWeight="bold">{moneyFormat(amountTotal)}</Td>
                <Td fontWeight="bold">{moneyFormat(expensesTotal)}</Td>
                <Td fontWeight="bold">{moneyFormat(amountTotal + expensesTotal)}</Td>
              </Tr>
            </Tbody>
          </Table>

          <Divider mb="30px" />

          {payrollData?.series && (
            <SeriesPayrollView key={payrollData.series.id} serie={payrollData.series} hideTitle />
          )}
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
