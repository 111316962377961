import { EpisodesService } from "@services/episodes.service";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const useEpisodesFindAll = (seried_id: number) => {
  const key = `/episodes/series/${seried_id}`;
  const fetcher = () => EpisodesService.findAll(seried_id);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useEpisodesClaimList = () => {
  const key = `/episodes/claim`;
  const fetcher = () => EpisodesService.claimList();
  const swr = useSWR(key, fetcher, { refreshInterval: 5000 });

  return makeSwrResponse(swr);
};

export const useEpisodesFindById = (id: number) => {
  const key = `/episodes/${id}`;
  const fetcher = () => (id ? EpisodesService.findById(id) : null);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
