import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, WithLoading, WithRoles } from "@components";
import { PageHeader } from "@components/page";
import { useInvoiceHistory } from "@hooks/api/invoice.hook";
import { useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { InvoiceService } from "@services";
import { DateFormat } from "@utils";
import { moneyFormat } from "@utils/money-format";
import { SUPER_ADMIN } from "@utils/permissions";
import { slugify } from "@utils/slugify";
import React, { useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const AlertMessageComponent = (props: { name: string }) => (
  <Text>
    Are you sure you want to delete the invoice{" "}
    <Text as="span" fontWeight="bold">
      {props.name}
    </Text>
    ?
  </Text>
);

export const InvoiceHistory = () => {
  const translations = useTranslations();
  const ref = useRef<ConfirmModalRef>(null);
  const history = useHistory();

  const toast = useToast();

  const { data: invoices, loading, refetch } = useInvoiceHistory();

  const handleClickDeletePayroll = async (id: number) => {
    try {
      await InvoiceService.delete(id);
      await refetch();
    } catch {
      toast({
        status: "error",
        description: "Error when deleting invoice.",
        isClosable: true,
      });
      return;
    }

    toast({
      status: "success",
      description: "Invoice deleted successfully.",
      isClosable: true,
    });
  };

  return (
    <MainLayout>
      <Container maxW={{ base: "full", sm: "container.lg" }}>
        <ConfirmModal ref={ref} onConfirm={handleClickDeletePayroll} />

        <PageHeader title={translations["invoices.history.title"]}>
          <WithRoles roles={SUPER_ADMIN}>
            <ButtonGroup variant="outline" spacing="2" width="100%" justifyContent="flex-end">
              <Button colorScheme="teal" size="sm" type="submit" onClick={() => history.push("/invoice/create")}>
                {translations["invoices.history.create-button"]}
              </Button>
            </ButtonGroup>
          </WithRoles>
        </PageHeader>

        <WithLoading loading={loading}>
          {invoices?.map((i) => (
            <Box key={i.id} pb={5}>
              <Flex justifyContent="space-between" alignItems="center" pt="15px">
                <Flex direction="column">
                  <Heading size="md" p="0" m="0">
                    {i.publisher.name} - {i.name} - #{i.number.toString().padStart(3, "0")}
                  </Heading>
                  <Heading size="xs" mt="5px !important" fontWeight="normal">
                    {DateFormat.toDateString(i.period_start)} - {DateFormat.toDateString(i.period_end)} (UTC)
                  </Heading>
                </Flex>
                <Flex>
                  <ButtonGroup>
                    <IconButton
                      size="sm"
                      aria-label="View"
                      icon={<ViewIcon />}
                      as={Link}
                      to={`/invoice/view/${i.id}/${slugify(i.name)}`}
                    />
                    <WithRoles roles={SUPER_ADMIN}>
                      <IconButton
                        colorScheme="red"
                        size="sm"
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        onClick={() =>
                          ref.current?.open(i.id, {
                            title: "Delete Invoice?",
                            message: <AlertMessageComponent name={i.name} />,
                          })
                        }
                      />
                    </WithRoles>
                  </ButtonGroup>
                </Flex>
              </Flex>

              <Table variant="striped" marginTop="16px !important" size="sm">
                <Thead>
                  <Tr>
                    <Th colSpan={3}>{translations["invoices.history.table.title"]}</Th>
                  </Tr>
                  <Tr>
                    <Th>{translations["invoices.history.table.series"]}</Th>
                    <Th>{translations["invoices.history.table.effective-episodes"]}</Th>
                    <Th w="15%">{translations["invoices.history.table.total"]}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {i.series.map((s) => (
                    <Tr key={s.id}>
                      <Td>{s.title}</Td>
                      <Td>{s.num_episodes}</Td>
                      <Td>{moneyFormat(s.amount)}</Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td>
                      {translations["invoices.history.table.number-of-series"]}: {i.num_series}
                    </Td>
                    <Td>{i.num_episodes}</Td>
                    <Td>{moneyFormat(i.total)}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          ))}
          {invoices && invoices.length == 0 && <Text>{translations["invoices.history.empty"]}</Text>}
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
