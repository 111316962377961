import { Divider, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Container, WithLoading } from "@components";
import { usePayrollNext } from "@hooks/api/payroll.hook";
import { MainLayout } from "@layouts";
import { PayrollNextPayload } from "@services";
import { moneyFormat } from "@utils/money-format";
import moment from "moment";
import { SeriesPayrollView } from "./view";

type RenderPayrollProps = {
  payroll: PayrollNextPayload;
  title: string;
};

const NextPayrollDetails = ({ payroll, title }: RenderPayrollProps) => {
  return (
    <>
      <Heading size="lg">{title}</Heading>

      {payroll?.series.map((serie) => (
        <SeriesPayrollView key={serie.id} serie={serie} hideExpenses hideUser />
      ))}

      {payroll?.series_expenses && payroll?.series_expenses.length > 0 && (
        <>
          <Heading size="md">Series Expenses</Heading>
          <Table variant="striped" marginTop="16px !important">
            <Thead>
              <Tr>
                <Th>Series</Th>
                <Th>Description</Th>
                <Th w="20%">Total Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payroll?.series_expenses.map((s) => (
                <Tr key={s.id}>
                  <Td>{s.title}</Td>
                  <Td>{s.description}</Td>
                  <Td>{moneyFormat(s.total_amount)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}

      {payroll?.expenses && payroll?.expenses.length > 0 && (
        <>
          <Heading size="md">Business Expenses</Heading>
          <Table variant="striped" marginTop="16px !important">
            <Thead>
              <Tr>
                <Th>Category</Th>
                <Th>Description</Th>
                <Th w="20%">Total Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payroll?.expenses.map((e) => (
                <Tr key={e.username}>
                  <Td>{e.category}</Td>
                  <Td>{e.description}</Td>
                  <Td>{moneyFormat(e.total_amount)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}

      <Divider />
      {<Text>Total amount: {moneyFormat(payroll?.total_amount)}</Text>}
    </>
  );
};

const RenderNoPayroll = () => {
  return <Text>No payment due.</Text>;
};

export const NextPayroll = () => {
  const { data: payrollNextFuture, loading: payrollLoading } = usePayrollNext();

  const loading = payrollLoading;
  const hasPayroll =
    !loading && (payrollNextFuture?.next.total_amount > 0 || payrollNextFuture?.future.total_amount > 0);

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.lg" } }}>
        <Heading size="lg">Next Payment Preview</Heading>
        <Divider />
        <WithLoading loading={loading}>
          {payrollNextFuture?.next.total_amount > 0 && (
            <>
              <NextPayrollDetails
                payroll={payrollNextFuture.next}
                title={moment().subtract(1, "month").format("MMMM YYYY")}
              />
              {payrollNextFuture?.future.total_amount > 0 && <Divider />}
            </>
          )}
          {payrollNextFuture?.future.total_amount > 0 && (
            <NextPayrollDetails payroll={payrollNextFuture.future} title={moment().format("MMMM YYYY")} />
          )}
          {!hasPayroll && <RenderNoPayroll />}
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
