import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language, Translations } from "@services/translations.service";

type TranslationStoreState = {
  translations: Translations;
  currentLanguage: Language;
  languages: Language[];
};

const initialState: TranslationStoreState = {
  languages: [],
  currentLanguage: {} as Language,
  translations: {} as Translations,
};

const translationsSlice = createSlice({
  initialState,
  name: "translations",
  reducers: {
    initTranslations: (
      state,
      { payload }: PayloadAction<Pick<TranslationStoreState, "languages" | "translations">>
    ) => {
      state.currentLanguage = payload.languages[0];
      state.languages = payload.languages;
      state.translations = payload.translations;
    },
    changeCurrentLanguage: (state, action: PayloadAction<Pick<TranslationStoreState, "currentLanguage">>) => {
      state.currentLanguage = action.payload.currentLanguage;
    },
    changeTranslations: (state, action: PayloadAction<Pick<TranslationStoreState, "translations">>) => {
      state.translations = action.payload.translations;
    },
    changeTranslationKeyText: (state, action: PayloadAction<{ key: string; text: string }>) => {
      Object.assign(state.translations, {
        [action.payload.key]: action.payload.text,
      });
    },
  },
});

export const { initTranslations, changeCurrentLanguage, changeTranslations, changeTranslationKeyText } =
  translationsSlice.actions;

export default translationsSlice.reducer;
