import { ExpensesService } from "@services/expenses.service";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const usePayrollExpenses = () => {
  const key = `/expenses`;
  const fetcher = () => ExpensesService.findAll();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
