import { InvoiceService } from "@services/invoice.service";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const useInvoiceCreateData = (publisher_id?: number) => {
  const key = publisher_id ? `/invoice/create/${publisher_id}` : null;
  const fetcher = () => InvoiceService.loadCreate(publisher_id || 0);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useInvoiceNumber = (publisher_id?: number) => {
  const key = publisher_id ? `/invoice/next_number/${publisher_id}` : null;
  const fetcher = () => InvoiceService.nextInvoiceNumber(publisher_id || 0);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useInvoiceHistory = () => {
  const key = `/invoice/history`;
  const fetcher = () => InvoiceService.history();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useInvoiceView = (id: number) => {
  const key = `/invoice/view/${id}`;
  const fetcher = () => InvoiceService.view(id);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useInvoiceNext = (publisher_id: string) => {
  const key = `/invoice/next/${publisher_id}`;
  const fetcher = () => InvoiceService.next(publisher_id);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
