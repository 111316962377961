import { env } from "@config/env";
import { store } from "@store";
import { setUser } from "@store/modules/user";
import axios from "axios";
import jwt from "jsonwebtoken";
import { AuthService } from "./auth.service";

export const api = axios.create({
  baseURL: env.api,
});

api.interceptors.request.use(async (config) => {
  const token = store.getState()?.user?.token;

  if (!token) return config;

  config.headers.authorization = makeBearerToken(token);

  const payload = jwt.decode(token, { complete: true });

  const expiration = payload?.payload.exp;

  if (!expiration) throw new Error("Error to decoded expiration date (JWT)");

  const expirationTimestamp = expiration * 1000;

  if (Date.now() >= expirationTimestamp && !config.url?.includes("refresh-token")) {
    const response = await AuthService.refreshToken(makeBearerToken(token));

    store.dispatch(setUser({ token: response.token }));

    config.headers.authorization = makeBearerToken(response.token);
  }

  return config;
});

const makeBearerToken = (token: string) => `Bearer ${token}`;
