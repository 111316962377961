import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Container, Form, InputForm, InputSelectForm, SelectOptions } from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { PageHeader } from "@components/page";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEpisodesFindById } from "@hooks/api/episodes.hook";
import { useSeriesFindById } from "@hooks/api/series.hook";
import { MainLayout } from "@layouts";
import { EpisodesService } from "@services/episodes.service";
import { DateFormat } from "@utils";
import { contrastColor } from "@utils/contrast-color";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

const INVOICE_TYPE_SELECT_OPTIONS: SelectOptions = [
  { label: "Short Length or PV", value: "pv" },
  { label: "Normal Length", value: "normal" },
  { label: "Double Length or Movie", value: "double_or_movie" },
];

const FORM_LABELS = {
  order: "Order",
  number: "Number",
  air_date: "Air Date (UTC)",
  title: "Title",
  video_available_flag: "Video Available",
  script_available_flag: "Script Available",
  submitted_date_flag: "Submitted",
  duration: "Duration (seconds)",
  target_submission_date: "Target Submission Date (UTC)",
  invoice_type: "Invoice Entry Type",
};

const SCHEMA = Yup.object({
  order: Yup.number().required().label(FORM_LABELS.order),
  number: Yup.string().required().label(FORM_LABELS.number),
  air_date: Yup.string().required().label(FORM_LABELS.air_date),
  target_submission_date: Yup.string().required().label(FORM_LABELS.target_submission_date),
  title: Yup.string().nullable().label(FORM_LABELS.title),
  video_available_flag: Yup.boolean().required().label(FORM_LABELS.video_available_flag),
  script_available_flag: Yup.boolean().required().label(FORM_LABELS.script_available_flag),
  submitted_date_flag: Yup.boolean().required().label(FORM_LABELS.submitted_date_flag),
  duration: Yup.number().required().label(FORM_LABELS.duration),
  invoice_type: Yup.mixed().oneOf(["normal", "pv", "double_or_movie"]).required().label(FORM_LABELS.invoice_type),
});

type FormFields = Yup.InferType<typeof SCHEMA>;

const DEFAULT_VALUES: FormFields = {
  order: 1,
  number: "1",
  air_date: "",
  title: "",
  video_available_flag: false,
  script_available_flag: false,
  submitted_date_flag: false,
  duration: 0,
  target_submission_date: "",
  invoice_type: "normal",
};

const BADGE_STATUS_DATE_FORMAT = "DD-MM-YYYY [at] HH:mm";

export const Episode = () => {
  const history = useHistory();
  const toast = useToast();
  const {
    id: series_id,
    name: series_name,
    episode_id,
  } = useParams<{ id: string; name: string; episode_id: string }>();
  const { data: seriesData, loading: seriesLoading } = useSeriesFindById(series_id);
  const { data: episodeData, loading: episodeLoading, refetch } = useEpisodesFindById(parseInt(episode_id));

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  const onSubmit = async (values: FormFields) => {
    try {
      await EpisodesService.updateById(episode_id, {
        order: values.order,
        number: values.number,
        air_date: values.air_date,
        duration: values.duration,
        title: values.title || "",
        video_available_flag: values.video_available_flag,
        script_available_flag: values.script_available_flag,
        submitted_date_flag: values.submitted_date_flag,
        target_submission_date: values.target_submission_date,
        invoice_type: values.invoice_type,
      });

      const message = `Episode updated successfully`;

      toast({
        status: "success",
        description: message,
      });

      refetch();
    } catch (error: any) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  const goToEpisodes = () => {
    history.push(`/series/${series_id}/${series_name}/episodes`);
  };

  const goToEpisode = (episodeId?: number) => {
    history.push(`/series/${series_id}/${series_name}/episode/${episodeId}`);
  };

  const { isSubmitting, isDirty } = methods.formState;

  useEffect(() => {
    if (!episodeData) return;
    const formResetState = {
      ...episodeData,
      video_available_flag: !!episodeData.video_available,
      script_available_flag: !!episodeData.script_available,
      submitted_date_flag: !!episodeData.submitted_date,
      air_date: DateFormat.toDateForm(episodeData.air_date || DEFAULT_VALUES.air_date),
      target_submission_date: episodeData.target_submission_date
        ? DateFormat.toDateForm(episodeData.target_submission_date)
        : "",
    };

    methods.reset(formResetState);
  }, [episodeData]);

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.lg" } }}>
        <PageHeader
          render={
            <Skeleton isLoaded={!(seriesLoading || episodeLoading)}>
              <Heading size="md" textTransform="capitalize">
                {seriesData?.short_title} - Episode {episodeData?.number}
              </Heading>
            </Skeleton>
          }
        >
          <ButtonGroup variant="outline" spacing="4">
            <Button colorScheme="red" size="md" onClick={goToEpisodes}>
              Episodes
            </Button>
            <Button
              colorScheme="blue"
              size="md"
              isDisabled={!episodeData?.previousEpisodeId}
              onClick={() => goToEpisode(episodeData?.previousEpisodeId)}
            >
              Previous
            </Button>
            <Button
              colorScheme="blue"
              size="md"
              isDisabled={!episodeData?.nextEpisodeId}
              onClick={() => goToEpisode(episodeData?.nextEpisodeId)}
            >
              Next
            </Button>
          </ButtonGroup>
        </PageHeader>
        <Form methods={methods} onSubmit={onSubmit}>
          <HStack alignItems="flex-start">
            <Flex minWidth="224px">
              <Heading size="sm">Episode Info</Heading>
            </Flex>
            <VStack w="full">
              <InputForm label={FORM_LABELS.order} name="order" />
              <InputForm label={FORM_LABELS.number} name="number" />
              <InputForm
                label={FORM_LABELS.target_submission_date}
                name="target_submission_date"
                type="datetime-local"
              />
              <InputForm label={FORM_LABELS.air_date} name="air_date" type="datetime-local" />
              <InputForm label={FORM_LABELS.title} name="title" />
              <InputForm label={FORM_LABELS.duration} name="duration" />
              <InputSelectForm
                name="invoice_type"
                label={FORM_LABELS.invoice_type}
                options={INVOICE_TYPE_SELECT_OPTIONS}
                formControlProps={{ isRequired: true }}
              />
            </VStack>
          </HStack>
          <Divider mt="30px" mb="30px" />
          <HStack alignItems="flex-start">
            <Flex minWidth="224px">
              <Heading size="sm">Flags</Heading>
            </Flex>
            <VStack w="xs" alignItems="flex-start">
              <Skeleton w="xs" isLoaded={!(seriesLoading || episodeLoading)}>
                <InputSwitchForm label={FORM_LABELS.video_available_flag} name="video_available_flag" />
                <InputSwitchForm label={FORM_LABELS.script_available_flag} name="script_available_flag" />
                <InputSwitchForm label={FORM_LABELS.submitted_date_flag} name="submitted_date_flag" />
              </Skeleton>
            </VStack>
          </HStack>
          <Divider mt="30px" mb="30px" />
          <HStack alignItems="flex-start">
            <Flex minWidth="224px">
              <Heading size="sm">Status</Heading>
            </Flex>
            <VStack w="full" alignItems="flex-start">
              {episodeData?.episodeteam
                .sort((a, b) => a.role.order - b.role.order)
                .map((episodeteam) => (
                  <Badge
                    key={episodeteam.id}
                    backgroundColor={episodeteam.role.color}
                    color={contrastColor(episodeteam.role.color)}
                    p="2"
                  >
                    {`${episodeteam.user.username} ${episodeteam.role.description} on 
                    ${DateFormat.toCustomDate(episodeteam.created_at, BADGE_STATUS_DATE_FORMAT)}`}
                  </Badge>
                ))}
              {episodeData?.submitted_date && (
                <Badge p="2">{`Submitted ON ${DateFormat.toCustomDate(
                  episodeData.submitted_date,
                  BADGE_STATUS_DATE_FORMAT
                )}`}</Badge>
              )}
              {(!episodeData || episodeData.episodeteam.length == 0) && <Text>Nothing</Text>}
            </VStack>
          </HStack>
          <HStack mt="30px">
            <Box minW="224px" />
            <ButtonGroup variant="outline" spacing="4">
              <Button colorScheme="red" size="md" onClick={goToEpisodes}>
                Back
              </Button>
              <Button colorScheme="teal" size="md" type="submit" isLoading={isSubmitting} isDisabled={!isDirty}>
                Save
              </Button>
            </ButtonGroup>
          </HStack>
        </Form>
      </Container>
    </MainLayout>
  );
};
