import { api } from "./api";
import { User } from "./users.service";
import { handleRequestPromise } from "./_utils_";

export const ExpensesService = {
  findAll: async (): Promise<Expense[]> => {
    const promise = api.get<Expense[]>(`expenses`);
    return handleRequestPromise(promise);
  },
  create: async (expense: Partial<ExpensePayload>): Promise<boolean> => {
    const promise = api.post<boolean>(`expenses`, expense);
    return handleRequestPromise(promise);
  },
  updateById: async (id: string, expense: Partial<ExpensePayload>): Promise<boolean> => {
    const promise = api.patch<boolean>(`expenses/${id}`, expense);
    return handleRequestPromise(promise);
  },
  deleteById: async (id: string): Promise<boolean> => {
    const promise = api.delete<boolean>(`expenses/${id}`);
    return handleRequestPromise(promise);
  },
};

export type Expense = {
  id: number;
  user_id: number;
  user: User;
  recurrent: boolean;
  category: string;
  description: string;
  amount: number;
};

export type ExpensePayload = {
  id: number;
  user_id: number;
  recurrent: boolean;
  category: string;
  description: string;
  amount: number;
};
