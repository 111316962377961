import { api } from "./api";
import { User } from "./users.service";
import { handleRequestPromise } from "./_utils_";

export const SeriesExpensesService = {
  findAllBySerieId: async (serieId: string) => {
    const promise = api.get<SeriesExpense[]>(`series/expenses/${serieId}`);
    return handleRequestPromise(promise);
  },
  create: async (seriesExpense: Partial<SeriesExpensePayload>) => {
    const promise = api.post<boolean>("series/expenses", seriesExpense);
    return handleRequestPromise(promise);
  },
  updateById: async (serieExpenseId: string, seriesExpense: Partial<SeriesExpensePayload>) => {
    const promise = api.patch<boolean>(`series/expenses/${serieExpenseId}`, seriesExpense);
    return handleRequestPromise(promise);
  },
  deleteById: async (serieExpenseId: string) => {
    const promise = api.delete<boolean>(`series/expenses/${serieExpenseId}`);
    return handleRequestPromise(promise);
  },
};

export type SeriesExpensePayload = Omit<SeriesExpense, "user" | "created_at">;

export type SeriesExpense = {
  id: number;
  user_id: number;
  name: string;
  description: string;
  amount: number;
  recurrent: boolean;
  serie_id: number;
  created_at: Date;
  user: User;
};
