import { Container, Divider, Heading, Text } from "@chakra-ui/layout";
import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { InputSelectForm, SelectOptions, WithLoading, WithRoles } from "@components";
import { PageHeader } from "@components/page";
import { useInvoiceNext } from "@hooks/api/invoice.hook";
import { usePublishersFindAll } from "@hooks/api/publishers.hook";
import { useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { activityToString } from "@pages";
import { InvoiceCreateDataPayload } from "@services";
import { getEpisodeRange } from "@utils/episodes";
import { toFixed } from "@utils/math";
import { moneyFormat, moneyValue } from "@utils/money-format";
import { ADMIN_ROLES } from "@utils/permissions";
import { flatten, groupBy, orderBy } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";

type EpisodesAcitivitiesType = {
  id: number;
  invoice_type: string;
  number: string;
  order: number;
  price: number;
  duration: number;
  activity: string;
};

const getEpisodesActivities = (episodes: InvoiceCreateDataPayload["episodes"][number]) => {
  return episodes.reduce<EpisodesAcitivitiesType[]>((acc, current) => {
    const ret = [];
    if (current.publisher.options.full_tl) {
      ret.push({
        id: current.id,
        order: current.order,
        invoice_type: current.invoice_type,
        duration: current.publisher.duration,
        number: current.number,
        price: current.publisher.rate,
        activity: "full_tl",
      });
    }
    if (current.publisher.options.major_tlc) {
      ret.push({
        id: current.id,
        order: current.order,
        invoice_type: current.invoice_type,
        duration: current.publisher.duration,
        number: current.number,
        price: current.publisher.rate_major_tlc,
        activity: "major_tlc",
      });
    }
    if (current.publisher.options.minor_tlc) {
      ret.push({
        id: current.id,
        order: current.order,
        invoice_type: current.invoice_type,
        duration: current.publisher.duration,
        number: current.number,
        price: current.publisher.rate_minor_tlc,
        activity: "minor_tlc",
      });
    }
    if (current.publisher.options.reedit) {
      ret.push({
        id: current.id,
        order: current.order,
        invoice_type: current.invoice_type,
        duration: current.publisher.duration,
        number: current.number,
        price: current.publisher.rate_reedit,
        activity: "reedit",
      });
    }
    if (current.publisher.options.retime) {
      ret.push({
        id: current.id,
        order: current.order,
        invoice_type: current.invoice_type,
        duration: current.publisher.duration,
        number: current.number,
        price: current.publisher.rate_retime,
        activity: "retime",
      });
    }

    return acc.concat(ret);
  }, []);
};

const calcEpisodesMinutesAmount = (episodes: EpisodesAcitivitiesType[]) => {
  const minutes = toFixed(
    episodes.map((e) => toFixed(Math.max(1, e.duration / 60), 1)).reduce((acc, current) => acc + current, 0) /
      episodes.length,
    1
  );

  return {
    episodes: episodes.map((e) => e.number),
    episodes_range: getEpisodeRange(orderBy(episodes, "order").map((e) => [e.order, e.number])),
    minutes,
    activity: episodes[0].activity,
    price: episodes[0].price,
    amount: moneyValue(minutes * episodes.length * episodes[0].price),
  };
};

const groupEpisodesActivities = (episodesActivities: EpisodesAcitivitiesType[]) => {
  const group = groupBy(episodesActivities, "activity");

  const episodesGroupsActivities = Object.entries(group).map(([id, episodes]) => ({
    id,
    invoice_type: episodes[0].invoice_type,
    ...calcEpisodesMinutesAmount(episodes),
  }));

  const episodesGroups = groupBy(episodesGroupsActivities, (e) => `e-${e.episodes.join("|")}`);

  return Object.entries(episodesGroups).map(([id, activities]) => ({
    id,
    invoice_type: activities[0].invoice_type,
    episodes: activities[0].episodes,
    episodes_range: activities[0].episodes_range,
    minutes: activities[0].minutes,
    total: activities.reduce((acc, current) => moneyValue(acc + current.amount), 0),
    activities,
  }));
};

const transformNextInvoiceData = (invoiceData: InvoiceCreateDataPayload[]) => {
  const nextInvoiceData = invoiceData.map((serie) => {
    const episodesActivities = serie.episodes.map((e) => getEpisodesActivities(e));
    const episodesGroupActivities = episodesActivities.map((e) => groupEpisodesActivities(e));
    const total = flatten(episodesGroupActivities).reduce((acc, current) => moneyValue(acc + current.total), 0);
    const [episodesActivitiesNormal, episodesActivitiesDouble, episodesActivitiesPVs] = episodesGroupActivities;

    return {
      ...serie,
      episodes: [episodesActivitiesNormal, episodesActivitiesDouble, episodesActivitiesPVs],
      total,
    };
  });

  const nextInvoiceDataTotal = nextInvoiceData.reduce((acc, current) => moneyValue(acc + current.total), 0);

  return { nextInvoiceData, nextInvoiceDataTotal };
};

type TransformedInvoiceNextSeries = ReturnType<typeof transformNextInvoiceData>["nextInvoiceData"];

const InvoiceNextSeries = ({
  series,
  episodes,
}: {
  series: TransformedInvoiceNextSeries[number];
  episodes: TransformedInvoiceNextSeries[number]["episodes"][number];
}) => {
  if (episodes.length == 0) {
    return null;
  }

  let title_extra = "";
  if (episodes[0].invoice_type == "pv") {
    title_extra = " - PV";
  } else if (episodes[0].invoice_type == "double_or_movie") {
    title_extra = " - Double/Movie";
  }

  const title = `${series.title}${title_extra}`;

  return (
    <>
      {episodes.map((e) => (
        <React.Fragment key={e.id}>
          {e.activities.map((a, idx) => (
            <Tr key={a.id}>
              {idx == 0 && <Td rowSpan={e.activities.length}>{title}</Td>}
              <Td>{e.episodes.length}</Td>
              <Td>{e.episodes_range}</Td>
              <Td>{e.minutes}</Td>
              <Td>{activityToString(a.activity)}</Td>
              <Td>{moneyFormat(a.price)}</Td>
              <Td>{moneyFormat(a.amount)}</Td>
            </Tr>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

const PublisherSelect = () => {
  const translations = useTranslations();
  const methods = useFormContext();

  const publisher_id = useWatch({ name: "publisher_id", control: methods.control });

  const { data: publishersData, loading: publishersLoading } = usePublishersFindAll();
  const PUBLISHERS_SELECT: SelectOptions =
    publishersData?.map((p) => ({ label: p.name, value: p.id.toString() })) ?? [];

  useEffect(() => {
    if (publishersData && publishersData.length == 1) {
      methods.setValue("publisher_id", publishersData[0].id.toString());
      // console.log(publishersData[0].id);
    }
  }, [publisher_id, publishersData]);

  if (publishersLoading) {
    return <Spinner />;
  }

  return (
    <InputSelectForm
      label={translations["invoices.next.publisher"]}
      name="publisher_id"
      options={PUBLISHERS_SELECT}
      formControlProps={{ mb: 10 }}
    />
  );
};

const InvoiceNextDetails = ({ invoice, title }: { title: string; invoice: InvoiceCreateDataPayload[] }) => {
  const translations = useTranslations();
  const { nextInvoiceData, nextInvoiceDataTotal } = transformNextInvoiceData(invoice);

  return (
    <>
      <Heading size="lg">{title}</Heading>
      <Table marginTop="16px !important">
        <Thead>
          <Tr>
            <Th>{translations["invoices.next.table.series"]}</Th>
            <Th>{translations["invoices.next.table.number-episodes"]}</Th>
            <Th>{translations["invoices.next.table.episodes"]}</Th>
            <Th>{translations["invoices.next.table.minutes-per-episode"]}</Th>
            <Th>{translations["invoices.next.table.activity"]}</Th>
            <Th>{translations["invoices.next.table.price"]}</Th>
            <Th w="15%">{translations["invoices.next.table.amount"]}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <>
            {nextInvoiceData.map((s) => (
              <React.Fragment key={s.id}>
                {s.episodes.map((e, idx) => (
                  <InvoiceNextSeries key={idx} series={s} episodes={e} />
                ))}
              </React.Fragment>
            ))}
          </>
          <Tr>
            <Td colSpan={6}>
              <strong>{translations["invoices.next.table.total"]}</strong>
            </Td>
            <Td>
              <strong>{moneyFormat(nextInvoiceDataTotal)}</strong>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export const InvoiceNext = () => {
  const translations = useTranslations();
  const methods = useForm({
    defaultValues: {
      publisher_id: undefined as undefined | string,
    },
  });

  const publisher_id = useWatch({ name: "publisher_id", control: methods.control, defaultValue: "" });

  const { data: invoiceData, loading: loadingInvoice } = useInvoiceNext(publisher_id || "");
  const loading = loadingInvoice;

  const hasInvoice = !loading && (invoiceData?.next?.length > 0 || invoiceData?.future?.length > 0);

  return (
    <MainLayout>
      <Container maxW="container.xl">
        <PageHeader title={translations["invoices.next.title"]} />
        <FormProvider {...methods}>
          <WithRoles roles={ADMIN_ROLES}>
            <PublisherSelect />
          </WithRoles>
          <WithLoading loading={loading}>
            {!hasInvoice && <Text>{translations["invoices.next.empty"]}</Text>}
            {invoiceData?.next?.length > 0 && (
              <>
                <InvoiceNextDetails
                  invoice={invoiceData.next}
                  title={moment().subtract(1, "month").format("MMMM YYYY")}
                />
                {invoiceData?.future.length > 0 && <Divider mt="10" mb="10" />}
              </>
            )}
            {invoiceData?.future?.length > 0 && (
              <InvoiceNextDetails invoice={invoiceData.future} title={moment().format("MMMM YYYY")} />
            )}
          </WithLoading>
        </FormProvider>
      </Container>
    </MainLayout>
  );
};
