import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, WithLoading } from "@components";
import { usePublishersFindAll } from "@hooks/api/publishers.hook";
import { MainLayout } from "@layouts";
import { RolesService } from "@services/roles.service";
import { useRef } from "react";
import { useHistory } from "react-router";

const AlertMessageComponent = (props: { publisher: string }) => (
  <Text>
    Are you sure you want to delete the publisher{" "}
    <Text as="span" fontWeight="bold">
      {props.publisher}
    </Text>
    ?
  </Text>
);

const NoneMessage = () => (
  <Tr>
    <Td colSpan={11}>No records found.</Td>
  </Tr>
);

export const PublishersList = () => {
  const toast = useToast();
  const { data, loading, refetch } = usePublishersFindAll();
  const history = useHistory();
  const ref = useRef<ConfirmModalRef>(null);

  const handleClickEditPublisher = (publisherId: number) => history.push(`/publishers/${publisherId}`);
  const handleClickDeleteRole = async (publisherId: string) => {
    try {
      await RolesService.deleteById(publisherId);

      toast({
        status: "success",
        description: "Publisher removed successfully",
      });

      refetch();
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  return (
    <MainLayout>
      <Container maxW="container.lg">
        <ConfirmModal ref={ref} onConfirm={handleClickDeleteRole} />

        <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end">
          <Button colorScheme="teal" size="sm" type="submit" onClick={() => history.push("/publishers/add")}>
            Add New Publisher
          </Button>
        </ButtonGroup>
        <WithLoading loading={loading}>
          <Table variant="striped" marginTop="16px !important">
            <Thead>
              <Tr>
                <Th w="10%"># ID</Th>
                <Th>Name</Th>
                <Th>Admins</Th>
                <Th w="10%">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!data?.length && <NoneMessage />}
              {data?.map((publisher) => {
                return (
                  <Tr key={publisher.id}>
                    <Td textAlign="center">{publisher.id}</Td>
                    <Td>{publisher.name}</Td>
                    <Td>{publisher.admins?.map((u) => u.name).join(", ") || "None"}</Td>
                    <Td>
                      <ButtonGroup>
                        <Tooltip label="Edit publisher" aria-label="Edit publisher">
                          <IconButton
                            size="sm"
                            aria-label="Edit"
                            icon={<EditIcon />}
                            onClick={() => handleClickEditPublisher(publisher.id)}
                            borderRadius="4"
                          />
                        </Tooltip>
                        <Tooltip label="Delete publisher" aria-label="Delete publisher">
                          <IconButton
                            borderRadius="4"
                            size="sm"
                            colorScheme="red"
                            aria-label="Delete"
                            icon={<DeleteIcon />}
                            onClick={() =>
                              ref.current?.open(publisher.id, {
                                title: "Delete Publisher?",
                                message: <AlertMessageComponent publisher={`${publisher.id} - ${publisher.name}`} />,
                              })
                            }
                          />
                        </Tooltip>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
