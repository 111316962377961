import { Button, ButtonGroup, Divider, Text, useToast, VStack } from "@chakra-ui/react";
import { Container, Form, InputForm, WithLoading } from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetLanguageById } from "@hooks/api/translations.hook";
import { MainLayout } from "@layouts";
import { TranslationsService } from "@services/translations.service";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

const FORM_LABELS = {
  flag: "Flag",
  label: "Label",
  active: "Active",
};

const DEFAULT_VALUES = {
  flag: "",
  label: "",
  active: true,
};

const SCHEMA = Yup.object({
  label: Yup.string().required().label(FORM_LABELS.label),
  flag: Yup.string().required().label(FORM_LABELS.flag),
  active: Yup.bool().required().label(FORM_LABELS.active),
});

export const LanguagesForm = () => {
  const toast = useToast();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data: language, loading } = useGetLanguageById(params.id ? +params.id : null);
  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  const handleSubmit = async (values: typeof DEFAULT_VALUES) => {
    // ON UPDATE
    if (params.id) {
      await TranslationsService.updateLanguage(values, +params.id);

      toast({
        isClosable: true,
        title: "Language updated successfully",
        status: "success",
      });

      return history.push("/translations");
    } else {
      // ON CREATE
      const newLanguage = await TranslationsService.createLanguage(values);

      toast({
        isClosable: true,
        title: "Language created successfully",
        status: "success",
      });

      return history.push(`/translations/${newLanguage.id}/keys`);
    }
  };

  useEffect(() => {
    if (language) {
      methods.reset({
        active: language.active,
        flag: language.flag,
        label: language.label,
      });
    }
  }, [language]);

  return (
    <MainLayout>
      <Container>
        <WithLoading loading={loading}>
          <Form methods={methods} onSubmit={handleSubmit}>
            <VStack spacing="4">
              <Text fontSize="28px" textAlign="left" width="100%">
                {params.id ? "Edit" : "Add"} Language
              </Text>
              <Divider />

              <InputForm label={FORM_LABELS.label} name="label" />
              <InputForm label={FORM_LABELS.flag} name="flag" helperMessage="Used to find a series' title" />
              <InputSwitchForm label={FORM_LABELS.active} name="active" />

              <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="space-between">
                <Button colorScheme="red" size="md" onClick={() => history.push("/translations")}>
                  Cancel
                </Button>
                <Button
                  colorScheme="teal"
                  size="md"
                  type="submit"
                  isLoading={methods.formState.isSubmitting}
                  isDisabled={!methods.formState.isDirty}
                >
                  Save
                </Button>
              </ButtonGroup>
            </VStack>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
