import moment from "moment-timezone";
import { store } from "../store";

type StrOrDate = string | Date;

export const DateFormat = {
  toCustomDate(dateStr?: StrOrDate, format = "DD-MM-YYYY") {
    const timezone = store.getState().user.timezone || "UTC";
    return moment(dateStr).utc().tz(timezone).format(format);
  },
  toDateString(dateStr?: StrOrDate) {
    return moment(dateStr).utc().format("DD-MM-YYYY");
  },
  toDateTimeString(dateStr?: StrOrDate) {
    const timezone = store.getState().user.timezone || "UTC";
    return moment(dateStr).utc().tz(timezone).format("DD-MM-YYYY HH:mm");
  },
  toDateForm(date?: StrOrDate) {
    return moment(date).utc().format("YYYY-MM-DDTHH:mm");
  },
};
