export const CONSTANTS = {
  PROJECT_NAME: "Nami",
  BY: "Waku Studios",
  TITLE_PAGE: "Nami by Waku Studios",
};

const REGEX_PASSWORD_VALIDATION = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/;
const PASSWORD_INVALID_MESSAGE =
  "${path} must be at least 12 characters long and contain a minimum of one uppercase character, one number and one special character";

export const VALIDATORS = {
  PASSWORD: {
    rule: REGEX_PASSWORD_VALIDATION,
    message: PASSWORD_INVALID_MESSAGE,
  },
};

export const SERIE_TYPE_LABEL: Record<string, string> = {
  simulcast: "Simulcast",
  catalogue: "Catalogue",
};

export const getSerieLabelByType = (type?: string) => {
  return type ? SERIE_TYPE_LABEL[type] : "";
};
