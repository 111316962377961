import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useState } from "react";

type ConfirmModalProps = {
  onConfirm: (params: any) => Promise<void> | void;
};

export type ConfirmModalRef = {
  open: (params: any, options: { title: string; message: string | JSX.Element }) => void;
};

export const ConfirmModal = forwardRef<ConfirmModalRef, ConfirmModalProps>(({ onConfirm }, ref) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [message, setMessage] = useState<string | JSX.Element>("");
  const [title, setTitle] = useState("");
  const [params, setParams] = useState<any>(undefined);

  const handleClickConfirm = async () => {
    setLoading(true);

    await onConfirm(params);

    setLoading(false);
    onClose();
  };

  useImperativeHandle(
    ref,
    () => ({
      open: (params, options) => {
        setTitle(options.title);
        setMessage(options.message);
        setParams(params);

        onOpen();
      },
    }),
    []
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{message}</ModalBody>

        <ModalFooter justifyContent="space-between" display="flex" flex={1}>
          <Button colorScheme="red" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outline" onClick={handleClickConfirm} isLoading={loading}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
