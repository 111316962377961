import { Center, Spinner, SpinnerProps } from "@chakra-ui/react";
import { FC } from "react";

type WithLoadingProps = {
  loading?: boolean;
  spinnerProps?: SpinnerProps;
  centerH?: boolean;
};

export const WithLoading: FC<WithLoadingProps> = ({ loading = false, spinnerProps, children, centerH = true }) => {
  return (
    <>
      {!loading && children}
      {loading && (
        <Center mt={centerH ? "40%" : ""}>
          <Spinner size="xl" {...spinnerProps} />
        </Center>
      )}
    </>
  );
};
