import { Flex, FlexProps, Stack, StackProps } from "@chakra-ui/react";
import { FC } from "react";

type ContainerProps = {
  flexProps?: FlexProps;
  stackProps?: StackProps;
};

export const Container: FC<ContainerProps> = ({ children, flexProps, stackProps }) => (
  <Flex minH="full" align="center" justify="center" {...flexProps}>
    <Stack spacing={8} maxW="lg" w="lg" mx="auto" py={2} px={6} {...stackProps}>
      {children}
    </Stack>
  </Flex>
);
