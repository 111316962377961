import { useFormContext } from "react-hook-form";
import { Input, InputProps } from "../input";
import { makeInputFormProps } from "./utils";

export const InputForm = (props: InputProps) => {
  const { register, formState } = useFormContext();
  const { registerOptions, ...rest } = props;
  const withFormHookProps = makeInputFormProps(register(props.name, registerOptions), formState);

  return <Input {...rest} {...withFormHookProps} />;
};
