export function getEpisodeRange(episodes: [number, string][]) {
  if (episodes.length == 0) return "";
  if (episodes.length == 1) return episodes[0][1];

  const groups = episodes.reduce(
    (acc, current) => {
      if (!acc.start) {
        return { last: current[0], start: current[1], end: current[1], groups: acc.groups };
      }

      if (acc.last + 1 != current[0]) {
        const newGroup = acc.start != acc.end ? `${acc.start}-${acc.end}` : acc.start;
        return { last: current[0], start: current[1], end: current[1], groups: [...acc.groups, newGroup] };
      } else {
        return { last: current[0], start: acc.start, end: current[1], groups: acc.groups };
      }
    },
    { last: 0, start: "", end: "", groups: [] as string[] }
  );

  groups.groups.push(groups.start != groups.end ? `${groups.start}-${groups.end}` : groups.start);

  return groups.groups.join(", ");
}
