import { Badge, Box, Flex, Text } from "@chakra-ui/layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { Tooltip } from "@chakra-ui/tooltip";
import { WithLoading } from "@components";
import { useDashboardReleases } from "@hooks/api/dashboard.hook";
import { useCurrentLanguage, useTranslations } from "@hooks/useTranslations";
import { CardHeader } from "@pages";
import { makeTitleBySerie } from "@pages/series/series.utils";
import { Serie } from "@services";
import { DashboardReleasesEpisode } from "@services/dashboard.service";
import { contrastColor } from "@utils/contrast-color";
import { slugify } from "@utils/slugify";
import { first, sortBy } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

const getCalendarCellColor = (episodes?: DashboardReleasesEpisode[]) => {
  if (!episodes || episodes.length == 0) {
    return undefined;
  }

  const episodesColor = episodes.map((e) => {
    const oneOfMaterials = (e.video_available && !e.script_available) || (!e.video_available && e.script_available);
    const bothMaterials = e.video_available && e.script_available;
    const completed = e.submitted_date;

    if (completed) {
      return { order: 3, color: "#73dc76" };
    }

    if (bothMaterials) {
      return { order: 2, color: "#f7d392" };
    }

    if (oneOfMaterials) {
      return { order: 1, color: "#d93310" };
    }

    return { order: 0, color: undefined };
  });

  const episodeColor = first(sortBy(episodesColor, "order"));
  return episodeColor?.color;
};

const CalendarEpisode = ({ episode }: { episode: DashboardReleasesEpisode }) => {
  const translations = useTranslations();

  return (
    <Tooltip
      hasArrow
      label={
        <>
          <Flex direction="column" m="2px">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text fontWeight={"bold"} mr="10px" width="50px">
                {translations["dashboard.calendar-overview.script.label"]}
              </Text>
              <Badge p="1" mr="3px" color="black" background={episode.script_available ? "blue.100" : "red.500"}>
                {episode.script_available
                  ? translations["dashboard.calendar-overview.available"]
                  : translations["dashboard.calendar-overview.missing"]}
              </Badge>
            </Flex>

            <Flex alignItems="center" mt="1" justifyContent="flex-start">
              <Text fontWeight={"bold"} mr="10px" width="50px">
                {translations["dashboard.calendar-overview.video.label"]}
              </Text>
              <Badge p="1" mr="3px" color="black" background={episode.video_available ? "orange.200" : "red.500"}>
                {episode.video_available
                  ? translations["dashboard.calendar-overview.available"]
                  : translations["dashboard.calendar-overview.missing"]}
              </Badge>
            </Flex>

            <Flex alignItems="center" mt="1" justifyContent="flex-start">
              <Text fontWeight={"bold"} mr="10px" width="50px">
                {translations["dashboard.calendar-overview.status.label"]}
              </Text>

              {episode.last_status && !episode.submitted_date && (
                <Badge
                  p="1"
                  mr="3px"
                  backgroundColor={episode.last_status.color}
                  color={contrastColor(episode.last_status.color)}
                >
                  {episode.last_status.description}
                </Badge>
              )}

              {episode.submitted_date && (
                <Badge p="1" mr="3px" background="#4b5362">
                  {translations["dashboard.calendar-overview.status-submitted"]}
                </Badge>
              )}

              {!episode.last_status && !episode.submitted_date && (
                <Badge p="1" mr="3px" backgroundColor="green.300" color="black">
                  {translations["dashboard.calendar-overview.status-being"]}
                </Badge>
              )}
            </Flex>
          </Flex>
        </>
      }
    >
      <Text fontWeight="bold">{episode.number}</Text>
    </Tooltip>
  );
};

export const PublisherCalendarView = () => {
  const language = useCurrentLanguage();
  const translations = useTranslations();
  const { data: publisherReleases, loading: publisherReleasesLoading } = useDashboardReleases();

  const startDay = moment().subtract(7, "days");
  const now = moment().format("YYYY-MM-DD");

  const days: moment.Moment[] = [];
  for (let i = 0; i < 28; i++) {
    days.push(startDay.clone().add(i, "days"));
  }

  return (
    <Box as="section" pt="12" px={{ md: "8" }}>
      <Box rounded={{ md: "lg" }} shadow="dark-lg" pb="25px" x="auto">
        <CardHeader
          title={translations["dashboard.calendar-overview"]}
          action={
            <Flex fontSize="12px">
              <Box ml="10px">
                <Badge p="1" mr="5px" backgroundColor="#d93310"></Badge>{" "}
                {translations["dashboard.calendar-overview.badge.red"]}
              </Box>
              <Box ml="10px">
                <Badge p="1" mr="5px" backgroundColor="#f7d392"></Badge>{" "}
                {translations["dashboard.calendar-overview.badge.yellow"]}
              </Box>
              <Box ml="10px">
                <Badge p="1" mr="5px" backgroundColor="#73dc76"></Badge>{" "}
                {translations["dashboard.calendar-overview.badge.green"]}
              </Box>
            </Flex>
          }
        />
        <Flex
          overflowX={"scroll"}
          x="auto"
          display="block"
          sx={{
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
          }}
        >
          <WithLoading centerH={false} loading={publisherReleasesLoading}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th rowSpan={2} minWidth="200px" maxWidth="200px" width="200px">
                    {translations["dashboard.calendar-overview.series"]}
                  </Th>
                  {days.map((d) => (
                    <Th
                      key={d.format("YYYY-MM-DD")}
                      background={d.format("YYYY-MM-DD") == now ? "gray.600" : undefined}
                      textAlign="center"
                    >
                      {d.format("DD[.]MM")}
                    </Th>
                  ))}
                </Tr>
                <Tr>
                  {days.map((d) => (
                    <Th
                      key={d.format("YYYY-MM-DD")}
                      background={d.format("YYYY-MM-DD") == now ? "gray.600" : undefined}
                      textAlign="center"
                    >
                      {d.format("ddd")}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {publisherReleases?.calendar.map((s) => (
                  <Tr key={s.title}>
                    <Td>
                      <Link to={`/series/${s.id}/${slugify(s.short_title || "")}/episodes`}>
                        <Text color="blue.400">{makeTitleBySerie(s as Serie, language)}</Text>
                      </Link>
                    </Td>
                    {days.map((d) => {
                      const cellColorEpisode = getCalendarCellColor(s.episodes[d.format("YYYY-MM-DD")]);
                      const fontColor = cellColorEpisode ? contrastColor(cellColorEpisode) : undefined;
                      // const cellColorDay = d.format("YYYY-MM-DD") == now ? "gray.600" : undefined;

                      return (
                        <Td
                          key={d.format("YYYY-MM-DD")}
                          color={fontColor}
                          background={cellColorEpisode}
                          textAlign="center"
                        >
                          {s.episodes[d.format("YYYY-MM-DD")] && (
                            <>
                              {s.episodes[d.format("YYYY-MM-DD")]
                                .map<React.ReactNode>((e) => <CalendarEpisode key={e.id} episode={e} />)
                                .reduce((prev, curr) => [prev, ",", curr])}
                            </>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </WithLoading>
        </Flex>
      </Box>
    </Box>
  );
};
