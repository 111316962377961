export const rgbToHex = (r: number, g: number, b: number) =>
  "#" +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

export const hexToRgb = (hex: string) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));

export function contrastColor(colorHex: string) {
  const rgb = hexToRgb(colorHex);
  if (!rgb) return "";

  const [R, G, B] = rgb;
  // Calculate the perceptive luminance (aka luma) - human eye favors green color...
  const luma = (0.299 * R + 0.587 * G + 0.114 * B) / 255;

  // Return black for bright colors, white for dark colors
  return luma > 0.5 ? "#000" : "#fff";
}
