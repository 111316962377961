import { Box, Flex } from "@chakra-ui/layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { WithLoading } from "@components";
import { useDashboardNextSimulcastCatalogue } from "@hooks/api/dashboard.hook";
import { useCurrentLanguage } from "@hooks/useTranslations";
import { CardHeader } from "@pages";
import { makeTitleBySerie } from "@pages/series/series.utils";
import { DateFormat } from "@utils";

export const NextCatalogues = () => {
  const language = useCurrentLanguage();
  const { data, loading } = useDashboardNextSimulcastCatalogue();

  return (
    <Box as="section" pt="12" px={{ md: "8" }}>
      <Box rounded={{ md: "lg" }} shadow="dark-lg" pb="25px" x="auto" maxW="4xl" mx="auto">
        <CardHeader title="Next Catalogues" />
        <Flex
          overflowX={"auto"}
          x="auto"
          display="block"
          sx={{
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
          }}
        >
          <WithLoading centerH={false} loading={loading}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th minWidth="200px" maxWidth="200px" width="200px">
                    Series
                  </Th>
                  <Th>Episode</Th>
                  <Th>Target Submission</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.catalogue?.map((s) => (
                  <Tr key={s.id}>
                    <Td>{makeTitleBySerie(s.serie as any, language)}</Td>
                    <Td>{s.number}</Td>
                    <Td>{DateFormat.toDateTimeString(s.target_submission_date)}</Td>
                  </Tr>
                ))}
                {data && data.catalogue?.length == 0 && (
                  <Tr>
                    <Td colSpan={3}>Nothing</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </WithLoading>
        </Flex>
      </Box>
    </Box>
  );
};
