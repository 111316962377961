import { useAppSelector } from "@store";
import { LoginFormSteps } from "@store/modules/login";
import { Login } from "./login";
import { RegisterTwoAuthCode } from "./register-two-auth-code";
import { ScanQRCode } from "./scan-qrcode";

export const Auth = () => {
  const step = useAppSelector((state) => state.login.currentStep);

  if (step === LoginFormSteps.LOGIN) {
    return <Login />;
  }

  if (step === LoginFormSteps.SCAN_QRCODE) {
    return <ScanQRCode />;
  }

  return <RegisterTwoAuthCode />;
};
