import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const InvoiceService = {
  history: async () => {
    const promise = api.get<InvoiceHistoryPayload[]>("invoice");
    return handleRequestPromise(promise);
  },
  delete: async (id: number) => {
    const promise = api.delete<boolean>(`invoice/${id}`);
    return handleRequestPromise(promise);
  },
  create: async (invoice: InvoiceCreatePayload) => {
    const promise = api.post<boolean>("invoice/create", invoice);
    return handleRequestPromise(promise);
  },
  loadCreate: async (publisher_id: number) => {
    const promise = api.get<InvoiceCreateDataPayload[]>(`invoice/create/${publisher_id}`);
    return handleRequestPromise(promise);
  },
  nextInvoiceNumber: async (publisher_id: number) => {
    const promise = api.get<{ number: number }>(`invoice/next_number/${publisher_id}`);
    return handleRequestPromise(promise);
  },
  next: async (publisher_id: string) => {
    const promise = api.get<{ next: InvoiceCreateDataPayload[]; future: InvoiceCreateDataPayload[] }>(
      `invoice/next/${publisher_id}`
    );
    return handleRequestPromise(promise);
  },
  view: async (id: number) => {
    const promise = api.get<InvoiceViewPayload>(`invoice/${id}`);
    return handleRequestPromise(promise);
  },
  viewPDF: async (id: number) => {
    const promise = api.get<Blob>(`invoice/pdf/${id}`, { responseType: "blob" });
    return handleRequestPromise(promise);
  },
};

export type InvoiceHistoryPayload = {
  id: number;
  number: number;
  name: string;
  period_start: string;
  period_end: string;
  publisher: {
    name: string;
  };
  num_episodes: number;
  num_series: number;
  series: {
    id: number;
    title: string;
    num_episodes: number;
    amount: number;
  }[];
  total: number;
};

export type InvoiceCreatePayload = {
  publisher_id: number;
  invoice_number: number;
  name: string;
  period_start: string;
  period_end: string;
  episodes: {
    id: number;
    duration: number;
    rate: number;
    rate_major_tlc: number;
    rate_minor_tlc: number;
    rate_reedit: number;
    rate_retime: number;
  }[];
};

export type InvoiceCreateDataEpisodesPayload = {
  id: number;
  number: string;
  order: number;
  invoice_type: string;
  publisher: {
    duration: number;
    rate: number;
    rate_major_tlc: number;
    rate_minor_tlc: number;
    rate_reedit: number;
    rate_retime: number;
    approved: boolean;
    options: {
      full_tl: boolean;
      major_tlc: boolean;
      minor_tlc: boolean;
      reedit: boolean;
      retime: boolean;
    };
  };
};

export type InvoiceCreateDataPayload = {
  id: number;
  title: string;
  episodes: [
    InvoiceCreateDataEpisodesPayload[],
    InvoiceCreateDataEpisodesPayload[],
    InvoiceCreateDataEpisodesPayload[]
  ];
};

export type InvoiceViewPayload = {
  name: string;
  number: number;
  period_start: string;
  period_end: string;
  publisher: {
    name: string;
  };
  episodes: {
    id: string;
    series_title: string;
    episodes_range: string;
    episodes: string[];
    minutes: number;
    activity: string;
    price: number;
    amount: number;
  }[];
  total: number;
};
