import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { InputForm, InputSelectForm, SelectOptions } from "@components";
import { moneyFormat } from "@utils/money-format";
import { Control, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { PayrollFormFields } from "./new";

export const SELECT_CATEGORIES: SelectOptions = [
  { label: "Localization", value: "Localization" },
  { label: "Development", value: "Development" },
  { label: "Event", value: "Event" },
  { label: "Others", value: "Others" },
  { label: "Marketing", value: "Marketing" },
  { label: "Professional Services", value: "Professional Services" },
];

export const ExpensesList = ({ SELECT_USERS }: { SELECT_USERS: SelectOptions }) => {
  const { register, control } = useFormContext<PayrollFormFields>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "payroll_expenses",
    keyName: "field_id",
  });

  return (
    <>
      <Heading size="md" mt="30px">
        Expenses
      </Heading>
      <Table variant="striped" marginTop="16px !important">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Category</Th>
            <Th>Description</Th>
            <Th w="20%">Amount</Th>
            <Th w="10%">Approved</Th>
            <Th w="3%">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((expense, i) => (
            <Tr key={expense.field_id}>
              <Td>
                <InputSelectForm
                  label=""
                  options={SELECT_USERS}
                  name={`payroll_expenses.${i}.user_id`}
                  selectProps={{ defaultValue: expense.user_id }}
                />
              </Td>
              <Td>
                <InputSelectForm
                  label=""
                  options={SELECT_CATEGORIES}
                  name={`payroll_expenses.${i}.category`}
                  selectProps={{ defaultValue: expense.category }}
                />
              </Td>
              <Td>
                <InputForm
                  label=""
                  name={`payroll_expenses.${i}.description`}
                  inputProps={{ defaultValue: expense.description }}
                />
              </Td>
              <Td>
                <InputForm
                  label=""
                  name={`payroll_expenses.${i}.amount`}
                  registerOptions={{ valueAsNumber: true }}
                  inputProps={{ defaultValue: expense.amount, step: 0.01 }}
                  type="number"
                />
              </Td>
              <Td textAlign="center">
                <Switch
                  size="md"
                  {...register(`payroll_expenses.${i}.approved` as const)}
                  defaultChecked={expense.approved}
                />
              </Td>
              <Td textAlign="center">
                <ButtonGroup>
                  <Tooltip label="Delete" aria-label="Delete Expense">
                    <IconButton
                      size="sm"
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => remove(i)}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td colSpan={3}>Total</Td>
            <Td colSpan={3}>
              <ExpensesListTotal control={control} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end" mt="15px">
        <Button
          colorScheme="green"
          size="md"
          onClick={() =>
            append({
              amount: 0,
              approved: true,
              category: "",
              description: "",
              expense_id: 0,
              user_id: 0,
            })
          }
        >
          Add Expense
        </Button>
      </ButtonGroup>
    </>
  );
};

const ExpensesListTotal = ({ control }: { control: Control<PayrollFormFields> }) => {
  const formValues = useWatch({
    name: `payroll_expenses`,
    control,
  });

  const total = formValues?.reduce((acc, current) => acc + (current.approved ? current.amount : 0 || 0), 0) || 0;
  return <> {moneyFormat(total)}</>;
};
