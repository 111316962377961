import { Image, ImageProps } from "@chakra-ui/react";

export const SerieArtwork = (props: ImageProps) => {
  return (
    <Image
      w="110px"
      height="165px"
      minHeight="165px"
      minWidth="110px"
      borderRadius="4"
      display={{ base: "none", md: "block" }}
      fallbackSrc="/images/defaults/artwork.jpg"
      {...props}
    />
  );
};
