import { FormState, UseFormRegisterReturn } from "react-hook-form";

export const makeInputFormProps = (
  { name, ...rest }: UseFormRegisterReturn,
  formState: FormState<any>
): UseFormRegisterReturn & { isInvalid: boolean; errorMessage: string } => {
  return {
    name,
    ...rest,
    isInvalid: !!formState.errors?.[name]?.message,
    errorMessage: formState.errors?.[name]?.message,
  };
};
