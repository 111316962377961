import { Box, Flex, Text } from "@chakra-ui/layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { WithLoading } from "@components";
import { useDashboardReleases } from "@hooks/api/dashboard.hook";
import { useCurrentLanguage, useTranslations } from "@hooks/useTranslations";
import { CardHeader, CountdownTimer } from "@pages";
import { makeTitleBySerie } from "@pages/series/series.utils";
import { Serie } from "@services";
import { DateFormat } from "@utils";
import { contrastColor } from "@utils/contrast-color";
import { slugify } from "@utils/slugify";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

export const PublisherSeriesEpisodesView = () => {
  const language = useCurrentLanguage();
  const translations = useTranslations();
  const { data: publisherReleases, loading: publisherReleasesLoading } = useDashboardReleases();

  console.log(language);

  const orders: number[] = [];
  for (let i = 0; i < publisherReleases?.maxEpisodes; ++i) {
    orders.push(i + 1);
  }

  return (
    <Box as="section" pt="12" px={{ md: "8" }}>
      <Box rounded={{ md: "lg" }} shadow="dark-lg" pb="25px" x="auto">
        <CardHeader title={translations["dashboard.series-episodes-overview"]} />
        <Flex
          overflowX={"auto"}
          x="auto"
          display="block"
          sx={{
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
          }}
        >
          <WithLoading centerH={false} loading={publisherReleasesLoading}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th minWidth="200px" maxWidth="200px" width="200px">
                    {translations["dashboard.series-episodes-overview.series"]}
                  </Th>
                  <Th colSpan={publisherReleases?.maxEpisodes}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {publisherReleases?.series.map((s) => (
                  <React.Fragment key={s.title}>
                    <Tr borderTopWidth="5px">
                      <Td rowSpan={5}>
                        <Link to={`/series/${s.id}/${slugify(s.short_title || "")}/episodes`}>
                          <Text color="blue.400">{makeTitleBySerie(s as Serie, language)}</Text>
                        </Link>
                      </Td>
                      {s.episodes.map((e) => (
                        <Td key={e.id} textAlign="center" fontSize={"12px"}>
                          {e.number}
                        </Td>
                      ))}
                      {publisherReleases?.maxEpisodes - s.episodes.length > 0 && (
                        <Td colSpan={publisherReleases?.maxEpisodes - s.episodes.length}>&nbsp;</Td>
                      )}
                    </Tr>
                    <Tr>
                      {s.episodes.map((e) => (
                        <Td
                          key={e.id}
                          textAlign="center"
                          fontSize={"12px"}
                          fontWeight="bold"
                          color="black"
                          background={e.script_available ? "blue.100" : undefined}
                        >
                          {e.script_available && (
                            <Text>{translations["dashboard.series-episodes-overview.script"]}</Text>
                          )}
                          {!e.script_available && <Text>&nbsp;</Text>}
                        </Td>
                      ))}
                      {publisherReleases?.maxEpisodes - s.episodes.length > 0 && (
                        <Td colSpan={publisherReleases?.maxEpisodes - s.episodes.length}>&nbsp;</Td>
                      )}
                    </Tr>
                    <Tr>
                      {s.episodes.map((e) => (
                        <Td
                          key={e.id}
                          textAlign="center"
                          color="black"
                          fontSize={"12px"}
                          fontWeight="bold"
                          background={e.video_available ? "orange.200" : undefined}
                        >
                          {e.video_available && <Text>{translations["dashboard.series-episodes-overview.video"]}</Text>}
                          {!e.video_available && <Text>&nbsp;</Text>}
                        </Td>
                      ))}
                      {publisherReleases?.maxEpisodes - s.episodes.length > 0 && (
                        <Td colSpan={publisherReleases?.maxEpisodes - s.episodes.length}>&nbsp;</Td>
                      )}
                    </Tr>
                    <Tr>
                      {s.episodes
                        .filter((e) => e.submitted_date || e.last_status)
                        .map((e) => (
                          <>
                            {e.submitted_date && (
                              <Td
                                key={e.id}
                                textAlign="center"
                                fontSize={"12px"}
                                fontWeight="bold"
                                background={e.submitted_date ? "#4b5362" : undefined}
                              >
                                {translations["dashboard.series-episodes-overview.submitted"]}
                              </Td>
                            )}
                            {e.last_status && !e.submitted_date && (
                              <Td
                                key={e.id}
                                textAlign="center"
                                backgroundColor={e.last_status.color}
                                color={contrastColor(e.last_status.color)}
                                fontSize={"12px"}
                                fontWeight="bold"
                              >
                                {e.last_status.description}
                              </Td>
                            )}
                          </>
                        ))}
                      {publisherReleases?.maxEpisodes -
                        s.episodes.filter((e) => e.submitted_date || e.last_status).length >
                        0 && (
                        <Td
                          colSpan={
                            publisherReleases?.maxEpisodes -
                            s.episodes.filter((e) => e.submitted_date || e.last_status).length
                          }
                        >
                          &nbsp;
                        </Td>
                      )}
                    </Tr>
                    <Tr>
                      {s.episodes.map((e) => (
                        <Td key={e.id} textAlign="center" fontSize={"12px"}>
                          {moment(e.air_date).isBefore() && DateFormat.toCustomDate(e.air_date, "DD/MM HH:mm")}
                          {moment(e.air_date).isAfter() && <CountdownTimer time={e.air_date} />}
                        </Td>
                      ))}
                      {publisherReleases?.maxEpisodes - s.episodes.length > 0 && (
                        <Td colSpan={publisherReleases?.maxEpisodes - s.episodes.length}>&nbsp;</Td>
                      )}
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          </WithLoading>
        </Flex>
      </Box>
    </Box>
  );
};
