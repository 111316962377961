import { AuthService } from "@services";
import { useAppSelector } from "@store";
import { UserRoles } from "types/user-roles";

export type WithRolesProps = {
  roles: UserRoles[];
};

export const WithRoles: React.FC<WithRolesProps> = ({ children, roles }) => {
  const role = useAppSelector((state) => state.user.role);

  if (!role) return null;
  if (!AuthService.hasPermission(roles, role)) return null;

  return <>{children}</>;
};
