import { Badge, BadgeProps } from "@chakra-ui/react";
import { FC } from "react";
import { UserRoles } from "types/user-roles";

type RoleLabelProps = {
  role: UserRoles;
  badgeProps?: BadgeProps;
};

const colors: Record<UserRoles, string> = {
  admin: "purple",
  super_admin: "red",
  publisher: "green",
  project_manager: "yellow",
  normal: "blue",
};

const colorLabel: Record<UserRoles, string> = {
  admin: "Admin",
  super_admin: "Super Admin",
  publisher: "Publisher",
  project_manager: "Project Manager",
  normal: "Normal",
};

export const RoleLabel: FC<RoleLabelProps> = ({ role, badgeProps }) => {
  return (
    <Badge ml="1" colorScheme={colors[role]} {...badgeProps}>
      {colorLabel[role]}
    </Badge>
  );
};
