import { Divider, Flex, Heading } from "@chakra-ui/layout";

export type PageHeaderProps = {
  title?: string;
  render?: React.ReactNode;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title, render, children }) => {
  return (
    <>
      <Flex mt="20px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
        <Flex direction="column">
          {!render && (
            <Heading size="lg" textTransform="capitalize">
              {title}
            </Heading>
          )}
          {render}
        </Flex>
        {children && <Flex>{children}</Flex>}
      </Flex>
      <Divider mb="30px" />
    </>
  );
};
