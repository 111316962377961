import { ArrowBackIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Container } from "@components";
import { AuthService } from "@services";
import { useAppDispatch, useAppSelector } from "@store";
import { goToStep, LoginFormSteps, resetLogin } from "@store/modules/login";
import { authenticate } from "@store/modules/user";
import { CONSTANTS, wait } from "@utils";
import { useState } from "react";
import { useHistory } from "react-router";

const EMAIL_MESSAGE = "Please insert the 6-digit authentication code sent to your email.";
const APP_MESSAGE = "Please insert the 6-digit authentication code provided by the Google Authentication app.";

export const RegisterTwoAuthCode = () => {
  const toast = useToast();
  const [resendingCode, setResendingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { password, username, twoStepType } = useAppSelector((state) => state.login);

  const onComplete = async (code: string) => {
    try {
      setLoading(true);
      setError("");

      const user = await AuthService.verifyTwoStepCode({ password, username, code });

      dispatch(authenticate(user));
      dispatch(resetLogin());

      await wait(0);

      history.push("/");
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
    }
  };

  const onClickBackToLogin = () => {
    dispatch(goToStep({ step: LoginFormSteps.LOGIN }));
  };

  const isTwoStepTypeApp = twoStepType === "app";
  const infoMessage = isTwoStepTypeApp ? APP_MESSAGE : EMAIL_MESSAGE;

  const onClickResendCode = async () => {
    try {
      setResendingCode(true);

      await AuthService.login({ username, password });

      toast({ status: "info", description: "A new code has been sent to your email!", isClosable: true });
    } catch (error: any) {
      toast({ status: "error", description: error.message, isClosable: true });
    } finally {
      setResendingCode(false);
    }
  };

  return (
    <Container>
      <Stack align="center">
        <Heading fontSize="4xl">{CONSTANTS.PROJECT_NAME}</Heading>
        <Heading fontSize="4sm">by {CONSTANTS.BY}</Heading>
      </Stack>
      <Flex
        direction="column"
        rounded="lg"
        bg="gray.700"
        boxShadow="lg"
        p={8}
        justify="center"
        alignItems="center"
        mb="8"
      >
        <Heading size="sm" mb="8" textAlign="center">
          {infoMessage}
        </Heading>

        <HStack spacing="3">
          <PinInput type="number" otp onComplete={onComplete}>
            <PinInputField autoFocus />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
        {error && (
          <Alert status="error" mt="6" w="76%" borderRadius="md" fontSize="sm">
            <AlertIcon />
            {error}
          </Alert>
        )}
        {loading && (
          <VStack spacing="4">
            <Spinner mt="8" />
            <Text fontSize="sm">Validating code, please wait...</Text>
          </VStack>
        )}
        <ButtonGroup mt="10" justifyContent={isTwoStepTypeApp ? "center" : "space-between"} w="full">
          <Button colorScheme="red" size="sm" onClick={onClickBackToLogin} leftIcon={<ArrowBackIcon />}>
            Back to login
          </Button>
          {!isTwoStepTypeApp && (
            <Button
              colorScheme="blue"
              size="sm"
              onClick={onClickResendCode}
              rightIcon={<EmailIcon />}
              isLoading={resendingCode}
            >
              Resend code
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </Container>
  );
};
