import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Box, Switch } from "@chakra-ui/react";
import { InputSwitchProps } from "@components/input";
import { Controller, useFormContext } from "react-hook-form";

export const InputSwitchForm = (props: InputSwitchProps) => {
  const { control } = useFormContext();
  const { switchProps, ...rest } = props;

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { value, ...field }, fieldState }) => (
        <FormControl {...rest} {...props.formControlProps}>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            {props.label && (
              <FormLabel htmlFor={props.name} m="0">
                {props.label}
              </FormLabel>
            )}
            <Switch {...field} {...switchProps} isChecked={!!value} />
          </Box>
          <Box>{fieldState.error?.message && <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>}</Box>
        </FormControl>
      )}
    />
  );
};
