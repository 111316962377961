import { User, UsersService } from "@services/users.service";
import useSWR from "swr";
import { HookResponse, makeSwrResponse } from "./utils";

export const useUsersFindAll = (): HookResponse<User[]> => {
  const key = "/users";
  const fetcher = () => UsersService.findAll();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useUsersFindById = (id?: string) => {
  const key = `/users/${id}`;
  const fetcher = () => (id ? UsersService.findById(id) : null);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useUsersFindSelf = () => {
  const key = "/users/self";
  const fetcher = () => UsersService.findSelf();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useUsersCalendarLink = () => {
  const key = "/users/calendar";
  const fetcher = () => UsersService.calendar();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
