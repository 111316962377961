import { Role, Serie, User } from "@services";
import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const EpisodesService = {
  download: async (episode_id: number, type: string): Promise<Blob> => {
    const promise = api.get<Blob>(`episodes/download/${episode_id}/${type}`, { responseType: "blob" });
    return handleRequestPromise(promise);
  },
  downloadFileName: async (episode_id: number, type: string): Promise<string> => {
    const promise = api.get<string>(`episodes/download/${episode_id}/${type}/fn`);
    return handleRequestPromise(promise);
  },
  findAll: async (series_id: number): Promise<Episode[]> => {
    const promise = api.get<Episode[]>(`episodes/series/${series_id}`);
    return handleRequestPromise(promise);
  },
  findById: async (episode_id: number): Promise<Episode> => {
    const promise = api.get<Episode>(`episodes/${episode_id}`);
    return handleRequestPromise(promise);
  },
  updateById: async (id: string, episode: Partial<Episode>): Promise<boolean> => {
    const promise = api.patch<boolean>(`episodes/${id}`, episode);
    return handleRequestPromise(promise);
  },
  updateFlagsById: async (id: string, episode: Partial<Episode>): Promise<boolean> => {
    const promise = api.patch<boolean>(`episodes/flags/${id}`, episode);
    return handleRequestPromise(promise);
  },
  deleteById: async (id: string): Promise<boolean> => {
    const promise = api.delete<boolean>(`episodes/${id}`);
    return handleRequestPromise(promise);
  },
  claimList: async (): Promise<ClaimList[]> => {
    const promise = api.get<ClaimList[]>(`episodes/claim`);
    return handleRequestPromise(promise);
  },
  claimEpisode: async (id: number): Promise<boolean> => {
    const promise = api.post<boolean>(`episodes/claim/${id}`);
    return handleRequestPromise(promise);
  },
  removeClaimEpisode: async (id: number): Promise<boolean> => {
    const promise = api.delete<boolean>(`episodes/claim/${id}`);
    return handleRequestPromise(promise);
  },
};

export type ClaimList = {
  series_id: number;
  series_title: string;
  series_short_title: string;
  short_title: string;
  title_jp: string;
  title_en: string;
  title_ch: string;
  episodes: {
    id: number;
    number: string;
    air_date: string;
    target_date: string;
    translated: string;
    claim_user_name?: string;
    claim_start?: string;
    claim_end?: string;
  }[];
};

export type EpisodeTeam = {
  id: number;
  user: User;
  role: Role;
  rate: number;
  created_at: string;
};

export type Episode = {
  id: number;
  order: number;
  number: string;
  title: string;
  description: string;
  air_date: string;
  target_submission_date: string;
  duration: number;
  invoice_type: string;
  video_available: string;
  video_available_flag: boolean;
  script_available: string;
  script_available_flag: boolean;
  submitted_date: string;
  submitted_date_flag: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  serie: Serie;
  previousEpisodeId?: number;
  nextEpisodeId?: number;
  episodeteam: EpisodeTeam[];
};
