import { Button, ButtonGroup, Divider, InputLeftElement, Text, useToast, VStack } from "@chakra-ui/react";
import { Container, Form, InputForm, WithLoading } from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRolesFindById } from "@hooks/api/roles.hook";
import { MainLayout } from "@layouts";
import { RolesService } from "@services/roles.service";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

const FORM_LABELS = {
  name: "Name",
  short_name: "Short Name",
  description: "Description",
  finish_text: "Notification for Work Completed",
  begin_text: "Notification for Work Outstanding",
  sftp_folder: "Archive FTP Folder",
  file_tag: "File Tag",
  color: "Color Hex",
  order: "Order",
  rate: "Default Rate",
  active: "Active",
};

const SCHEMA = Yup.object({
  name: Yup.string().required().label(FORM_LABELS.name),
  short_name: Yup.string().required().label(FORM_LABELS.short_name),
  description: Yup.string().required().label(FORM_LABELS.description),
  finish_text: Yup.string().required().label(FORM_LABELS.finish_text),
  begin_text: Yup.string().required().label(FORM_LABELS.begin_text),
  sftp_folder: Yup.string().required().label(FORM_LABELS.sftp_folder),
  file_tag: Yup.string().required().label(FORM_LABELS.file_tag),
  color: Yup.string().required().label(FORM_LABELS.color),
  order: Yup.number().required().label(FORM_LABELS.order),
  rate: Yup.number().required().label(FORM_LABELS.rate),
  active: Yup.bool().required().label(FORM_LABELS.active),
});

type FormFields = Yup.InferType<typeof SCHEMA>;

const DEFAULT_VALUES: FormFields = {
  name: "",
  short_name: "",
  description: "",
  finish_text: "",
  begin_text: "",
  sftp_folder: "",
  file_tag: "",
  color: "#ffffff",
  order: 1,
  rate: 0,
  active: false,
};

export const RolesForm = () => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const history = useHistory();
  const { data, loading, refetch } = useRolesFindById(id);
  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  const { isDirty, isSubmitting } = methods.formState;

  useEffect(() => {
    if (data) methods.reset(data);
  }, [data]);

  const onSubmit = async (values: FormFields) => {
    try {
      const promise = id ? RolesService.updateById(id, values) : RolesService.create(values);

      await promise;

      const messageStatus = id ? "updated" : "created";

      toast({
        status: "success",
        description: `Roles ${messageStatus} successfully.`,
      });

      refetch();

      history.push("/roles");
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  return (
    <MainLayout>
      <Container>
        <WithLoading loading={loading}>
          <Form methods={methods} onSubmit={onSubmit}>
            <VStack spacing="4">
              <Text fontSize="28px" textAlign="left" width="100%">
                {id ? "Edit" : "Add"} Role
              </Text>
              <Divider />

              <InputForm label={FORM_LABELS.name} name="name" />
              <InputForm label={FORM_LABELS.short_name} name="short_name" />
              <InputForm label={FORM_LABELS.description} name="description" />
              <InputForm label={FORM_LABELS.finish_text} name="finish_text" />
              <InputForm label={FORM_LABELS.begin_text} name="begin_text" />
              <InputForm label={FORM_LABELS.sftp_folder} name="sftp_folder" />
              <InputForm
                label={FORM_LABELS.rate}
                name="rate"
                type="number"
                inputProps={{ step: 0.01 }}
                leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
              />
              <InputForm label={FORM_LABELS.order} name="order" type="number" />
              <InputForm label={FORM_LABELS.file_tag} name="file_tag" />
              <InputForm label={FORM_LABELS.color} name="color" type="color" inputProps={{ min: 1, step: 1 }} />

              <InputSwitchForm label={FORM_LABELS.active} name="active" />

              <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="space-between">
                <Button colorScheme="red" size="md" onClick={() => history.push("/roles")}>
                  Cancel
                </Button>
                <Button colorScheme="teal" size="md" type="submit" isLoading={isSubmitting} isDisabled={!isDirty}>
                  Save
                </Button>
              </ButtonGroup>
            </VStack>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
