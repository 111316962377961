import {
  Avatar,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  InputRightElement,
  Spinner,
  useClipboard,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  Container,
  Form,
  Input,
  InputForm,
  InputPasswordForm,
  InputSelectForm,
  RoleLabel,
  SelectOption,
  SelectOptions,
  WithLoading,
} from "@components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUsersCalendarLink, useUsersFindSelf } from "@hooks/api/users.hook";
import { useLanguages, useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { SelfUpdateErrorCodes, UsersService } from "@services";
import { useAppDispatch } from "@store";
import { setTimezone, setUser } from "@store/modules/user";
import { VALIDATORS } from "@utils";
import * as moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const FORM_LABELS = {
  name: "Name",
  avatar: "Avatar",
  password: "New Password",
  oldPassword: "Old Password",
  confirmPassword: "Confirm New Password",
  timezone: "Timezone",
  email: "Email",
  discordId: "Discord ID",
  username: "Username",
};

const SCHEMA = Yup.object({
  name: Yup.string().required().label(FORM_LABELS.name),
  oldPassword: Yup.string()
    .nullable()
    .test("is-valid-password", VALIDATORS.PASSWORD.message, (value = "") =>
      value ? VALIDATORS.PASSWORD.rule.test(value) : true
    )
    .label(FORM_LABELS.oldPassword),
  password: Yup.string()
    .nullable()
    .when("oldPassword", {
      is: (oldPassword: string) => !!oldPassword,
      then: Yup.string().required(),
    })
    .test("is-valid-password", VALIDATORS.PASSWORD.message, (value = "") =>
      value ? VALIDATORS.PASSWORD.rule.test(value) : true
    )
    .label(FORM_LABELS.password),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .when("password", {
      is: (password: string) => !!password,
      then: Yup.string().required(),
    })
    .label(FORM_LABELS.confirmPassword),
  avatar: Yup.mixed().label(FORM_LABELS.name),
  timezone: Yup.string().label(FORM_LABELS.timezone),
});

const DEFAULT_VALUES = {
  avatar: null,
  name: "",
  language_id: "1",
  timezone: "UTC",
  oldPassword: "",
  confirmPassword: "",
  password: "",
};

type FormFields = {
  avatar: File | null;
  name: string;
  oldPassword: string;
  password: string;
  language_id: string;
  confirmPassword: string;
  calendar_link: string;
  timezone: string;
};

export const Profile = () => {
  const translations = useTranslations();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const { loading, data: user } = useUsersFindSelf();
  const { loading: loadingCalendar, data: calendarLinkData } = useUsersCalendarLink();
  const [avatarURL, setAvatarURL] = useState(user?.image || "");
  const languages = useLanguages();

  const methods = useForm<FormFields>({
    resolver: yupResolver(SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { isDirty, isSubmitting } = methods.formState;

  const handleChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files![0];
    setAvatarURL(URL.createObjectURL(file));
    methods.setValue("avatar", file, { shouldDirty: true });
  };

  const handleSubmit = async (values: FormFields) => {
    methods.clearErrors();

    try {
      await UsersService.selfUpdate({
        ...values,
        confirm_password: values.confirmPassword,
        old_password: values.oldPassword,
      });

      const newData = await UsersService.findSelf();

      dispatch(
        setUser({
          avatar: newData.image,
          name: newData.name,
          language_id: newData.language_id,
        })
      );

      dispatch(setTimezone({ timezone: values.timezone }));

      toast({
        status: "success",
        description: translations["profile.form.update-success-message"],
      });
    } catch (error: any) {
      if (error.code) {
        if (error.code === SelfUpdateErrorCodes.INVALID_OLD_PASSWORD) {
          methods.setError("oldPassword", { message: error.message });
          return;
        }
      }

      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  useEffect(() => {
    if (user) {
      methods.reset({
        name: user.name,
        timezone: user.timezone,
        language_id: user.language_id?.toString() || "1",
      });
      setAvatarURL(user.image);
    }
  }, [user]);

  useEffect(() => {
    if (calendarLinkData) {
      methods.setValue("calendar_link", calendarLinkData.link);
    }
  }, [loadingCalendar, calendarLinkData]);

  const { hasCopied, onCopy } = useClipboard(calendarLinkData?.link);
  const options: SelectOptions = languages.map<SelectOption>((l) => ({ label: l.label, value: l.id.toString() }));
  const TIMEZONE_SELECT = moment.tz.names().map((t) => ({ label: t, value: t }));

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "container.md", w: "full" }}>
        <Heading textAlign={{ base: "center", md: "inherit" }}>
          {translations["profile.title"]} {user && <RoleLabel role={user.permission} />}{" "}
        </Heading>
        <Divider my={{ base: "2", md: "4" }} />
        <WithLoading loading={loading}>
          <Form methods={methods} onSubmit={handleSubmit}>
            <Flex flex={1} direction="column">
              <Flex direction={{ base: "column", md: "row" }}>
                <VStack mr={{ base: undefined, md: "8" }} mb={{ base: "8", md: "inherit" }}>
                  <input
                    type="file"
                    ref={ref}
                    accept=".jpg,.png"
                    onChange={handleChangeAvatar}
                    style={{ display: "none" }}
                  />
                  <Avatar size="2xl" name={user?.name} src={avatarURL} />
                  <Button size="sm" onClick={() => ref.current?.click()}>
                    {translations["profile.form.upload-button"]}
                  </Button>
                </VStack>
                <Flex direction="column" flex={1}>
                  <InputForm
                    name="name"
                    label={translations["users.form.name.label"]}
                    placeholder={translations["users.form.name.label"]}
                  />
                  <Input
                    isDisabled
                    name="email"
                    label={translations["users.form.email.label"]}
                    inputProps={{ defaultValue: user?.email }}
                  />
                  <HStack spacing={4}>
                    <Input
                      isDisabled
                      name="username"
                      label={translations["user.form.username.label"]}
                      placeholder={translations["user.form.username.label"]}
                      inputProps={{ defaultValue: user?.username }}
                    />
                    <Input
                      isDisabled
                      name="discord_id"
                      label={translations["user.form.discord.label"]}
                      placeholder={translations["user.form.discord.label"]}
                      inputProps={{ defaultValue: user?.discord_id }}
                    />
                  </HStack>
                  <InputSelectForm
                    label={translations["profile.form.timezone"]}
                    name="timezone"
                    options={TIMEZONE_SELECT}
                  />
                  <InputSelectForm label={translations["profile.form.language"]} name="language_id" options={options} />
                  <InputPasswordForm
                    name="oldPassword"
                    label={translations["profile.form.old-password"]}
                    placeholder={translations["profile.form.old-password"]}
                  />
                  <InputPasswordForm
                    name="password"
                    label={translations["profile.form.new-password"]}
                    placeholder={translations["profile.form.new-password"]}
                  />
                  <InputPasswordForm
                    name="confirmPassword"
                    label={translations["profile.form.confirm-password"]}
                    placeholder={translations["profile.form.confirm-password"]}
                  />
                  <Input
                    name="calendar_link"
                    label={translations["profile.form.calendar-link"]}
                    rightElement={
                      <InputRightElement width="5rem">
                        {loadingCalendar && <Spinner />}
                        {!loadingCalendar && (
                          <Button onClick={onCopy} size="sm" h="1.75rem">
                            {hasCopied
                              ? translations["profile.form.calendar-copied"]
                              : translations["profile.form.calendar-copy"]}
                          </Button>
                        )}
                      </InputRightElement>
                    }
                    inputProps={{ pr: "5rem", defaultValue: calendarLinkData?.link }}
                  />
                </Flex>
              </Flex>
              <Flex flex={1} justify="flex-end" mt="6">
                <Button
                  colorScheme="teal"
                  size="md"
                  type="submit"
                  isDisabled={!isDirty || isSubmitting}
                  isLoading={isSubmitting}
                  width={{ base: "full", md: "inherit" }}
                >
                  {translations["profile.form.save-button"]}
                </Button>
              </Flex>
            </Flex>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
