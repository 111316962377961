import { Box, Button, Heading, Stack } from "@chakra-ui/react";
import { AlertError, Container, Form, InputForm, InputPasswordForm } from "@components";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "@services";
import { useAppDispatch } from "@store";
import { goToStep, LoginFormSteps, setLogin } from "@store/modules/login";
import { CONSTANTS } from "@utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const SCHEMA = Yup.object({
  username: Yup.string().required().label("Username"),
  password: Yup.string().required().label("Password"),
});

type FormFields = Yup.InferType<typeof SCHEMA>;

const DEFAULT_VALUES: FormFields = {
  password: "",
  username: "",
};

export const Login = () => {
  const [error, setError] = useState("");
  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  const dispatch = useAppDispatch();

  const { isSubmitting } = methods.formState;

  const onSubmit = async (values: FormFields) => {
    try {
      setError("");

      const { first_login, two_step_type } = await AuthService.login(values);

      const step = first_login ? LoginFormSteps.SCAN_QRCODE : LoginFormSteps.REGISTER_TWO_AUTH_CODE;

      dispatch(setLogin({ ...values, twoStepType: two_step_type }));
      dispatch(goToStep({ step }));
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container>
      <Stack align="center">
        <Heading fontSize="4xl">{CONSTANTS.PROJECT_NAME}</Heading>
        <Heading fontSize="4sm">by {CONSTANTS.BY}</Heading>
      </Stack>
      <Box w="full" rounded="lg" bg="gray.700" boxShadow="lg" p={8}>
        <Form methods={methods} onSubmit={onSubmit}>
          <InputForm
            inputProps={{ autoFocus: true }}
            mb="4"
            name="username"
            label="Username"
            placeholder="Your username"
          />
          <InputPasswordForm name="password" label="Password" type="password" placeholder="Your password" />
          <AlertError message={error} />
          <Button isLoading={isSubmitting} type="submit" w="full" mt="6">
            Login
          </Button>
        </Form>
      </Box>
    </Container>
  );
};
