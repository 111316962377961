import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "@store";

const selectSelf = (state: RootState) => state;
const getTranslations = createDraftSafeSelector(selectSelf, ({ translations }) => translations.translations);
const getLanguages = createDraftSafeSelector(selectSelf, ({ translations }) => translations.languages);
const getCurrentLanguage = createDraftSafeSelector(
  selectSelf,
  ({ translations, user }) => translations.languages.find((l) => l.id === user.language_id)!
);

export const TranslationsSelectors = {
  getTranslations,
  getLanguages,
  getCurrentLanguage,
};
