import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { WithLoading } from "@components";
import { useDashboardAdminStaffEfficiency } from "@hooks/api/dashboard.hook";
import { useTranslations } from "@hooks/useTranslations";
import { CardHeader } from "@pages";
import React from "react";

export const AdminStaffEfficiency = () => {
  const translations = useTranslations();
  const { data: staffEfficiency, loading: staffEfficiencyLoading } = useDashboardAdminStaffEfficiency();

  return (
    <Box as="section" pt="12" px={{ md: "8" }}>
      <Box rounded={{ md: "lg" }} shadow="dark-lg" pb="25px" x="auto">
        <CardHeader title={translations["dashboard.staff-overview"]} />
        <Flex
          overflowX={"auto"}
          x="auto"
          display="block"
          sx={{
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
          }}
        >
          <WithLoading centerH={false} loading={staffEfficiencyLoading}>
            <Table size="sm" variant="striped">
              <Thead>
                <Tr>
                  <Th minWidth="200px" maxWidth="200px" width="200px">
                    {translations["dashboard.staff-overview.user"]}
                  </Th>
                  <Th>{translations["dashboard.staff-overview.last-day"]}</Th>
                  <Th>{translations["dashboard.staff-overview.7-days"]}</Th>
                  <Th>{translations["dashboard.staff-overview.14-days"]}</Th>
                  <Th>{translations["dashboard.staff-overview.30-days"]}</Th>
                  <Th>{translations["dashboard.staff-overview.average"]}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {staffEfficiency?.users.map((u) => (
                  <React.Fragment key={u.id}>
                    <Tr>
                      <Td>{u.name}</Td>
                      <Td>{u.count.last_day}</Td>
                      <Td>{u.count.last_7}</Td>
                      <Td>{u.count.last_14}</Td>
                      <Td>{u.count.last_30}</Td>
                      <Td>{u.count.avg}</Td>
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          </WithLoading>
        </Flex>
      </Box>
    </Box>
  );
};
