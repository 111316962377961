import { RolesService } from "@services/roles.service";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const useRolesFindAll = (onlyActive = false) => {
  const key = "/roles";
  const fetcher = () => RolesService.findAll(onlyActive);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useRolesFindById = (id: string) => {
  const key = `/roles/${id}`;
  const fetcher = () => (id ? RolesService.findById(id) : null);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
