import { Serie } from "@services";
import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const DashboardService = {
  releases: async (): Promise<DashboardReleases> => {
    const promise = api.get<DashboardReleases>(`dashboard/releases`);
    return handleRequestPromise(promise);
  },
  usersNextEpisodes: async (): Promise<DashboardNextEpisodes> => {
    const promise = api.get<DashboardNextEpisodes>(`dashboard/usersNextEpisodes`);
    return handleRequestPromise(promise);
  },
  adminSeriesEpisodes: async (): Promise<DashboardAdminSeriesEpisodes> => {
    const promise = api.get<DashboardAdminSeriesEpisodes>(`dashboard/adminSeriesEpisodes`);
    return handleRequestPromise(promise);
  },
  adminSeriesRoleOverview: async (): Promise<DashboardAdminSeriesRoleOverview> => {
    const promise = api.get<DashboardAdminSeriesRoleOverview>(`dashboard/adminSeriesRoleOverview`);
    return handleRequestPromise(promise);
  },
  adminStaffEfficiency: async (): Promise<DashboardAdminStaffEfficiency> => {
    const promise = api.get<DashboardAdminStaffEfficiency>(`dashboard/adminStaffEfficiency`);
    return handleRequestPromise(promise);
  },
};

export type NextEpisode = {
  id: number;
  order: number;
  number: string;
  target_submission_date: string;
  serie: {
    id: number;
    short_title: string;
    title_en: string;
    title_cn: string;
    title_jp: string;
  };
};

export type DashboardNextEpisodes = {
  simulcast: NextEpisode[];
  catalogue: NextEpisode[];
};

export type DashboardAdminStaffEfficiency = {
  users: {
    id: number;
    name: string;
    count: {
      last_day: number;
      last_7: number;
      last_14: number;
      last_30: number;
      avg: number;
    };
  }[];
};

export type DashboardAdminSeriesRoleOverview = {
  roles: {
    id: number;
    name: string;
  }[];
  series: Array<
    Partial<Serie> & {
      roles_last_status: {
        [Key: string]: {
          episode_number: number;
          user_name: string;
          date: string;
        };
      };
    }
  >;
};

export type DashboardAdminSeriesEpisodes = {
  maxEpisodes: number;
  series: Array<Partial<Serie> & { episodes: DashboardReleasesEpisode[] }>;
};

export type DashboardReleasesEpisode = {
  id: number;
  number: string;
  order: number;
  air_date: string;
  video_available: string;
  script_available: string;
  submitted_date: string;
  last_status: {
    color: string;
    description: string;
    date: string;
  };
};

export type DashboardReleases = {
  calendar: Array<
    Partial<Serie> & {
      episodes: {
        [Key: string]: DashboardReleasesEpisode[];
      };
    }
  >;
  series: Array<
    Partial<Serie> & {
      episodes: DashboardReleasesEpisode[];
    }
  >;
  maxEpisodes: number;
};
