import { UserRoles } from "types/user-roles";
import { api } from "./api";
import { Role } from "./roles.service";
import { handleRequestPromise } from "./_utils_";

export const UsersService = {
  findSelf: async () => {
    const promise = api.get<User>("users/self");
    return handleRequestPromise(promise);
  },
  calendar: async () => {
    const promise = api.get<{ link: string }>("users/calendar");
    return handleRequestPromise(promise);
  },
  findAll: async () => {
    const promise = api.get<User[]>("users");
    return handleRequestPromise(promise);
  },
  findById: async (id: string) => {
    const promise = api.get<User>(`users/${id}`);
    return handleRequestPromise(promise);
  },
  updateById: async (id: string, user: Partial<UserCreatePayload>) => {
    const promise = api.patch<boolean>(`users/${id}`, user);
    return handleRequestPromise(promise);
  },
  create: async (user: UserUpdatePayload): Promise<User> => {
    const promise = api.post<User>(`users`, user);
    return handleRequestPromise(promise);
  },
  delete: async (id: number): Promise<boolean> => {
    const promise = api.delete<boolean>(`users/${id}`);
    return handleRequestPromise(promise);
  },
  selfUpdate: async (user: UserSelUpdatePayload): Promise<boolean> => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(user)) {
      formData.append(key, value as any);
    }

    const promise = api.patch<boolean>(`users/self`, formData, {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    });

    return handleRequestPromise(promise);
  },
};

export enum SelfUpdateErrorCodes {
  INVALID_OLD_PASSWORD = "INVALID_OLD_PASSWORD",
}

export type UserSelUpdatePayload = {
  avatar: File | null;
  name?: string;
  password?: string;
  language_id?: string;
  confirm_password?: string;
  old_password?: string;
};

export type UserCreatePayload = Omit<User, "id" | "avatar" | "image" | "roles"> & { roles: number[] };

export type UserUpdatePayload = UserCreatePayload;

export type User = {
  id: number;
  avatar: string;
  image: string;
  name: string;
  username: string;
  permission: UserRoles;
  language_id?: number;
  email: string;
  timezone: string;
  discord_id: string;
  submission_notification: boolean;
  roles?: Role[];
  two_step_type: "app" | "email";
};
