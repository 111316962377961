import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  SelectProps as SelectPropsChakra,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { RegisterOptions } from "react-hook-form";

export type SelectOption = {
  value: string;
  label: string;
};

export type SelectOptions = SelectOption[];

type SelectProps = {
  label: string;
  name: string;
  errorMessage?: string;
  helperMessage?: string;
  options: SelectOptions;
  placeholder?: string;
  registerOptions?: RegisterOptions;
};

type ChakraProps = {
  selectProps?: SelectPropsChakra;
  formControlProps?: FormControlProps;
};

export type InputSelectProps = SelectProps & ChakraProps;

export const InputSelect = forwardRef<any, InputSelectProps>(
  (
    {
      options,
      label,
      name,
      errorMessage,
      helperMessage,
      placeholder = "Select",
      formControlProps,
      selectProps,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl {...props} {...formControlProps}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Select placeholder={placeholder} id={name} name={name} ref={ref} {...selectProps}>
          {options.map((d) => (
            <option key={d.value} value={d.value}>
              {d.label}
            </option>
          ))}
        </Select>
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        {!errorMessage && <FormHelperText>{helperMessage}</FormHelperText>}
      </FormControl>
    );
  }
);
