import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { WithRoles } from "@components";
import { useTranslations } from "@hooks/useTranslations";
import { CONSTANTS } from "@utils";
import { ADMIN_PUBLISHER, ADMIN_ROLES, ALL_ROLES, ALL_USERS, SUPER_ADMIN } from "@utils/permissions";
import { useHistory, useLocation } from "react-router";
import { UserRoles } from "types/user-roles";

type LinkItem = {
  label: string;
  link?: string;
  mobileLabel?: string;
  roles: UserRoles[];
  sub?: LinkItem[];
};

const useLinks = (): LinkItem[] => {
  const translations = useTranslations();

  const links: LinkItem[] = [
    {
      label: translations["menu.dashboard"],
      roles: ALL_ROLES,
      link: "/",
    },
    {
      label: translations["menu.series"],
      roles: ALL_ROLES,
      link: "/series",
      sub: [{ label: translations["menu.series.archived"], link: "/series/archived", roles: ALL_ROLES }],
    },
    {
      label: translations["menu.payroll"],
      mobileLabel: translations["menu.payroll"],
      roles: ALL_USERS,
      link: "/payroll/next",
      sub: [
        { label: translations["menu.payroll.create"], roles: SUPER_ADMIN, link: "/payroll/new" },
        { label: translations["menu.payroll.history"], roles: ALL_USERS, link: "/payroll/history" },
        { label: translations["menu.payroll.expenses"], roles: SUPER_ADMIN, link: "/payroll/expenses" },
      ],
    },
    {
      label: translations["menu.invoice"],
      mobileLabel: translations["menu.invoice"],
      roles: ADMIN_PUBLISHER,
      link: "/invoice/next",
      sub: [
        { label: translations["menu.invoice.create"], roles: SUPER_ADMIN, link: "/invoice/create" },
        { label: translations["menu.invoice.history"], roles: ADMIN_PUBLISHER, link: "/invoice" },
      ],
    },
    {
      label: translations["menu.roles"],
      roles: ADMIN_ROLES,
      link: "/roles",
    },
    {
      label: translations["menu.users"],
      roles: ADMIN_ROLES,
      link: "/users",
    },
    {
      label: translations["menu.publishers"],
      roles: ADMIN_ROLES,
      link: "/publishers",
    },
    {
      label: translations["menu.translations"],
      roles: ADMIN_ROLES,
      link: "/translations",
    },
  ];

  return links;
};

const activeMenu = (link: string, pathname: string) => {
  const HOME = "/";
  const isHome = link === HOME && pathname === HOME;

  if (isHome) return true;

  const str = link.replaceAll("/", "");

  if (!str) return false;
  const path = pathname.split("/")[1];
  const active = new RegExp(str, "gi").test(path);

  return active;
};

export const NavItemDesktop = ({ item }: { item: LinkItem }) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const redirect = (link: string) => () => history.push(link);
  const { pathname } = useLocation();
  const activeMenuItem = activeMenu(item.link || "", pathname) ? "gray.700" : undefined;
  const onClick = item.link ? redirect(item.link) : undefined;

  if (!item.sub) {
    return (
      <WithRoles roles={item.roles}>
        <Button
          pr="2"
          pl="2"
          rounded={"md"}
          variant="unstyled"
          w={{ base: "full", md: "inherit" }}
          onClick={onClick}
          backgroundColor={activeMenuItem}
          _hover={{
            textDecoration: "none",
            bg: "gray.700",
          }}
        >
          {item.label}
        </Button>
      </WithRoles>
    );
  }

  return (
    <WithRoles roles={item.roles}>
      <Box onMouseEnter={onOpen} onMouseLeave={onClose}>
        <Menu isOpen={isOpen} onClose={onClose}>
          <MenuButton
            as={Button}
            pr="2"
            pl="2"
            rounded="md"
            variant="unstyled"
            onClick={onClick}
            backgroundColor={activeMenuItem}
            _hover={{
              textDecoration: "none",
              bg: "gray.700",
            }}
          >
            {item.label}
          </MenuButton>
          <MenuList zIndex={3}>
            {item.sub.map((i) => (
              <WithRoles key={i.link} roles={i.roles}>
                <MenuItem onClick={redirect(i.link || "")}>{i.label}</MenuItem>
              </WithRoles>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </WithRoles>
  );
};

export const DesktopMenu = () => {
  const links = useLinks();

  return (
    <HStack spacing={8} alignItems={"center"}>
      <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
        {links.map((link, idx) => (
          <NavItemDesktop key={idx} item={link} />
        ))}
      </HStack>
    </HStack>
  );
};

export const NavItemMobile = ({ item }: { item: LinkItem }) => {
  const history = useHistory();
  const { isOpen, onToggle } = useDisclosure();
  const redirect = (link: string) => () => history.push(link);
  const { pathname } = useLocation();
  const activeMenuItem = activeMenu(item.link || "", pathname) ? "gray.600" : undefined;

  const btnProps = {
    pr: "2",
    pl: "2",
    rounded: "md",
    variant: "unstyled",
    w: { base: "full", md: "inherit" },
    _hover: {
      textDecoration: "none",
      bg: "gray.700",
    },
  };

  if (!item.sub) {
    return (
      <Button {...btnProps} onClick={redirect(item.link || "")} backgroundColor={activeMenuItem}>
        {item.label}
      </Button>
    );
  }

  return (
    <WithRoles roles={item.roles}>
      <Button {...btnProps} onClick={onToggle} backgroundColor={activeMenuItem}>
        {item.label}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {item.sub?.map((sub) => (
          <WithRoles roles={sub.roles} key={sub.link}>
            <Button {...btnProps} onClick={redirect(sub.link || "")}>
              {sub.label}
            </Button>
            <Divider />
          </WithRoles>
        ))}
      </Collapse>
    </WithRoles>
  );
};

export const MobileMenu = ({ onToggle }: { onToggle: () => void }) => {
  const links = useLinks();

  return (
    <Drawer isOpen placement="left" size="xs" onClose={onToggle}>
      <DrawerOverlay />
      <DrawerContent width="30%">
        <DrawerCloseButton />
        <DrawerHeader>{CONSTANTS.PROJECT_NAME}</DrawerHeader>
        <DrawerBody>
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {links.map((link, key) => (
                <Box key={key}>
                  <NavItemMobile item={link} />
                  <Divider />
                </Box>
              ))}
            </Stack>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
