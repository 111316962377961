import { Button, ButtonGroup, Divider, Heading, useToast } from "@chakra-ui/react";
import { Container, Form, InputForm, SelectOptions, WithLoading } from "@components";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePayrollNew } from "@hooks/api/payroll.hook";
import { useUsersFindAll } from "@hooks/api/users.hook";
import { MainLayout } from "@layouts";
import { PayrollCreatePayload, PayrollService } from "@services/payroll.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ExpensesList } from "./expenseslist";
import { SeriesPayroll } from "./seriespayroll";

const FORM_LABELS = {
  name: "Title",
  user: "User",
  start_date: "Start Date",
  end_date: "End Date",
};

const SCHEMA = Yup.object({
  name: Yup.string().required().label(FORM_LABELS.name),
  start_date: Yup.string().required().label(FORM_LABELS.start_date),
  end_date: Yup.string().required().label(FORM_LABELS.end_date),
  payroll: Yup.array()
    .of(
      Yup.object({
        serie: Yup.object({
          id: Yup.number().required(),
          title: Yup.string().required(),
        }).required(),
        episodes: Yup.array()
          .of(
            Yup.object({
              id: Yup.number().required(),
              number: Yup.string().required(),
              team: Yup.array()
                .of(
                  Yup.object({
                    id: Yup.number().required(),
                    user_id: Yup.number().required(),
                    user_username: Yup.string(),
                    role_id: Yup.number().required(),
                    role_name: Yup.string().required(),
                    rate: Yup.number().required(),
                    duration: Yup.number().required(),
                    approved: Yup.boolean().required(),
                  }).required()
                )
                .required(),
            })
          )
          .required(),
        expenses: Yup.array()
          .of(
            Yup.object({
              expense_id: Yup.number().required(),
              user_id: Yup.number().required(),
              user_username: Yup.string(),
              name: Yup.string().required(),
              description: Yup.string().required(),
              amount: Yup.number().required(),
              approved: Yup.boolean().required(),
            }).required()
          )
          .required(),
      }).required()
    )
    .required(),
  payroll_expenses: Yup.array()
    .of(
      Yup.object({
        expense_id: Yup.number().required(),
        user_id: Yup.number().required(),
        user_username: Yup.string(),
        category: Yup.string().required(),
        description: Yup.string().required(),
        amount: Yup.number().required(),
        approved: Yup.boolean().required(),
      }).required()
    )
    .required(),
});

export type PayrollFormFields = Yup.InferType<typeof SCHEMA>;

const DEFAULT_VALUES: PayrollFormFields = {
  name: moment().subtract(1, "month").format("MMM YYYY"),
  start_date: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
  end_date: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  payroll: [],
  payroll_expenses: [],
};

export const NewPayroll = () => {
  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  console.log(methods.formState.errors);

  const history = useHistory();

  const toast = useToast();

  const [creating, setCreating] = useState(false);

  const onSubmit = async (values: PayrollFormFields) => {
    setCreating(true);

    try {
      const reduceEpisodeTeam = (episodes: PayrollFormFields["payroll"][number]["episodes"]) =>
        episodes.reduce<PayrollCreatePayload["teams"]>(
          (acc, current) =>
            acc.concat(
              current.team
                .filter((t) => t.approved)
                .map((team) => ({
                  id: team.id,
                  rate: team.rate,
                  duration: team.duration,
                  role_id: team.role_id,
                  user_id: team.user_id,
                }))
            ),
          []
        );

      const payroll_teams = values.payroll.reduce<PayrollCreatePayload["teams"]>(
        (acc, current) => acc.concat(reduceEpisodeTeam(current.episodes)),
        []
      );

      const series_expenses = values.payroll.reduce<PayrollCreatePayload["series_expenses"]>(
        (acc, current) =>
          acc.concat(
            current.expenses
              .filter((e) => e.approved)
              .map((expense) => ({
                expense_id: expense.expense_id,
                amount: expense.amount,
                description: expense.description,
                name: expense.name,
                user_id: expense.user_id,
                series_id: current.serie.id,
              }))
          ),
        []
      );

      const payroll_expenses = values.payroll_expenses.filter((e) => e.approved);

      const payroll: PayrollCreatePayload = {
        name: values.name,
        start_date: values.start_date,
        end_date: values.end_date,
        payroll_expenses,
        series_expenses,
        teams: payroll_teams,
      };

      await PayrollService.create(payroll);
    } catch (error) {
      toast({
        status: "error",
        description: "Error when creating payroll!",
        isClosable: true,
      });
      return;
    } finally {
      setCreating(false);
    }

    toast({
      status: "success",
      description: "Payroll created successfully.",
      isClosable: true,
    });

    history.push("/payroll/history");
  };

  const { data: usersData, loading: usersLoading } = useUsersFindAll();
  const { data: payrollData, loading: payrollLoading } = usePayrollNew();
  const SELECT_USERS: SelectOptions =
    usersData
      ?.map((u) => ({ label: u.username, value: u.id.toString() }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const { fields: seriesGroupFormData } = useFieldArray({
    control: methods.control,
    name: "payroll",
    keyName: "field_id",
  });

  useEffect(() => {
    methods.reset({
      ...DEFAULT_VALUES,
      payroll: payrollData?.payroll || [],
      payroll_expenses: payrollData?.payroll_expenses || [],
    });
  }, [payrollData]);

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.lg" } }}>
        <WithLoading loading={usersLoading || payrollLoading}>
          <Form methods={methods} onSubmit={onSubmit}>
            <Heading size="lg" mb="30px">
              Create Payment
            </Heading>
            <InputForm label={FORM_LABELS.name} name="name" />

            <Divider mt="30px" mb="30px" />

            <InputForm type="date" label={FORM_LABELS.start_date} name="start_date" />
            <InputForm type="date" label={FORM_LABELS.end_date} name="end_date" />

            <Divider mt="30px" mb="30px" />

            {seriesGroupFormData.map((group, index) => (
              <SeriesPayroll key={group.field_id} series_index={index} group={group} users={usersData || []} />
            ))}

            <ExpensesList SELECT_USERS={SELECT_USERS} />

            <Divider mt="30px" mb="30px" />

            <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end" mt="15px">
              <Button colorScheme="red" size="md" as={Link} to={"/payroll/history"} isDisabled={creating}>
                Cancel
              </Button>

              <Button colorScheme="teal" size="md" type="submit" isLoading={creating} isDisabled={creating}>
                Create
              </Button>
            </ButtonGroup>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
