import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

type FormProps = {
  methods: UseFormReturn<any>;
  onSubmit: (...args: any[]) => Promise<void> | void;
};

export const Form: FC<FormProps> = ({ children, methods, onSubmit }) => {
  return (
    <FormProvider {...methods}>
      <Box w="full" as="form" onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </Box>
    </FormProvider>
  );
};
