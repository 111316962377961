import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const EpisodesTeamsService = {
  create: async (episodeteam: EpisodesTeamsCreate): Promise<boolean> => {
    const promise = api.post<boolean>(`episodesteams`, episodeteam);
    return handleRequestPromise(promise);
  },
  adminCreate: async (episodeteam: EpisodesTeamsAdminCreate): Promise<boolean> => {
    const promise = api.post<boolean>(`episodesteams/create`, episodeteam);
    return handleRequestPromise(promise);
  },
  update: async (id: string, episodeteam: EpisodesTeamsAdminCreate): Promise<boolean> => {
    const promise = api.patch<boolean>(`episodesteams/${id}`, episodeteam);
    return handleRequestPromise(promise);
  },
};

export type EpisodesTeamsAdminCreate = {
  episodeId: number;
  roleId: number;
  userId: number;
};

export type EpisodesTeamsCreate = {
  episodeId: number;
  roleId: number;
};
