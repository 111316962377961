import { DeleteIcon } from "@chakra-ui/icons";
import { Button, ButtonGroup, IconButton, Switch, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { InputForm, InputSelectForm, SelectOptions } from "@components";
import { User } from "@services";
import { moneyFormat } from "@utils/money-format";
import { Control, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { PayrollFormFields } from "./new";

export type SeriesExpensesListProps = {
  series_index: number;
  users: User[];
};

export const SeriesExpensesList = ({ series_index, users }: SeriesExpensesListProps) => {
  const { register, control } = useFormContext<PayrollFormFields>();
  const userSelectOptions: SelectOptions = users
    .map((u) => ({
      label: u.name,
      value: u.id.toString(),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const { fields, append, remove } = useFieldArray({
    control,
    name: `payroll.${series_index}.expenses` as "payroll.0.expenses",
    keyName: "field_id",
  });

  return (
    <>
      <Table variant="striped" marginTop="16px !important">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th w="20%">Amount</Th>
            <Th w="5%">Approved</Th>
            <Th w="3%">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((expense, i) => (
            <Tr key={expense.field_id}>
              <Td>
                <InputSelectForm
                  label=""
                  options={userSelectOptions}
                  name={`payroll.${series_index}.expenses.${i}.user_id`}
                  selectProps={{ defaultValue: expense.user_id, size: "sm" }}
                />
              </Td>
              <Td>
                <InputForm
                  label=""
                  name={`payroll.${series_index}.expenses.${i}.name`}
                  inputProps={{ defaultValue: expense.name, size: "sm" }}
                />
              </Td>
              <Td>
                <InputForm
                  label=""
                  name={`payroll.${series_index}.expenses.${i}.description`}
                  inputProps={{ defaultValue: expense.description, size: "sm" }}
                  id={expense.field_id}
                />
              </Td>
              <Td>
                <InputForm
                  label=""
                  name={`payroll.${series_index}.expenses.${i}.amount`}
                  registerOptions={{ valueAsNumber: true }}
                  inputProps={{ defaultValue: expense.amount, size: "sm", step: 0.01 }}
                  type="number"
                />
              </Td>
              <Td textAlign="center">
                <Switch
                  size="sm"
                  {...register(`payroll.${series_index}.expenses.${i}.approved` as const)}
                  defaultChecked={expense.approved}
                />
              </Td>
              <Td textAlign="center">
                <ButtonGroup>
                  <Tooltip label="Delete" aria-label="Delete Expense">
                    <IconButton
                      size="sm"
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => remove(i)}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td colSpan={3}>Total</Td>
            <Td colSpan={3}>
              <SeriesExpensesListTotal series_index={series_index} control={control} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end" mt="15px">
        <Button
          colorScheme="green"
          size="md"
          onClick={() => append({ expense_id: 0, user_id: 0, description: "", name: "", amount: 0, approved: true })}
        >
          Add Expense
        </Button>
      </ButtonGroup>
    </>
  );
};

const SeriesExpensesListTotal = ({
  series_index,
  control,
}: {
  series_index: number;
  control: Control<PayrollFormFields>;
}) => {
  const formValues = useWatch({
    name: `payroll.${series_index}.expenses` as "payroll.0.expenses",
    control,
  });

  const total = formValues?.reduce((acc, current) => acc + (current.approved ? current.amount : 0 || 0), 0) || 0;
  return <>{moneyFormat(total)}</>;
};
