import { Center, Flex, Heading, Image, Spinner } from "@chakra-ui/react";
import { useGetLanguages, useGetTranslationsByLanguageId } from "@hooks/api/translations.hook";
import { useAppSelector } from "@store";
import { initTranslations } from "@store/modules/translations";
import { CONSTANTS } from "@utils";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

export const TranslationsMiddleware: FC = ({ children }) => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);
  const languages = useGetLanguages({ active: true });
  const translations = useGetTranslationsByLanguageId(user.language_id || 1);

  const loading = translations.loading || languages.loading;

  useEffect(() => {
    if (languages.data && translations.data) {
      dispatch(
        initTranslations({
          languages: languages.data,
          translations: translations.data,
        })
      );
    }
  }, [languages.data, translations.data]);

  if (loading) {
    return (
      <Center mt="130px">
        <Flex direction="column" align="center">
          <Image src="/logo192.png" h="120px" w="120px" />
          <Heading my="10">{CONSTANTS.TITLE_PAGE}</Heading>
          Loading Translations...
          <Spinner mt="10" size="xl" />
        </Flex>
      </Center>
    );
  }

  return <>{children}</>;
};
