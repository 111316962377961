import { PublishersService } from "@services/publishers.service";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const usePublishersFindAll = () => {
  const key = "/publishers";
  const fetcher = () => PublishersService.findAll();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const usePublishersFindById = (id: string) => {
  const key = `/publishers/${id}`;
  const fetcher = () => (id ? PublishersService.findById(id) : null);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
