import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { WithLoading, WithRoles } from "@components";
import { useInvoiceView } from "@hooks/api/invoice.hook";
import { useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { InvoiceService } from "@services";
import { DateFormat } from "@utils";
import { moneyFormat } from "@utils/money-format";
import { SUPER_ADMIN } from "@utils/permissions";
import { useParams } from "react-router";

export const activityToString = (activity: string) => {
  switch (activity) {
    case "full_tl":
      return "TL";
      break;
    case "major_tlc":
      return "Major TLC";
      break;
    case "minor_tlc":
      return "Minor TLC";
      break;
    case "reedit":
      return "Edit/QC";
      break;
    case "retime":
      return "Time";
      break;
    default:
      return "Other";
      break;
  }
};

export const InvoiceView = () => {
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();

  const { data: invoice, loading } = useInvoiceView(parseInt(id));

  const onClickPDF = async () => {
    const invoiceBlob = await InvoiceService.viewPDF(parseInt(id));
    const fileURL = window.URL.createObjectURL(invoiceBlob);
    const tab = window.open();
    if (tab) {
      tab.location.href = fileURL;
    }
  };

  return (
    <MainLayout>
      <Container maxW="container.lg">
        <WithLoading loading={loading}>
          {invoice && (
            <>
              <Flex mt="10px" direction="row" justifyContent="space-between" alignItems="center">
                <Flex direction="column">
                  <Heading size="lg" p="0" m="0">
                    {invoice.publisher.name} - {invoice.name}
                  </Heading>
                  <Heading size="xs" mt="5px !important" fontWeight="normal">
                    {DateFormat.toDateString(invoice.period_start)} - {DateFormat.toDateString(invoice.period_end)}{" "}
                    (UTC) #{invoice.number.toString().padStart(3, "0")}
                  </Heading>
                </Flex>
                <Flex>
                  <WithRoles roles={[...SUPER_ADMIN, "publisher"]}>
                    <ButtonGroup size="sm">
                      <Button color="red.300" onClick={onClickPDF}>
                        {translations["invoices.view.pdf-button"]}
                      </Button>
                    </ButtonGroup>
                  </WithRoles>
                </Flex>
              </Flex>

              <Divider mt="30px" mb="30px" />

              <Table variant="striped" marginTop="16px !important">
                <Thead>
                  <Tr>
                    <Th>{translations["invoices.view.table.series"]}</Th>
                    <Th>{translations["invoices.view.table.number-episodes"]}</Th>
                    <Th>{translations["invoices.view.table.episodes"]}</Th>
                    <Th>{translations["invoices.view.table.minutes"]}</Th>
                    <Th>{translations["invoices.view.table.activity"]}</Th>
                    <Th>{translations["invoices.view.table.price"]}</Th>
                    <Th w="15%">{translations["invoices.view.table.amount"]}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {invoice.episodes.map((e) => (
                    <Tr key={e.id}>
                      <Td>{e.series_title}</Td>
                      <Td>{e.episodes.length}</Td>
                      <Td>{e.episodes_range}</Td>
                      <Td>{e.minutes}</Td>
                      <Td>{activityToString(e.activity)}</Td>
                      <Td>{moneyFormat(e.price)}</Td>
                      <Td>{moneyFormat(e.amount)}</Td>
                    </Tr>
                  ))}

                  <Tr>
                    <Td colSpan={3}>{translations["invoices.view.table.total"]}</Td>
                    <Td colSpan={3}>
                      {invoice.episodes
                        .map((e) => e.minutes * e.episodes.length)
                        .reduce((curr, prev) => curr + prev, 0)
                        .toFixed(1)}
                    </Td>
                    <Td>{moneyFormat(invoice.total)}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </>
          )}
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
