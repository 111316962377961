import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { WithLoading } from "@components";
import { useDashboardAdminRolesOverview } from "@hooks/api/dashboard.hook";
import { useCurrentLanguage, useTranslations } from "@hooks/useTranslations";
import { CardHeader } from "@pages";
import { makeTitleBySerie } from "@pages/series/series.utils";
import { Serie } from "@services";
import { slugify } from "@utils/slugify";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const EpisodeRoleStatus = ({ status }: { status: { episode_number: number; user_name: string; date: string } }) => {
  const translations = useTranslations();
  return (
    <>
      {translations["dashboard.catalogue-overview.episode"]} {status.episode_number} - {moment(status.date).fromNow()}
    </>
  );
};

export const AdminCataloguePerRoleStatus = () => {
  const language = useCurrentLanguage();
  const translations = useTranslations();
  const { data: seriesRolesOverview, loading: seriesRolesOverviewLoading } = useDashboardAdminRolesOverview();

  return (
    <Box as="section" pt="12" px={{ md: "8" }}>
      <Box rounded={{ md: "lg" }} shadow="dark-lg" pb="25px" x="auto">
        <CardHeader title={translations["dashboard.catalogue-overview"]} />
        <Flex
          overflowX={"auto"}
          x="auto"
          display="block"
          sx={{
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
            },
          }}
        >
          <WithLoading centerH={false} loading={seriesRolesOverviewLoading}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th minWidth="200px" maxWidth="200px" width="200px">
                    {translations["dashboard.catalogue-overview.series"]}
                  </Th>
                  {seriesRolesOverview?.roles.map((r) => (
                    <Th key={r.id}>{r.name}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {seriesRolesOverview?.series.map((s) => (
                  <React.Fragment key={s.title}>
                    <Tr>
                      <Td>
                        <Link to={`/series/${s.id}/${slugify(s.short_title || "")}/episodes`}>
                          <Text color="blue.400">{makeTitleBySerie(s as Serie, language)}</Text>
                        </Link>
                      </Td>
                      {seriesRolesOverview?.roles.map((r) => (
                        <Td key={r.id}>
                          {s.roles_last_status[r.name] && <EpisodeRoleStatus status={s.roles_last_status[r.name]} />}
                        </Td>
                      ))}
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          </WithLoading>
        </Flex>
      </Box>
    </Box>
  );
};
