import { AuthService, CreateTwoStepCodeResponse, LoginServicePayload } from "@services/auth.service";
import useSWR from "swr";
import { HookResponse, makeSwrResponse } from "./utils";

export const useGetLinkQRCode = (payload: LoginServicePayload): HookResponse<CreateTwoStepCodeResponse> => {
  const key = JSON.stringify(payload);
  const fetcher = () => AuthService.createTwoStepCode(payload);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
