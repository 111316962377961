import { UserRoles } from "types/user-roles";
import { UserSubtitleRoles } from "types/user-subtitle-roles";
import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const AuthService = {
  hasPermission: (roles: UserRoles[], role: UserRoles): boolean => {
    return !roles || roles.includes(role);
  },
  hasSubPermission: (need_roles: UserSubtitleRoles[], user_roles: UserSubtitleRoles[]): boolean => {
    return !!user_roles.find((r) => need_roles.includes(r));
  },
  login: async (payload: LoginServicePayload) => {
    const promise = api.post<LoginResponse>("auth/login", payload);
    return handleRequestPromise(promise);
  },
  createTwoStepCode: async (payload: LoginServicePayload) => {
    const promise = api.post<CreateTwoStepCodeResponse>("auth/2fa/create", payload);
    return handleRequestPromise(promise);
  },
  verifyTwoStepCode: async (payload: VerifyTwoStepCodePayload): Promise<VerifyTwoStepCodeResponse> => {
    const promise = api.post<VerifyTwoStepCodeResponse>("auth/2fa/verify", payload);
    return handleRequestPromise(promise);
  },
  refreshToken: async (token: string): Promise<RefreshTokenResponse> => {
    const promise = api.post<RefreshTokenResponse>("auth/refresh-token", null, {
      headers: {
        authorization: token,
      },
    });

    return handleRequestPromise(promise);
  },
};

// export type Role = UserRoles;

export type LoginServicePayload = {
  username: string;
  password: string;
};

export type TwoStepType = "app" | "email";

type LoginResponse = {
  first_login: string;
  two_step_type: TwoStepType;
};

export type CreateTwoStepCodeResponse = {
  otp_auth_url: string;
  two_step_type: TwoStepType;
  key_2fa: string;
};

type VerifyTwoStepCodePayload = {
  code: string;
} & LoginServicePayload;

type VerifyTwoStepCodeResponse = {
  token: string;
  role: UserRoles;
  subtitle_roles: UserSubtitleRoles[];
  language_id: number;
  username: string;
  name: string;
  email: string;
  avatar: string;
  timezone: string;
};

type RefreshTokenResponse = {
  token: string;
};
