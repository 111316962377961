import { SeriesExpensesService } from "@services";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const useSeriesExpensesFindAllBySerieId = (serieId: string) => {
  const key = `/series/expenses/${serieId}`;
  const fetcher = () => (serieId ? SeriesExpensesService.findAllBySerieId(serieId) : null);
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
