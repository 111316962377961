import { ENGLISH_ID } from "@pages/translations/translations.constant";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRoles } from "types/user-roles";
import { UserSubtitleRoles } from "types/user-subtitle-roles";

export type UserState = {
  avatar: string;
  username: string;
  name: string;
  email: string;
  token: string;
  language_id: number;
  timezone: string;
  role: UserRoles | null;
  subtitle_roles: UserSubtitleRoles[] | null;
  logged: boolean;
};

const initialState: UserState = {
  avatar: "",
  username: "",
  name: "",
  email: "",
  language_id: ENGLISH_ID,
  timezone: "UTC",
  token: "",
  role: null,
  subtitle_roles: null,
  logged: false,
};

export type State = typeof initialState & { role: UserRoles };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<State>>) => {
      Object.assign(state, action.payload);
    },
    authenticate: (state, action: PayloadAction<Omit<State, "logged">>) => {
      Object.assign(state, action.payload);
      state.logged = true;
    },
    setTimezone: (state, action: PayloadAction<{ timezone: string }>) => {
      state.timezone = action.payload.timezone;
    },
    unauthenticate: () => {
      return initialState;
    },
  },
});

export const { authenticate, unauthenticate, setUser, setTimezone } = userSlice.actions;

export default userSlice.reducer;
