import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TwoStepType } from "@services";

export enum LoginFormSteps {
  LOGIN = "login",
  REGISTER_TWO_AUTH_CODE = "register-two-auth-code",
  SCAN_QRCODE = "scan-qrcode",
}

export type State = {
  twoStepType: TwoStepType;
  username: string;
  password: string;
  twoAuthCode: LoginFormSteps;
  currentStep: string;
};

const initialState = {
  username: "",
  password: "",
  twoAuthCode: "",
  currentStep: LoginFormSteps.LOGIN,
  twoStepType: "app",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<Partial<State>>) => {
      Object.assign(state, action.payload);
    },
    goToStep: (state, action: PayloadAction<{ step: LoginFormSteps }>) => {
      state.currentStep = action.payload.step;
    },
    resetLogin: () => {
      return initialState;
    },
  },
});

export const { setLogin, goToStep, resetLogin } = loginSlice.actions;

export default loginSlice.reducer;
