export function toFixed(num: number, fixed: number) {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  const m = num.toString().match(re);

  if (!m) {
    console.warn(`Invalid number! ${m} - ${num}`);
    return 0;
  }

  return Number(m[0]);
}
