import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, Container, SerieArtwork, WithLoading, WithRoles } from "@components";
import { useQuery } from "@hooks";
import { useSeriesFindAll } from "@hooks/api/series.hook";
import { useCurrentLanguage, useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { Serie, SeriesFindAllQueryParams, SeriesService } from "@services";
import { CONSTANTS, DateFormat } from "@utils";
import { contrastColor } from "@utils/contrast-color";
import { ADMIN_PM } from "@utils/permissions";
import { slugify } from "@utils/slugify";
import { debounce, groupBy, orderBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { makeTitleBySerie } from "./series.utils";

type TeamBoxType = {
  name: string;
  username: string;
  color: string;
};

const TeamBox = ({ name, username, color }: TeamBoxType) => {
  return (
    <Box
      backgroundColor={color}
      textColor={contrastColor(color)}
      marginTop="2"
      mr="1"
      p="1"
      borderWidth="1px"
      borderRadius="sm"
      textAlign="center"
      minW="60px"
    >
      <Box fontSize="12px">{name}</Box>
      <Box fontSize="13px" fontWeight="bold">
        {username}
      </Box>
    </Box>
  );
};

const AlertMessageComponent = (props: { serie_title: string }) => {
  const translations = useTranslations();
  return (
    <Text>
      {translations["series.delete.message"]}{" "}
      <Text as="span" fontWeight="bold">
        {props.serie_title}
      </Text>
      ?
    </Text>
  );
};

type QueryParams = {
  q: string;
};

type SearchFieldProps = {
  value: string;
  onChange: (value: string) => void;
  loading?: boolean;
};

type RenderListSeriesProps = {
  series: Serie[];
  type: string;
  refetch: () => Promise<boolean>;
  isArchived: boolean;
};

const SearchField = ({ value = "", onChange, loading }: SearchFieldProps) => {
  const translations = useTranslations();
  const [fieldValue, setFieldValue] = useState<string>(value);

  const debouncedFn = useMemo(() => debounce((v) => onChange(v), 300), []);
  const handleChange = (v: string) => {
    setFieldValue(v);
    debouncedFn(v);
  };

  return (
    <Flex flex={1}>
      <InputGroup>
        <Input
          placeholder={translations["series.input.placeholder"]}
          value={fieldValue}
          onChange={(e) => handleChange(e.currentTarget.value)}
          autoFocus={!!fieldValue}
        />
        <InputLeftElement>
          <SearchIcon />
        </InputLeftElement>
        <InputRightElement>{loading && <Spinner />}</InputRightElement>
      </InputGroup>
    </Flex>
  );
};

const RenderListSeries = ({ series, type, refetch, isArchived }: RenderListSeriesProps) => {
  const language = useCurrentLanguage();
  const translations = useTranslations();
  const ref = useRef<ConfirmModalRef>(null);
  const history = useHistory();
  const toast = useToast();
  const handleClickEditSerie = (serie: Serie) => history.push(`/series/${serie.id}/edit`);
  const handleClickDeleteSerie = async (serieId: string) => {
    try {
      await SeriesService.deleteById(serieId);

      toast({
        status: "success",
        description: translations["series.delete.success"],
      });

      refetch();
    } catch (error: any) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  if (!series || series.length === 0) return null;

  return (
    <Box mb="30px">
      <ConfirmModal ref={ref} onConfirm={handleClickDeleteSerie} />
      {!isArchived && (
        <Box mb="2">
          <Heading size="lg" textTransform="capitalize">
            {type === "simulcast" ? translations["series.simulcast"] : translations["series.catalogue"]}
          </Heading>
        </Box>
      )}

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={2}>
        {series.map((serie) => (
          <Flex key={`serie_${serie.id}`} borderWidth="1px" borderRadius="lg" p="2" alignItems="flex-start">
            <SerieArtwork src={serie.image} />
            <Flex flexDirection="column" w="full">
              <Box p="3">
                <Flex fontSize="lg" fontWeight="bold" justifyContent="space-between">
                  <Box>
                    <Link to={`/series/${serie.id}/${slugify(serie.short_title)}/episodes`}>
                      {makeTitleBySerie(serie, language)}
                    </Link>
                  </Box>
                  <WithRoles roles={ADMIN_PM}>
                    <ButtonGroup>
                      <IconButton
                        size="sm"
                        aria-label="Edit"
                        onClick={() => handleClickEditSerie(serie)}
                        icon={<EditIcon />}
                        borderRadius="4"
                      />
                      <IconButton
                        size="sm"
                        color="red.400"
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        borderRadius="4"
                        onClick={() =>
                          ref.current?.open(serie.id, {
                            title: translations["series.delete.title"],
                            message: <AlertMessageComponent serie_title={serie.short_title} />,
                          })
                        }
                      />
                    </ButtonGroup>
                  </WithRoles>
                </Flex>
                {serie.type == "catalogue" && (
                  <Box fontSize="sm">
                    {translations["series.deadline"]} {DateFormat.toCustomDate(serie.deadline, "DD-MM-YYYY, HH:mm")}
                  </Box>
                )}
                {serie.type == "simulcast" && (
                  <Box fontSize="sm">{DateFormat.toCustomDate(serie.air_date, "dddd, HH:mm")}</Box>
                )}
                <Box fontSize="xs">
                  {translations["series.episodes-count"]} {serie.num_episodes}
                </Box>
                <Box>
                  <Flex flexDirection="row">
                    {serie.team.map((member) => (
                      <TeamBox
                        key={member.role.id}
                        name={member.role.short_name}
                        username={member.user.name}
                        color={member.role.color}
                      />
                    ))}
                    {(!serie.team || serie.team.length == 0) && (
                      <Box mt="5px" fontSize="14px" color="gray">
                        {translations["series.team.empty"]}
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  );
};

let oldCatalogue: any[] = [];
let oldSimulcast: any[] = [];
let oldSeries: any[] = [];

const useSerieWithGroup = (params?: SeriesFindAllQueryParams) => {
  const { data, refetch, loading } = useSeriesFindAll(params);
  const fixCategories = data?.map((d) => ({ ...d, type: d.type == "catalog" ? "catalogue" : d.type }));
  const fixTeamOrder = fixCategories?.map((d) => ({ ...d, team: orderBy(d.team, "role.order") })) || oldSeries;
  const { catalogue, simulcast } = data
    ? groupBy(fixTeamOrder, "type")
    : { catalogue: oldCatalogue, simulcast: oldSimulcast };

  oldSimulcast = simulcast || [];
  oldCatalogue = catalogue || [];
  oldSeries = fixTeamOrder || [];

  return {
    series: oldSeries,
    catalogue: oldCatalogue,
    simulcast: oldSimulcast,
    loading,
    refetch,
  };
};

export const Series = () => {
  const translations = useTranslations();
  const { q } = useQuery<QueryParams>();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState(q ? decodeURIComponent(q) : "");
  const match = useRouteMatch();
  const isArchived = match.url.includes("archived");
  const { refetch, loading, catalogue, simulcast, series } = useSerieWithGroup({
    archived: isArchived,
    q: search?.trim(),
  });
  const history = useHistory();

  const handleChangeSearch = (value: string) => {
    setSearch(value);
    setLoadingSearch(true);
  };

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams({ q: encodeURIComponent(search.trim()) }).toString();
      window.history.replaceState(null, CONSTANTS.TITLE_PAGE, `/series?${params}`);
    } else {
      window.history.replaceState(null, CONSTANTS.TITLE_PAGE, `/series`);
    }
  }, [search]);

  useEffect(() => {
    if (!loading) setLoadingSearch(false);
  }, [loading]);

  const showLargeLoading = loading && !loadingSearch;
  const showSmallLoading = loadingSearch && loading;

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.lg" } }}>
        <SearchField value={search} onChange={handleChangeSearch} loading={showSmallLoading} />
        {search && !series?.length && !showSmallLoading && (
          <Flex>
            <Text>
              {translations["series.search.not-found"]} <strong>&quot;{search}&quot;</strong>
            </Text>
          </Flex>
        )}
        <WithLoading loading={showLargeLoading}>
          <Box>
            {isArchived && (
              <>
                <Heading size="lg">{translations["series.archived"]}</Heading>
                <Divider mt="4" mb="8" />
              </>
            )}

            {!isArchived && (
              <WithRoles roles={ADMIN_PM}>
                <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end">
                  <Button colorScheme="teal" size="sm" type="submit" onClick={() => history.push("/series/add")}>
                    {translations["series.button.add"]}
                  </Button>
                </ButtonGroup>
              </WithRoles>
            )}
            {isArchived && (
              <RenderListSeries series={series || []} type="simulcast" refetch={refetch} isArchived={isArchived} />
            )}

            {!isArchived && (
              <>
                <RenderListSeries series={simulcast} type="simulcast" refetch={refetch} isArchived={isArchived} />
                <RenderListSeries series={catalogue} type="catalogue" refetch={refetch} isArchived={isArchived} />
              </>
            )}
          </Box>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
