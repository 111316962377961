import { Button, ButtonGroup, Divider, Flex, Heading, Switch, Text, useToast, VStack } from "@chakra-ui/react";
import {
  Container,
  Form,
  InputForm,
  InputPasswordForm,
  InputSelectForm,
  SelectOptions,
  WithLoading,
} from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePublishersFindAll } from "@hooks/api/publishers.hook";
import { useRolesFindAll } from "@hooks/api/roles.hook";
import { useUsersFindById } from "@hooks/api/users.hook";
import { useTranslations } from "@hooks/useTranslations";
import { MainLayout } from "@layouts";
import { TranslationKeys } from "@services/translations.service";
import { UserCreatePayload, UsersService, UserUpdatePayload } from "@services/users.service";
import { VALIDATORS } from "@utils";
import * as moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

const FORM_LABELS = {
  discord_id: "Discord ID",
  email: "Email",
  name: "Name",
  username: "Username",
  password: "Password",
  timezone: "Timezone",
  permission: "User Type",
  two_step_type: "2-Step Auth. Type",
  publisher: "Publisher",
  submission_notification: "Email Notification",
};

const UPDATE_SCHEMA = Yup.object({
  name: Yup.string().label(FORM_LABELS.name),
  email: Yup.string().email().label(FORM_LABELS.email),
  username: Yup.string().label(FORM_LABELS.username),
  password: Yup.string()
    .nullable()
    .test("is-valid-password", VALIDATORS.PASSWORD.message, (value) =>
      !value ? true : VALIDATORS.PASSWORD.rule.test(value)
    )
    .label(FORM_LABELS.password),
  timezone: Yup.string().label(FORM_LABELS.timezone),
  permission: Yup.string().label(FORM_LABELS.permission),
  publisher_id: Yup.number().when("permission", {
    is: "publisher",
    then: Yup.number().required().label(FORM_LABELS.publisher),
    otherwise: Yup.number().nullable(),
  }),
  submission_notification: Yup.boolean().when("permission", {
    is: "publisher",
    then: Yup.boolean().required().label(FORM_LABELS.submission_notification),
    otherwise: Yup.boolean().nullable(),
  }),
  discord_id: Yup.string().nullable().label(FORM_LABELS.discord_id),
});

const CREATE_SCHEMA = Yup.object({
  name: Yup.string().required().label(FORM_LABELS.name),
  email: Yup.string().email().required().label(FORM_LABELS.email),
  username: Yup.string().required().label(FORM_LABELS.username),
  two_step_type: Yup.string().required().label(FORM_LABELS.two_step_type),
  password: Yup.string()
    .required()
    .test("is-valid-password", VALIDATORS.PASSWORD.message, (value = "") => VALIDATORS.PASSWORD.rule.test(value))
    .label(FORM_LABELS.password),
  timezone: Yup.string().required().label(FORM_LABELS.timezone),
  permission: Yup.string().required().label(FORM_LABELS.permission),
  publisher_id: Yup.number().when("permission", {
    is: "publisher",
    then: Yup.number().required().label(FORM_LABELS.publisher),
  }),
  submission_notification: Yup.boolean().when("permission", {
    is: "publisher",
    then: Yup.boolean().required().label(FORM_LABELS.submission_notification),
    otherwise: Yup.boolean().nullable(),
  }),
  discord_id: Yup.string()
    .nullable()
    .test("number", "Discord Id must be a `number`", (value) => !value || !isNaN(+value))
    .label(FORM_LABELS.discord_id),
});

type FormFields = {
  name: string;
  email: string;
  username: string;
  password: string;
  publisher_id: number;
  timezone: string;
  permission: string;
  submission_notification: boolean;
  discord_id: string;
  two_step_type: string;
};

const PERMISSION_OPTIONS: SelectOptions = [
  { label: "Normal", value: "normal" },
  { label: "Administrator", value: "admin" },
  { label: "Super Administrator", value: "super_admin" },
  { label: "Project Manager", value: "project_manager" },
  { label: "Publisher", value: "publisher" },
];

const TWO_STEP_TYPES_OPTIONS: SelectOptions = [
  { label: "App", value: "app" },
  { label: "Email", value: "email" },
];

const DEFAULT_VALUES: FormFields = {
  email: "",
  name: "",
  username: "",
  permission: PERMISSION_OPTIONS[0].value,
  timezone: "UTC",
  discord_id: "",
  submission_notification: false,
  password: "",
  two_step_type: TWO_STEP_TYPES_OPTIONS[0].value,
  publisher_id: 0,
};

const InputSelectPublisher = ({ PUBLISHERS_SELECT }: { PUBLISHERS_SELECT: SelectOptions }) => {
  const { watch } = useFormContext<FormFields>();

  const permission = watch("permission");

  if (permission != "publisher") {
    return null;
  }

  return (
    <>
      <InputSelectForm
        label={FORM_LABELS.publisher}
        options={PUBLISHERS_SELECT}
        name="publisher_id"
        registerOptions={{ valueAsNumber: true }}
      />
      <InputSwitchForm name="submission_notification" label={FORM_LABELS.submission_notification} />
    </>
  );
};

export const UsersForm = () => {
  const translations = useTranslations();
  const [fieldRoles, setFieldRoles] = useState<number[]>([]);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();
  const { data: user, loading: userLoading } = useUsersFindById(id);
  const { data: roles, loading: rolesLoading } = useRolesFindAll(true);
  const { data: publishersData, loading: publishersLoading } = usePublishersFindAll();
  const SCHEMA = id ? UPDATE_SCHEMA : CREATE_SCHEMA;

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  });

  const { isSubmitting } = methods.formState;
  const handleChangeRoles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = +e.target.value;
    const rolesCtx = [...fieldRoles];
    const roleIdx = rolesCtx.findIndex((f) => f === value);

    if (roleIdx > -1) {
      rolesCtx.splice(roleIdx, 1);
    } else {
      rolesCtx.push(+value);
    }

    setFieldRoles(rolesCtx);
  };

  const TIMEZONE_SELECT = moment.tz.names().map((t) => ({ label: t, value: t }));

  const PUBLISHERS_SELECT: SelectOptions =
    publishersData?.map((p) => ({ label: p.name, value: p.id.toString() })) ?? [];

  const onSubmit = async (values: FormFields) => {
    try {
      const payload = { ...values, roles: fieldRoles };
      if (id) {
        await UsersService.updateById(id, payload as UserUpdatePayload);

        methods.reset(values);

        toast({
          description: "User updated successfully",
          status: "success",
        });
      } else {
        await UsersService.create(payload as UserCreatePayload);

        toast({
          description: "User created successfully",
          status: "success",
        });
      }

      history.push("/users");
    } catch (error: any) {
      toast({
        description: error.message,
        status: "error",
      });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (user) {
      const { roles, ...rest } = user;

      setFieldRoles(roles?.map((r) => r.id) || []);
      methods.reset({
        ...rest,
      });
    }
  }, [user]);

  const isLoading = userLoading || rolesLoading || publishersLoading;

  const keyTranslation: TranslationKeys = id ? "users.form.edit.title" : "users.form.add.title";

  return (
    <MainLayout>
      <Container>
        <WithLoading loading={isLoading}>
          <Form methods={methods} onSubmit={onSubmit}>
            <VStack spacing="4">
              <Heading size="lg" textAlign="left" width="100%">
                {translations[keyTranslation]}
              </Heading>
              <Divider />
              <InputForm label={translations["users.form.name.label"]} inputProps={{ autoFocus: true }} name="name" />
              <InputForm label={translations["users.form.email.label"]} name="email" />
              <InputForm label={translations["user.form.username.label"]} name="username" />
              <InputSelectForm
                label={translations["user.form.timezone.label"]}
                options={TIMEZONE_SELECT}
                name="timezone"
              />
              <InputSelectForm
                label={translations["user.form.two-step-auth-type.label"]}
                options={TWO_STEP_TYPES_OPTIONS}
                name="two_step_type"
              />
              <InputPasswordForm label={translations["user.form.password.label"]} name="password" />
              <InputForm label={translations["user.form.discord.label"]} name="discord_id" inputMode="numeric" />
              <Divider />
              <InputSelectForm
                label={translations["user.form.permission.label"]}
                options={PERMISSION_OPTIONS}
                name="permission"
              />
              <InputSelectPublisher PUBLISHERS_SELECT={PUBLISHERS_SELECT} />
              <Flex w="full" flex="1" justifyContent="flex-start" direction="column">
                <Text w="full" textAlign="left" mb="4" fontSize="22px" fontWeight="bold">
                  {translations["user.form.roles.label"]}
                </Text>
                <Flex flex={1} direction="column" mb="6">
                  {roles?.map((role) => {
                    const checked = fieldRoles.includes(role.id);

                    return (
                      <Flex key={role.id} flex={1} direction="row" justify="space-between" mb="2">
                        <Text>{role.name}</Text>
                        <Switch onChange={handleChangeRoles} value={role.id} isChecked={checked} />
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
              <ButtonGroup variant="outline" width="full" justifyContent="space-between">
                <Button colorScheme="red" size="md" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button colorScheme="teal" size="md" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </ButtonGroup>
            </VStack>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
