import { EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  Divider,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { InputSelectForm, SelectOptions } from "@components";
import { User } from "@services";
import { moneyFormat } from "@utils/money-format";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { PayrollFormFields } from "./new";
import { SeriesExpensesList } from "./seriesexpenseslist";

export type SeriesPayrollProps = {
  series_index: number;
  group: PayrollFormFields["payroll"][number];
  users: User[];
};

type EpisodePayrollProps = {
  series_index: number;
  episode_index: number;
  episode: PayrollFormFields["payroll"][number]["episodes"][number];
  publisher?: boolean;
  users: User[];
};

type EpisodeTeamPayrollProps = {
  series_index: number;
  episode_index: number;
  episodeteam_index: number;
  team: PayrollFormFields["payroll"][number]["episodes"][number]["team"][number];
  users: User[];
};

export const SeriesPayroll = ({ series_index, group, users }: SeriesPayrollProps) => {
  return (
    <>
      <Heading size="md">{group.serie.title}</Heading>
      <Table variant="simple" marginTop="16px !important">
        <Thead>
          <Tr>
            <Th width="15%">Episode #</Th>
            <Th>User</Th>
            <Th>Role</Th>
            <Th w="15%">Amount</Th>
            <Th w="3%">Approved</Th>
            <Th w="3%">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {group.episodes.map((episode, episodes_index) => (
            <EpisodePayroll
              series_index={series_index}
              episode_index={episodes_index}
              key={episode.number}
              episode={episode}
              users={users}
            />
          ))}

          <Tr>
            <Td colSpan={3}>Total</Td>
            <Td colSpan={3} textAlign="left">
              <SeriesPayrollTotal series_index={series_index} />
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Heading size="md" mt="30px">
        {group.serie.title} - Expenses
      </Heading>
      <SeriesExpensesList series_index={series_index} users={users} />

      <Divider mt="30px" mb="30px" />
    </>
  );
};

const SeriesPayrollTotal = ({ series_index }: { series_index: number }) => {
  const { control } = useFormContext<PayrollFormFields>();

  const formValues = useWatch({
    name: `payroll.${series_index}.episodes` as "payroll.0.episodes",
    control,
  });

  const sumTeam = (team: PayrollFormFields["payroll"][number]["episodes"][number]["team"]) =>
    team.reduce((acc, current) => acc + (current.approved ? current.rate : 0 || 0), 0);

  const total = formValues?.reduce((acc, current) => acc + sumTeam(current.team), 0) || 0;
  return <>{moneyFormat(total)}</>;
};

const EpisodePayroll = ({ series_index, episode_index, episode, users }: EpisodePayrollProps) => {
  return (
    <>
      {episode.team.map((team, episodesteam_index) => (
        <Tr key={team.id}>
          <>
            {episodesteam_index == 0 && <Td rowSpan={episode.team.length}>{episode.number}</Td>}
            <EpisodeTeamPayroll
              episodeteam_index={episodesteam_index}
              episode_index={episode_index}
              series_index={series_index}
              team={team}
              users={users}
            />
          </>
        </Tr>
      ))}
    </>
  );
};

const EpisodeTeamPayroll = ({
  series_index,
  episode_index,
  episodeteam_index,
  team,
  users,
}: EpisodeTeamPayrollProps) => {
  const { register } = useFormContext<PayrollFormFields>();
  const [edit, setEdit] = useState(false);

  if (edit) {
    return (
      <EpisodeTeamPayrollEditable
        series_index={series_index}
        episode_index={episode_index}
        episodeteam_index={episodeteam_index}
        team={team}
        users={users}
      />
    );
  }

  return (
    <>
      <Td>{team.user_username}</Td>
      <Td>{team.role_name}</Td>
      <Td>{moneyFormat(team.rate)}</Td>
      <Td textAlign="center">
        <Switch
          size="sm"
          {...register(`payroll.${series_index}.episodes.${episode_index}.team.${episodeteam_index}.approved` as const)}
          defaultChecked={team.approved}
        />
      </Td>
      <Td textAlign="center">
        <ButtonGroup>
          <Tooltip label="Edit" aria-label="Edit Serie">
            <IconButton size="sm" aria-label="Edit" icon={<EditIcon />} onClick={() => setEdit(true)} />
          </Tooltip>
        </ButtonGroup>
      </Td>
    </>
  );
};

const EpisodeTeamPayrollEditable = ({
  series_index,
  episode_index,
  episodeteam_index,
  team,
  users,
}: EpisodeTeamPayrollProps) => {
  const { register } = useFormContext<PayrollFormFields>();
  const usersSelectOptionsByRole: SelectOptions = users
    .filter((u) => u.roles?.some((r) => r.id === team.role_id))
    .map((u) => ({
      label: u.name,
      value: u.id.toString(),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <Td>
        <InputSelectForm
          label=""
          options={usersSelectOptionsByRole}
          name={`payroll.${series_index}.episodes.${episode_index}.team.${episodeteam_index}.user_id`}
          selectProps={{ defaultValue: team.user_id, size: "sm" }}
        />
      </Td>
      <Td>{team.role_name}</Td>
      <Td>
        <InputGroup size="sm">
          <InputLeftElement color="gray.300" fontSize="sm" children="$" />
          <Input
            placeholder="Enter amount"
            {...register(`payroll.${series_index}.episodes.${episode_index}.team.${episodeteam_index}.rate` as const, {
              valueAsNumber: true,
            })}
            type="number"
            size="sm"
            step={0.01}
            defaultValue={team.rate}
          />
        </InputGroup>
      </Td>
      <Td textAlign="center">
        <Switch
          size="sm"
          {...register(`payroll.${series_index}.episodes.${episode_index}.team.${episodeteam_index}.approved` as const)}
          defaultChecked={team.approved}
        />
      </Td>
      <Td textAlign="center">
        <ButtonGroup>
          <Tooltip label="Edit" aria-label="Edit Serie">
            <IconButton size="sm" aria-label="Edit" icon={<EditIcon />} disabled />
          </Tooltip>
        </ButtonGroup>
      </Td>
    </>
  );
};
