type Env = {
  api: string;
  production: boolean;
};

const hasInvalidEnvVariable = () => {
  const envKeys = ["REACT_APP_API_URL"];

  return envKeys.find((e) => !process.env[e]);
};

const invalid = hasInvalidEnvVariable();

if (invalid) {
  const message = `Please configure ${invalid} env variable`;
  throw new Error(message);
}

export const env: Env = {
  production: process.env.NODE_ENV === "production",
  api: process.env.REACT_APP_API_URL,
};
