import { DashboardService } from "@services/dashboard.service";
import useSWR from "swr";
import { makeSwrResponse } from "./utils";

export const useDashboardReleases = () => {
  const key = `/dashboard/releases`;
  const fetcher = () => DashboardService.releases();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useDashboardAdminSeriesEpisodes = () => {
  const key = `/dashboard/adminSeriesEpisodes`;
  const fetcher = () => DashboardService.adminSeriesEpisodes();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useDashboardAdminRolesOverview = () => {
  const key = `/dashboard/adminSeriesRoleOverview`;
  const fetcher = () => DashboardService.adminSeriesRoleOverview();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useDashboardAdminStaffEfficiency = () => {
  const key = `/dashboard/adminStaffEfficiency`;
  const fetcher = () => DashboardService.adminStaffEfficiency();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};

export const useDashboardNextSimulcastCatalogue = () => {
  const key = `/dashboard/usersNextEpisodes`;
  const fetcher = () => DashboardService.usersNextEpisodes();
  const swr = useSWR(key, fetcher);

  return makeSwrResponse(swr);
};
