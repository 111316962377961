import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  InputLeftElement,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  Container,
  FileUploadFrom,
  Form,
  InputForm,
  InputSelectForm,
  SelectOptions,
  WithLoading,
  WithRoles,
} from "@components";
import { InputSwitchForm } from "@components/form/input-switch-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePublishersFindAll, usePublishersFindById } from "@hooks/api/publishers.hook";
import { useRolesFindAll } from "@hooks/api/roles.hook";
import { useSeriesFindById } from "@hooks/api/series.hook";
import { useUsersFindAll } from "@hooks/api/users.hook";
import { MainLayout } from "@layouts";
import { CreateSeriePayload, SeriesService, Team } from "@services";
import { Role } from "@services/roles.service";
import { DateFormat } from "@utils";
import { ADMIN_ROLES } from "@utils/permissions";
import { useEffect, useState } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

const SELECT_OPTIONS: SelectOptions = [
  { label: "Simulcast", value: "simulcast" },
  { label: "Catalogue", value: "catalog" },
];

const FORM_LABELS = {
  short_title: "Short Title",
  title: "Long Title",
  title_jp: "Japanese Title",
  title_ch: "Chinese Title",
  title_en: "English Title",
  type: "Type",
  num_episodes: "Number of episodes",
  deadline: "Deadline (UTC)",
  air_date: "Air Date (UTC)",
  sftp_user: "SFTP User",
  sftp_season_folder: "SFTP Season Folder",
  discord_channel: "Discord Channel",
  sheet_link: "Consistency Sheet Link",
  subtitle_filename: "Subtitle Filename",
  episode_duration: "Episode duration (in seconds)",
  artwork: "Artwork",
  publisher: "Name",
  publisher_rate: "TL Rate",
  publisher_rate_major_tlc: "Major TLC Rate",
  publisher_rate_minor_tlc: "Minor TLC Rate",
  publisher_rate_reedit: "Edit/QC Rate",
  publisher_rate_retime: "Time Rate",
  archived: "Archived",
  target_submission_date: "Target Submission Date (UTC)",
};

const SCHEMA = (isEdit: boolean) =>
  Yup.object({
    short_title: isEdit
      ? Yup.string()
      : Yup.string()
          .required()
          .matches(/^[a-zA-Z0-9 -]*$/, "Must contains only characters, numbers and spaces")
          .label(FORM_LABELS.short_title),
    title: Yup.string().required().label(FORM_LABELS.title),
    title_jp: Yup.string().label(FORM_LABELS.title_jp),
    title_ch: Yup.string().label(FORM_LABELS.title_ch),
    title_en: Yup.string().label(FORM_LABELS.title_en),
    type: Yup.mixed().oneOf(["simulcast", "catalog"]).required().label(FORM_LABELS.type),
    num_episodes: Yup.number().required().label(FORM_LABELS.num_episodes),
    deadline: Yup.string().when("type", { is: "catalog", then: Yup.string().required().label(FORM_LABELS.deadline) }),
    air_date: Yup.string().when("type", { is: "simulcast", then: Yup.string().required().label(FORM_LABELS.air_date) }),
    target_submission_date: Yup.string().nullable().label(FORM_LABELS.target_submission_date),
    sftp_user: Yup.string().required().label(FORM_LABELS.sftp_user),
    sftp_season_folder: Yup.string().label(FORM_LABELS.sftp_season_folder),
    discord_channel: Yup.string().required().label(FORM_LABELS.discord_channel),
    sheet_link: Yup.string().label(FORM_LABELS.sheet_link),
    subtitle_filename: Yup.string().required().label(FORM_LABELS.subtitle_filename),
    episode_duration: Yup.number().required().label(FORM_LABELS.episode_duration),
    artwork: Yup.mixed().label(FORM_LABELS.artwork),
    publisher: Yup.number().label(FORM_LABELS.publisher),
    publisher_rate: Yup.number().required().label(FORM_LABELS.publisher_rate),
    publisher_rate_major_tlc: Yup.number().required().label(FORM_LABELS.publisher_rate_major_tlc),
    publisher_rate_minor_tlc: Yup.number().required().label(FORM_LABELS.publisher_rate_minor_tlc),
    publisher_rate_reedit: Yup.number().required().label(FORM_LABELS.publisher_rate_reedit),
    publisher_rate_retime: Yup.number().required().label(FORM_LABELS.publisher_rate_retime),
    publisher_rate_options: Yup.object({
      full_tl: Yup.boolean(),
      major_tlc: Yup.boolean(),
      minor_tlc: Yup.boolean(),
      reedit: Yup.boolean(),
      retime: Yup.boolean(),
    }).required(),
    archived: Yup.boolean().label(FORM_LABELS.archived),
  });

type FormFields = {
  short_title: string;
  title: string;
  title_jp: string;
  title_ch: string;
  title_en: string;
  type: string;
  num_episodes: number;
  deadline: string;
  air_date: string;
  sftp_user: string;
  sftp_season_folder: string;
  discord_channel: string;
  sheet_link: string;
  subtitle_filename: string;
  episode_duration: number;
  artwork: string;
  publisher_rate: number;
  publisher_rate_major_tlc: number;
  publisher_rate_minor_tlc: number;
  publisher_rate_reedit: number;
  publisher_rate_retime: number;
  publisher_rate_options: {
    full_tl: boolean;
    major_tlc: boolean;
    minor_tlc: boolean;
    reedit: boolean;
    retime: boolean;
  };
  archived?: boolean;
  team: Array<{
    user_id: number;
    rate: number;
    role_id: number;
    enabled: boolean;
  }>;
};

const DEFAULT_VALUES = {
  short_title: "",
  title: "",
  title_jp: "",
  title_ch: "",
  title_en: "",
  type: "simulcast",
  num_episodes: 12,
  deadline: DateFormat.toDateForm(),
  air_date: DateFormat.toDateForm(),
  target_submission_date: DateFormat.toDateForm(),
  sftp_user: "",
  sftp_season_folder: "",
  discord_channel: "",
  sheet_link: "",
  subtitle_filename: "",
  episode_duration: 60 * 24,
  team: [],
  artwork: "" as any,
  publisher_rate: 0,
  publisher_rate_major_tlc: 0,
  publisher_rate_minor_tlc: 0,
  publisher_rate_reedit: 0,
  publisher_rate_retime: 0,
  publisher_rate_options: {
    full_tl: false,
    major_tlc: false,
    minor_tlc: false,
    reedit: false,
    retime: false,
  },
  archived: false,
};

type TeamUser = {
  index: number;
  role: Role;
  users: SelectOptions;
  team?: Team;
};

const TeamUser = ({ role, users, team }: TeamUser) => {
  const [isOpen, setToggle] = useState(!!team);
  const { setValue } = useFormContext();

  const onToggle = () => {
    setToggle((prev) => !prev);
  };

  useEffect(() => {
    setValue(`team_${role.id}.role_id`, role.id);
  }, []);

  useEffect(() => {
    setToggle(!!team);
  }, [team]);

  useEffect(() => {
    setValue(`team_${role.id}.enabled`, isOpen, { shouldDirty: true });
  }, [isOpen]);

  return (
    <Box mb="4">
      <FormControl display="flex" alignItems="baseline" justifyContent="space-between">
        <FormLabel mb="2">
          <Text fontSize="18px" fontWeight="bold">
            {role.name}
          </Text>
        </FormLabel>
        <Switch size="md" isChecked={isOpen} onChange={onToggle} />
      </FormControl>
      <Divider />
      <Collapse in={isOpen} animateOpacity>
        <VStack>
          <InputSelectForm
            label=""
            options={users}
            placeholder="Select user"
            selectProps={{ isRequired: isOpen }}
            name={`team_${role.id}.user_id`}
          />
          <InputForm
            label=""
            type="number"
            placeholder="Enter amount"
            inputProps={{ isRequired: isOpen, step: 0.01 }}
            name={`team_${role.id}.rate` as const}
            leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
          />
        </VStack>
      </Collapse>
    </Box>
  );
};

const PublisherRate = ({
  label,
  name,
  checkName,
  isDisabled,
}: {
  label: string;
  name: string;
  checkName: string;
  isDisabled: boolean;
}) => {
  const { control } = useFormContext();
  const checked = useWatch({ control, name: checkName });

  return (
    <>
      <InputSwitchForm name={checkName} label={label} switchProps={{ isDisabled }} />
      {checked && (
        <WithRoles roles={ADMIN_ROLES}>
          <InputForm
            label=""
            inputProps={{ step: 0.01 }}
            name={name}
            type="number"
            leftElement={<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />}
          />
        </WithRoles>
      )}
    </>
  );
};

const PublisherRates = () => {
  const { control, getValues, setValue } = useFormContext();
  const publisher_id = useWatch({ control, name: "publisher_id" });

  const { data: publisherData, loading: publisherLoading } = usePublishersFindById(publisher_id);

  const options = useWatch({
    name: `publisher_rate_options`,
    control,
  });

  useEffect(() => {
    if (publisherData) {
      const values = getValues();
      if (!values.publisher_rate || values.publisher_rate == 0) setValue("publisher_rate", publisherData.rate || 0);
      if (!values.publisher_rate_major_tlc || values.publisher_rate_major_tlc == 0)
        setValue("publisher_rate_major_tlc", publisherData.rate_major_tlc || 0);
      if (!values.publisher_rate_minor_tlc || values.publisher_rate_minor_tlc == 0)
        setValue("publisher_rate_minor_tlc", publisherData.rate_minor_tlc || 0);
      if (!values.publisher_rate_reedit || values.publisher_rate_reedit == 0)
        setValue("publisher_rate_reedit", publisherData.rate_reedit || 0);
      if (!values.publisher_rate_retime || values.publisher_rate_retime == 0)
        setValue("publisher_rate_retime", publisherData.rate_retime || 0);
    }
  }, [publisherData]);

  if (!publisherData) {
    return <Text>Select Publisher First...</Text>;
  }

  if (publisherLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      <PublisherRate
        checkName="publisher_rate_options.full_tl"
        isDisabled={options.major_tlc || options.minor_tlc || options.reedit || options.retime}
        label={FORM_LABELS.publisher_rate}
        name="publisher_rate"
      />
      <PublisherRate
        checkName="publisher_rate_options.major_tlc"
        isDisabled={options.full_tl || options.minor_tlc}
        label={FORM_LABELS.publisher_rate_major_tlc}
        name="publisher_rate_major_tlc"
      />
      <PublisherRate
        checkName="publisher_rate_options.minor_tlc"
        isDisabled={options.full_tl || options.major_tlc}
        label={FORM_LABELS.publisher_rate_minor_tlc}
        name="publisher_rate_minor_tlc"
      />
      <PublisherRate
        checkName="publisher_rate_options.reedit"
        isDisabled={options.full_tl}
        label={FORM_LABELS.publisher_rate_reedit}
        name="publisher_rate_reedit"
      />
      <PublisherRate
        checkName="publisher_rate_options.retime"
        isDisabled={options.full_tl}
        label={FORM_LABELS.publisher_rate_retime}
        name="publisher_rate_retime"
      />
    </>
  );
};

export const Serie = () => {
  const toast = useToast();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: seriesData, loading: seriesLoading, refetch } = useSeriesFindById(id);
  const { data: usersData, loading: usersLoading } = useUsersFindAll();
  const { data: rolesData, loading: rolesLoading } = useRolesFindAll(true);
  const { data: publishersData, loading: publishersLoading } = usePublishersFindAll();
  const isEdit = id ? true : false;

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA(isEdit)),
  });

  console.log(methods.formState.errors);

  const { isSubmitting } = methods.formState;

  const onSubmit = async (values: FormFields) => {
    // get team key object and transform in array
    const team = Object.keys(values)
      .filter((v) => v.startsWith("team_"))
      .map((key) => {
        const v = values as any;

        return v[key];
      });

    const payload: CreateSeriePayload = {
      ...values,
      publisher_rate_checked: !!values.publisher_rate_options.full_tl,
      publisher_rate_major_tlc_checked: !!values.publisher_rate_options.major_tlc,
      publisher_rate_minor_tlc_checked: !!values.publisher_rate_options.minor_tlc,
      publisher_rate_reedit_checked: !!values.publisher_rate_options.reedit,
      publisher_rate_retime_checked: !!values.publisher_rate_options.retime,
      publisher_rate: values.publisher_rate_options.full_tl ? values.publisher_rate : 0,
      publisher_rate_major_tlc: values.publisher_rate_options.major_tlc ? values.publisher_rate_major_tlc : 0,
      publisher_rate_minor_tlc: values.publisher_rate_options.minor_tlc ? values.publisher_rate_minor_tlc : 0,
      publisher_rate_reedit: values.publisher_rate_options.reedit ? values.publisher_rate_reedit : 0,
      publisher_rate_retime: values.publisher_rate_options.retime ? values.publisher_rate_retime : 0,
      artwork: values.artwork as any,
      team: team,
    };

    try {
      if (id) {
        await SeriesService.updateById(id, payload as any);
      } else {
        const createData = {
          ...payload,
          team: team.filter((t) => t.enabled),
        };

        await SeriesService.create(createData as any);
      }

      const message = `Series ${id ? "updated" : "created"} successfully`;

      toast({
        status: "success",
        description: message,
      });

      history.push("/series");
    } catch (error: any) {
      toast({
        status: "error",
        description: error.message,
      });
    }

    await refetch();
  };

  useEffect(() => {
    if (seriesData && rolesData) {
      const { team, ...rest } = seriesData;

      const payloadToObject = (t: any, role: Role) => ({
        id: t?.id || undefined,
        user_id: t?.user_id || "",
        role_id: role.id,
        rate: t?.rate || role.rate,
        enabled: !!t?.id,
      });

      const teamMembers = rolesData.reduce((prev, curr) => {
        const key = `team_${curr.id}`;
        const teamRole = team.find((t) => t.role_id === curr.id);

        prev[key] = payloadToObject(teamRole, curr);

        return prev;
      }, {} as any);

      methods.reset({
        ...rest,
        ...teamMembers,
        publisher_rate_options: {
          full_tl: seriesData.publisher_rate_checked,
          major_tlc: seriesData.publisher_rate_major_tlc_checked,
          minor_tlc: seriesData.publisher_rate_minor_tlc_checked,
          reedit: seriesData.publisher_rate_reedit_checked,
          retime: seriesData.publisher_rate_retime_checked,
        },
        publisher_rate: seriesData.publisher_rate ? seriesData.publisher_rate : 0,
        publisher_rate_major_tlc: seriesData.publisher_rate_major_tlc ? seriesData.publisher_rate_major_tlc : 0,
        publisher_rate_minor_tlc: seriesData.publisher_rate_minor_tlc ? seriesData.publisher_rate_minor_tlc : 0,
        publisher_rate_reedit: seriesData.publisher_rate_reedit ? seriesData.publisher_rate_reedit : 0,
        publisher_rate_retime: seriesData.publisher_rate_retime ? seriesData.publisher_rate_retime : 0,
        artwork: "",
        deadline: DateFormat.toDateForm(seriesData.deadline || DEFAULT_VALUES.deadline),
        air_date: DateFormat.toDateForm(seriesData.air_date || DEFAULT_VALUES.air_date),
        target_submission_date: seriesData.target_submission_date
          ? DateFormat.toDateForm(seriesData.target_submission_date)
          : "",
      });
    }
  }, [seriesData, rolesData]);

  const handleCancel = () => {
    history.goBack();
  };

  const type = methods.watch("type");
  const title = id ? "Edit Series" : "Add Series";
  const loading = rolesLoading || usersLoading || seriesLoading || publishersLoading;
  const PUBLISHER_SELECT: SelectOptions = publishersData?.map((u) => ({ label: u.name, value: u.id.toString() })) ?? [];

  return (
    <MainLayout>
      <Container>
        <WithLoading loading={loading}>
          <Form methods={methods} onSubmit={onSubmit}>
            <VStack spacing="4">
              <Heading textAlign="left" fontWeight="normal" size="lg" width="full">
                {title}
              </Heading>
              <Divider />
              <InputForm label={FORM_LABELS.short_title} name="short_title" inputProps={{ autoFocus: true }} />
              <InputForm label={FORM_LABELS.title} name="title" isRequired inputProps={{ autoFocus: true }} />
              <InputForm label={FORM_LABELS.title_en} name="title_en" isRequired />
              <InputForm label={FORM_LABELS.title_jp} name="title_jp" inputProps={{ autoFocus: true }} />
              <InputForm label={FORM_LABELS.title_ch} name="title_ch" inputProps={{ autoFocus: true }} />
              {isEdit && <InputSwitchForm name="archived" label={FORM_LABELS.archived} />}
              <InputSelectForm
                name="type"
                label="Type"
                options={SELECT_OPTIONS}
                formControlProps={{ isRequired: true }}
              />
              <InputForm
                type="number"
                inputProps={{ min: 1 }}
                isRequired
                label={FORM_LABELS.num_episodes}
                name="num_episodes"
              />
              <InputForm
                type="datetime-local"
                label={FORM_LABELS.target_submission_date}
                name="target_submission_date"
              />
              {type === "catalog" && <InputForm type="datetime-local" label={FORM_LABELS.deadline} name="deadline" />}
              {type === "simulcast" && <InputForm type="datetime-local" label={FORM_LABELS.air_date} name="air_date" />}
              <InputForm label={FORM_LABELS.sftp_user} name="sftp_user" isRequired />
              <InputForm label={FORM_LABELS.sftp_season_folder} name="sftp_season_folder" />
              <InputForm label={FORM_LABELS.discord_channel} name="discord_channel" isRequired />
              <InputForm label={FORM_LABELS.sheet_link} name="sheet_link" />
              <InputForm label={FORM_LABELS.subtitle_filename} name="subtitle_filename" />
              <InputForm label={FORM_LABELS.episode_duration} isRequired name="episode_duration" />
              <FileUploadFrom
                acceptedFileTypes=".png,.jpg"
                label={FORM_LABELS.artwork}
                name="artwork"
                placeholder="Image File"
                defaultFilename={seriesData?.artwork?.split("/")?.pop()}
                helperMessage="Suggested size: 110x165"
              />

              <Divider />
              <Heading w="full" size="lg" textAlign="left">
                Publisher
              </Heading>

              <InputSelectForm
                label={FORM_LABELS.publisher}
                options={PUBLISHER_SELECT}
                placeholder="Select Publisher"
                selectProps={{ isRequired: true }}
                name="publisher_id"
              />

              <PublisherRates />

              <Divider />
              <Heading w="full" size="lg" textAlign="left">
                Team
              </Heading>
              <Flex w="full" flexDirection="column">
                {rolesData?.map((role, idx) => {
                  const teamSerie = seriesData?.team.find((t) => t.role_id === role.id);
                  const usersByRole: SelectOptions =
                    usersData
                      ?.filter((u) => u.roles?.some((r) => r.id === role.id))
                      .map((u) => ({
                        label: u.name,
                        value: u.id.toString(),
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

                  return <TeamUser key={role.id} role={role} team={teamSerie} index={idx} users={usersByRole} />;
                })}
              </Flex>

              <ButtonGroup variant="outline" width="full" justifyContent="space-between">
                <Button colorScheme="red" size="md" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button colorScheme="teal" size="md" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </ButtonGroup>
            </VStack>
          </Form>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
