import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, IconButton, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { CONSTANTS } from "@utils";
import React from "react";
import { Link } from "react-router-dom";
import { DesktopMenu, MobileMenu } from "./menu";
import { UserMenu } from "./user";

export const MainLayout: React.FC = ({ children }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={onToggle}
          />
          <VStack my="2" alignItems="flex-start">
            <Heading as={Link} to="/" size="lg" textAlign={{ base: "center", md: "left" }}>
              {CONSTANTS.PROJECT_NAME}
            </Heading>
            <DesktopMenu />
          </VStack>
          <UserMenu />
        </Flex>

        {isOpen && <MobileMenu onToggle={onToggle} />}
      </Box>

      <Box p={4}>{children}</Box>
    </>
  );
};
