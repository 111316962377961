import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SWRConfig, SWRConfiguration } from "swr";
import { Routes } from "./routes";
import { persistor, store } from "./store";
import { Theme } from "./theme";
import { TranslationsMiddleware } from "./translations";

const swrConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
};

export const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SWRConfig value={swrConfig}>
          <Theme>
            <TranslationsMiddleware>
              <Routes />
            </TranslationsMiddleware>
          </Theme>
        </SWRConfig>
      </PersistGate>
    </Provider>
  );
};
