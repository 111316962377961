import { AuthService } from "@services";
import { useAppSelector } from "@store";
import { UserSubtitleRoles } from "types/user-subtitle-roles";

export type WithSubRolesProps = {
  roles: UserSubtitleRoles[];
};

export const WithSubRoles: React.FC<WithSubRolesProps> = ({ children, roles }) => {
  const user_roles = useAppSelector((state) => state.user.subtitle_roles);

  if (!user_roles) return null;
  if (!AuthService.hasSubPermission(roles, user_roles)) return null;

  return <>{children}</>;
};
