import { Role, User } from "@services";
import { wait } from "@utils";
import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

const prepareFormData = (serie: CreateSeriePayload | UpdateSeriePayload): FormData => {
  const formData = new FormData();

  const { artwork, team, ...rest } = serie;

  for (const [key, value] of Object.entries(rest)) {
    formData.append(key, value);
  }

  if (typeof artwork !== "string") {
    formData.append("artwork", artwork);
  }

  formData.append("team", JSON.stringify(team));

  return formData;
};

export const SeriesService = {
  findAll: async (params?: SeriesFindAllQueryParams) => {
    await wait(1000);
    const promise = api.get<Serie[]>("series", { params });
    return handleRequestPromise(promise);
  },
  findById: async (id: string) => {
    const promise = api.get<Serie>(`series/${id}`);
    return handleRequestPromise(promise);
  },
  deleteById: async (id: string) => {
    const promise = api.delete<boolean>(`series/${id}`);
    return handleRequestPromise(promise);
  },
  submitAll: async (id: string) => {
    const promise = api.post<boolean>(`series/submit/${id}`);
    return handleRequestPromise(promise);
  },
  refreshSFTP: async (id: string) => {
    const promise = api.get<boolean>(`series/refresh/${id}`);
    return handleRequestPromise(promise);
  },
  create: async (serie: CreateSeriePayload) => {
    const formData = prepareFormData(serie);

    const promise = api.post<Serie>(`series`, formData, {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    });

    return handleRequestPromise(promise);
  },
  updateById: async (id: string, serie: UpdateSeriePayload) => {
    const formData = prepareFormData(serie);
    const promise = api.patch<boolean>(`series/${id}`, formData, {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    });
    return handleRequestPromise(promise);
  },
};

export type SeriesFindAllQueryParams = {
  archived?: boolean;
  q?: string;
};

export type CreateSeriePayload = {
  artwork: File;
  team: Omit<Team, "id">[];
  episode_duration: number;
  sftp_user: string;
  sftp_season_folder: string;
  discord_channel: string;
  sheet_link: string;
  type: string;
  deadline: string;
  num_episodes: number;
  title: string;
  title_en: string;
  title_ch: string;
  title_jp: string;
  archived?: boolean;
  publisher_rate_checked: boolean;
  publisher_rate: number;
  publisher_rate_major_tlc_checked: boolean;
  publisher_rate_major_tlc: number;
  publisher_rate_minor_tlc_checked: boolean;
  publisher_rate_minor_tlc: number;
  publisher_rate_reedit_checked: boolean;
  publisher_rate_reedit: number;
  publisher_rate_retime_checked: boolean;
  publisher_rate_retime: number;
};

export type UpdateSeriePayload = CreateSeriePayload & {
  team: Team;
};

export type Team = {
  rate: number;
  role: Role;
  user: User;
  role_id: number;
  user_id: number;
  id: number;
};

export type Serie = {
  id: number;
  short_title: string;
  target_submission_date?: Date;
  title: string;
  title_jp?: string;
  title_ch?: string;
  title_en?: string;
  artwork: string;
  type: string;
  num_episodes: number;
  deadline: string;
  air_date: string;
  sftp_user: string;
  sftp_season_folder: string;
  subtitle_filename: string;
  episode_duration: number;
  image: string;
  archived: boolean;
  publisher_rate: number;
  publisher_rate_major_tlc: number;
  publisher_rate_minor_tlc: number;
  publisher_rate_reedit: number;
  publisher_rate_retime: number;
  publisher_rate_checked: boolean;
  publisher_rate_major_tlc_checked: boolean;
  publisher_rate_minor_tlc_checked: boolean;
  publisher_rate_reedit_checked: boolean;
  publisher_rate_retime_checked: boolean;
  team: Team[];
};
