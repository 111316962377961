import { api } from "./api";
import { handleRequestPromise } from "./_utils_";

export const PayrollService = {
  history: async () => {
    const promise = api.get<PayrollHistoryPayload[]>("payroll");
    return handleRequestPromise(promise);
  },
  next: async () => {
    const promise = api.get<{ next: PayrollNextPayload; future: PayrollNextPayload }>("payroll/next");
    return handleRequestPromise(promise);
  },
  new: async () => {
    const promise = api.get<PayrollNewPayload>("payroll/new");
    return handleRequestPromise(promise);
  },
  delete: async (id: number) => {
    const promise = api.delete<boolean>(`payroll/${id}`);
    return handleRequestPromise(promise);
  },
  create: async (payroll: PayrollCreatePayload) => {
    const promise = api.post<boolean>("payroll", payroll);
    return handleRequestPromise(promise);
  },
  view: async (id: number) => {
    const promise = api.get<PayrollViewPayload>(`payroll/${id}`);
    return handleRequestPromise(promise);
  },
  series: async (series_id: string) => {
    const promise = api.get<PayrollSeriesPayload>(`payroll/series/${series_id}`);
    return handleRequestPromise(promise);
  },
  userPdf: async (id: number) => {
    const promise = api.get<Blob>(`payroll/pdf/${id}`, { responseType: "blob" });
    return handleRequestPromise(promise);
  },
  adminUserPdf: async (id: number, user_id: number) => {
    const promise = api.get<Blob>(`payroll/pdf/${id}/${user_id}`, { responseType: "blob" });
    return handleRequestPromise(promise);
  },
  adminPayrollPdf: async (id: number) => {
    const promise = api.get<Blob>(`payroll/pdf/summary/${id}`, { responseType: "blob" });
    return handleRequestPromise(promise);
  },
  adminPayrollCSV: async (id: number) => {
    const promise = api.get<Blob>(`payroll/csv/${id}`, { responseType: "blob" });
    return handleRequestPromise(promise);
  },
};

export type PayrollNextPayload = {
  series: PayrollViewPayload["series"];
  series_expenses: PayrollHistoryPayload["summary"]["series_expenses"];
  expenses: PayrollHistoryPayload["summary"]["expenses"];
  total_amount: number;
};

export type PayrollSeriesPayload = {
  payrolls: {
    payroll_id: number;
    payroll_name: string;
    episodes_range: string;
    episodes: string[];
    amount: number;
    expenses: number;
    publisher: number;
  }[];
  series: {
    id: number;
    title: string;
    publisher_id: number;
    publisher_name: string;
    expenses: {
      id: number;
      name: string;
      description: string;
      user_id: number;
      user_name: string;
      amount: number;
    }[];
    episodes: {
      id: number;
      number: string;
      team: {
        id: number;
        user_id: number;
        user_name: string;
        role_id: number;
        role_name: string;
        amount: number;
      }[];
    }[];
  };
};

export type PayrollViewPayload = {
  id: number;
  name: string;
  period_start: string;
  period_end: string;
  summaries: {
    series: {
      id: number;
      title: string;
      amount: number;
      publisher_amount: number;
      num_episodes: number;
      episodes_range: string;
      episodes: string[];
    }[];
    roles: {
      id: number;
      name: string;
      color: string;
      amount: number;
      num_episodes: number;
    }[];
    users: {
      id: number;
      name: string;
      amount: number;
      num_episodes: number;
      series: {
        id: number;
        title: string;
        num_episodes: number;
        roles: {
          id: number;
          name: string;
          amount: number;
          episodes: string[];
          num_episodes: number;
          episodes_range: string;
        }[];
      }[];
    }[];
    publishers: {
      id: number;
      name: string;
      amount: number;
    }[];
  };
  expenses: {
    id: number;
    user_id: number;
    user_name: string;
    category: string;
    description: string;
    amount: number;
  }[];
  series: {
    id: number;
    title: string;
    publisher_id: number;
    publisher_name: string;
    expenses: {
      id: number;
      name: string;
      description: string;
      user_id: number;
      user_name: string;
      amount: number;
    }[];
    episodes: {
      id: number;
      number: string;
      team: {
        id: number;
        user_id: number;
        user_name: string;
        role_id: number;
        role_name: string;
        amount: number;
      }[];
      publisher?: {
        duration: number;
        rate: number;
        amount: number;
      };
    }[];
  }[];
};

export type PayrollHistoryPayload = {
  payroll: {
    id: number;
    name: string;
    period_start: string;
    period_end: string;
  };
  summary: {
    localisation: {
      username: string;
      name: string;
      roles: {
        name: string;
        color: string;
      }[];
      total_minutes: number;
      total_amount: number;
      total_episodes: number;
    }[];
    series_expenses: {
      id: number;
      title: string;
      user_name: string;
      description: string;
      total_amount: number;
    }[];
    expenses: {
      username: string;
      name: string;
      category: string;
      description: string;
      total_amount: number;
    }[];
    publishers: {
      name: string;
      duration: number;
      total_amount: number;
    }[];
    total_localisation: number;
    total_expenses: number;
    total_publishers: number;
  };
};

export type PayrollNewPayload = {
  payroll: {
    serie: {
      id: number;
      title: string;
    };
    episodes: {
      number: string;
      id: number;
      team: {
        id: number;
        rate: number;
        duration: number;
        role_id: number;
        role_name: string;
        user_id: number;
        user_username: string;
        approved: boolean;
      }[];
    }[];
    expenses: {
      expense_id: number;
      user_id: number;
      user_username: string;
      name: string;
      description: string;
      amount: number;
      approved: boolean;
    }[];
  }[];
  payroll_expenses: {
    expense_id: number;
    user_id: number;
    user_username: string;
    category: string;
    description: string;
    amount: number;
    approved: boolean;
  }[];
};

export type PayrollCreatePayload = {
  name: string;
  start_date: string;
  end_date: string;
  teams: {
    id: number;
    rate: number;
    duration: number;
    role_id: number;
    user_id: number;
  }[];
  series_expenses: {
    expense_id: number;
    series_id: number;
    user_id: number;
    name: string;
    description: string;
    amount: number;
  }[];
  payroll_expenses: {
    expense_id: number;
    user_id: number;
    category: string;
    description: string;
    amount: number;
  }[];
};
