import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ConfirmModal, ConfirmModalRef, Container, WithLoading } from "@components";
import { useRolesFindAll } from "@hooks/api/roles.hook";
import { MainLayout } from "@layouts";
import { RolesService } from "@services/roles.service";
import { useRef } from "react";
import { useHistory } from "react-router";

const AlertMessageComponent = (props: { role: string }) => (
  <Text>
    Are you sure you want to delete the role{" "}
    <Text as="span" fontWeight="bold">
      {props.role}
    </Text>
    ?
  </Text>
);

const NoneMessage = () => (
  <Tr>
    <Td colSpan={11}>No records found.</Td>
  </Tr>
);

export const Roles = () => {
  const toast = useToast();
  const { data, loading, refetch } = useRolesFindAll();
  const history = useHistory();
  const ref = useRef<ConfirmModalRef>(null);

  const handleClickEditRole = (roleId: number) => history.push(`/roles/${roleId}`);
  const handleClickDeleteRole = async (roleId: string) => {
    try {
      await RolesService.deleteById(roleId);

      toast({
        status: "success",
        description: "Role removed successfully",
      });

      refetch();
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
      });
    }
  };

  return (
    <MainLayout>
      <Container stackProps={{ maxW: "full", w: { base: "full", sm: "container.lg" } }}>
        <ConfirmModal ref={ref} onConfirm={handleClickDeleteRole} />

        <ButtonGroup variant="outline" spacing="6" width="100%" justifyContent="flex-end">
          <Button colorScheme="teal" size="sm" type="submit" onClick={() => history.push("/roles/add")}>
            Add New Role
          </Button>
        </ButtonGroup>
        <WithLoading loading={loading}>
          <Table variant="striped" marginTop="16px !important">
            <Thead>
              <Tr>
                <Th>Order</Th>
                <Th colSpan={1}>Color</Th>
                <Th colSpan={3}>Name</Th>
                <Th>Short Name</Th>
                <Th colSpan={3}>Description</Th>
                <Th>File Tag</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!data?.length && <NoneMessage />}
              {data?.map((role) => {
                return (
                  <Tr key={role.id}>
                    <Td textAlign="center">{role.order}</Td>
                    <Td colSpan={1}>
                      <HStack justifyContent="center">
                        <Box w="4" h="4" rounded="full" backgroundColor={role.color} />
                      </HStack>
                    </Td>
                    <Td colSpan={3}>{role.name}</Td>
                    <Td>{role.short_name}</Td>
                    <Td colSpan={3}>{role.description}</Td>
                    <Td>{role.file_tag}</Td>
                    <Td>
                      <ButtonGroup>
                        <Tooltip label="Edit role" aria-label="Edit role">
                          <IconButton
                            size="sm"
                            aria-label="Edit"
                            icon={<EditIcon />}
                            onClick={() => handleClickEditRole(role.id)}
                            borderRadius="4"
                          />
                        </Tooltip>
                        <Tooltip label="Delete role" aria-label="Delete role">
                          <IconButton
                            borderRadius="4"
                            size="sm"
                            colorScheme="red"
                            aria-label="Delete"
                            icon={<DeleteIcon />}
                            onClick={() =>
                              ref.current?.open(role.id, {
                                title: "Delete Role?",
                                message: <AlertMessageComponent role={`${role.id} - ${role.short_name}`} />,
                              })
                            }
                          />
                        </Tooltip>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </WithLoading>
      </Container>
    </MainLayout>
  );
};
